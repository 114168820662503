import { RepositoryMain } from '../Domain/RepositoryMain'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'

export class UseCaseSelectTrabajoLocal {
  private repository: RepositoryMain
  private languageTranslate = LanguageTranslate()
  
  constructor(_repository: RepositoryMain) {
    this.repository = _repository
  }

  public async exec(user: EntityDataUsuario, ID_Trabajo: number): Promise<DtoTrabajosDE> {
    return await this._exec(user, ID_Trabajo)
  }

  private async _exec(user: EntityDataUsuario, ID_Trabajo: number): Promise<DtoTrabajosDE> {
    const result = await this.repository.selectTrabajoLocal(user, ID_Trabajo)
    if (!result) throw Error(this.languageTranslate.general.No_se_pudo_obtener_Trabajo)
    return result
  }
}