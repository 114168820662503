import { RepositoryMain } from '../Domain/RepositoryMain'
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario'
import { AdapterConfigure } from '../Infraestructure/AdapterConfigure'
import { DtoRequestSelectStockAlmacen } from '../Domain/DtoRequestSelectStockAlmacen'
import { DtoStockAlmacen } from '../Domain/DtoStockAlmacen'
import { codigosMaterialDuctos, CodigosMaterialDuctosAL04, codigosMaterialUGG } from 'sigo-package'

export class UseCaseSelectStockAlmacen {
    private repository: RepositoryMain

    constructor(_repository: RepositoryMain) {
        this.repository = _repository
    }

    public async exec(user: EntityDataUsuario): Promise<DtoStockAlmacen[]> {
        return await this._exec(user)
    }

    private async _exec(user: EntityDataUsuario): Promise<DtoStockAlmacen[]> {
        const pipelineCodsItem = user.Delegacion.Codigo === 'AL02'
            ? { $in: codigosMaterialUGG }
            : { $nin: CodigosMaterialDuctosAL04 }
        let params: DtoRequestSelectStockAlmacen = {
            datos: {
                Codigo: user.Delegacion.Codigo,
                Nombre: user.Pais.Nombre,
            },
            Filtros: [
                {
                    $match: {
                        'Almacen.Codigo': user.AlmacenEmpresa.Codigo,
                        'Estado.ID_Estado': 2,
                        'TipoStock.Nombre': 'NUEVO',
                        'Item.Codigo': pipelineCodsItem,
                        'Pais.Codigo': user.Pais.Codigo,
                        'Delegacion.Codigo': user.Delegacion.Codigo,
                    }
                },
                {
                    $project: {
                        'TipoStock.Nombre': 1,
                        Almacen: 1,
                        Bobina: 1,
                        Bodega: 1,
                        CodigoBusqueda: 1,
                        CodigoLlave: 1,
                        Despacho: 1,
                        Devolucion: 1,
                        ID_StockAlmacen: 1,
                        Ingreso: 1,
                        Item: 1,
                        Lote: 1,
                        Precio: 1,
                        Tipo: 1,
                        Transito: 1,
                        ZonaTrabajo: 1,
                    },
                }
            ],
        }
        if (user.Pais.Codigo === '004')
            return await this.repository.selectStockAlmacen(params, user)
        return []
    }
}