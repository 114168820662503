import { Dispatch } from "redux";
import { EntityDataUsuario } from "../Domain/EntityDataUsuario";
import { EntitySelectDelegacion } from "../Domain/EntityDelegacion";
import { EntityFindGenerico } from "../Domain/EntityFindGenerico";
import { EntitySelectOTs } from "../Domain/EntityOTs";
import { RepositoryGenerico } from "../Domain/RepositoryGenerico";
import { AdapterConfigureGenerico } from "./AdapterConfigureGenerico";
// import { AdapterData } from "./AdapterData";
// import { AdapterGenerico } from "./AdapterGenerico";
import { AdapterIndexedDB } from "./AdapterIndexedDB";
import { AdapterService } from "./AdapterService";
import { AdapterWebSocket } from "./AdapterWebSocket";
import { CodigoNombreDTO } from 'sigo-entities';

export abstract class RepositoryImplGenerico<T> implements RepositoryGenerico<T> {
  protected websocket: AdapterWebSocket;
  protected dbLocal: AdapterIndexedDB;
  protected dispatch: Dispatch;
  protected service: AdapterService;
  protected schema: string;
  protected entity: string;
  protected urlBase: string;
  protected urlBaseAuth: string;
  protected urlBaseLogistica: string;
  protected urlBaseReportesPex: string;
  protected data: Array<T>;
  protected pais: CodigoNombreDTO;
  protected delegacion: CodigoNombreDTO;

  constructor(websocket: AdapterWebSocket, dbLocal: AdapterIndexedDB, dispatch: Dispatch, schema: string, entity: string, user?: EntityDataUsuario) {
    this.websocket = websocket;
    this.dbLocal = dbLocal;
    this.dispatch = dispatch;

    this.schema = schema;
    this.entity = entity;

    this.urlBase = AdapterConfigureGenerico.URL_BASE;
    this.urlBaseAuth = AdapterConfigureGenerico.URL_BASE_AUTH;
    this.urlBaseLogistica = AdapterConfigureGenerico.URL_BASE_LOGISTICA;
    this.urlBaseReportesPex = AdapterConfigureGenerico.URL_BASE_REPORTES_PEX;

    this.service = new AdapterService(this.dispatch);

    this.data = [];

    this.pais = user?.Pais ?? new CodigoNombreDTO()
    this.delegacion = user?.Delegacion ?? new CodigoNombreDTO()
  }

  public async find(params: EntityFindGenerico): Promise<Array<T>> {
    let response: Array<T> | T | null = await this.service.bgCall<T>("POST", `${this.urlBase}/${this.schema}/${this.entity}/find`, JSON.stringify(params), "bearer", "json", 'json', {}, 2);
    response = response === null ? [] : !Array.isArray(response) ? [response] : response;
    return response;
  }

  async _find<ReturnType = T>(pipeline: any[] | Object, urlBase: string = this.urlBase, schema: string = this.schema, entity: string = this.entity, paramsPaisDelegacion: boolean = false) {
    let response: Array<ReturnType> | ReturnType | null = await this.service.bgCall<ReturnType>(
      "POST",
      `${urlBase}/${schema}/${entity}/find${paramsPaisDelegacion ? `/${this.pais.Codigo}/${this.delegacion.Codigo}` : ''}`,
      JSON.stringify(pipeline),
      "bearer",
      "json",
      'json',
      { 'request-decrypt-response': true },
      2
    );
    response = response === null ? [] : !Array.isArray(response) ? [response] : response;
    return response;
  }

  public async saveOne(obj: T): Promise<T | null> {
    let response: Array<T> | T | null = await this.service.bgCall<T>("POST", `${this.urlBase}/${this.schema}/${this.entity}/save`, JSON.stringify(obj), "bearer", "json", 'json', {}, 2);
    response = response as T;
    return response;
  }

  public async saveMany(obj: Array<T>): Promise<Array<T>> {
    let response: Array<T> | T | null = await this.service.bgCall<T>("POST", `${this.urlBase}/${this.schema}/${this.entity}/save`, JSON.stringify(obj), "bearer", "json", 'json', {}, 2);
    response = response as Array<T>;
    return response;
  }

  public async update(filter: object, update: object): Promise<Array<T>> {
    let response: Array<T> | T | null = await this.service.bgCall<T>("PATCH", `${this.urlBase}/${this.schema}/${this.entity}/update`, JSON.stringify({ filter, update }), "bearer", "json", 'json', {}, 2);
    response = response as Array<T>;
    return response;
  }

  public async delete(filter: any): Promise<Array<T>> {
    let response: Array<T> | T | null = await this.service.bgCall<T>("DELETE", `${this.urlBase}/${this.schema}/${this.entity}/delete`, JSON.stringify({ filter }), "bearer", "json", 'json', {}, 2);
    response = response as Array<T>;
    return response;
  }

  public async getOtForRol(user: EntityDataUsuario): Promise<EntitySelectOTs[]> {
    // const list = AdapterGenerico.formatoPersonalizadoSelect((await this.dbLocal.selectAllStore('OT')), 'Codigo', 'OT') as EntitySelectOTs[];
    // const list = AdapterGenerico.formatoPersonalizadoSelect(AdapterData.ot, 'Codigo', 'OT') as EntitySelectOTs[];
    let response: EntitySelectOTs[] = []

    // switch (user.DatosRol.Codigo) {
    //     case '01SA': response = list; break;
    //     case '02AP': response = list.filter(row => (user.Pais.map(itemPais => itemPais.IdPais)).includes(row.dataComplete.Pais.IdPais)); break;
    //     case '03AD': response = list.filter(row => (user.Delegacion.map(itemDel => itemDel.Codigo)).includes(row.dataComplete.Delegacion.Codigo)); break;
    //     case '04U':
    //     case '05DC':
    //         const groupIdOt = user.Delegacion.reduce((total, currentValue) => {
    //             const groupId: number[] = currentValue.OT.map(row => row.IdOT);
    //             return [...total, ...groupId];
    //         }, [] as number[])
    //         response = list.filter(row => groupIdOt.includes(row.dataComplete.IdOT));
    //         break;
    //     default: response = list; break;
    // }

    return response;
  }

  public async getDelegacionForRol(user: EntityDataUsuario): Promise<EntitySelectDelegacion[]> {
    // const list = AdapterGenerico.formatoPersonalizadoSelect(await this.dbLocal.selectAllStore('Delegacion'), 'Codigo', ['Codigo', 'Delegacion'], ' - ') as EntitySelectDelegacion[];
    let response: EntitySelectDelegacion[] = []

    // switch (user.DatosRol.Codigo) {
    //     case '01SA': response = list; break;
    //     case '02AP': response = list.filter(row => (user.Pais.map(itemPais => itemPais.IdPais)).includes(row.dataComplete.Pais.IdPais)); break;
    //     case '03AD':
    //     case '04U':
    //     case '05DC':
    //         response = list.filter(row => (user.Delegacion.map(itemDel => itemDel.Codigo)).includes(row.dataComplete.Codigo));
    //         break;
    //     default: response = list; break;
    // }

    return response;
  }
}