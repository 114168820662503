import { DtoResponseSelectStockPersonal } from '../../../StockPersonalTotal/Domain/DtoResponseSelectStockPersonal'
import { Repository } from '../infraestructure/repository'

export class UseCaseSelectStockPersonalEquipos {
    private repository: Repository

    constructor(_repository: Repository) {
        this.repository = _repository
    }

    async exec(data: DtoResponseSelectStockPersonal[]) {
        const IDS = data.reduce((ac: number[], el) => {
            if (el.Item.Ind_SB === 'S') {
                ac.push(el.ID_StockPersonal)
            }
            return ac
        }, [])
        if (!IDS.length) return []
        return await this.repository.selectStockPersonalEquipos(IDS)
    }
}
