import { Children, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { OffCanvasBottom } from '../../../../shared/Components/ReactBootstrap/OffCanvasBottom/OffCanvasBottom'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { DtoResponseSelectStockPersonal } from '../../../StockPersonalTotal/Domain/DtoResponseSelectStockPersonal'
import { StockPersonalEquiposENTITY } from 'sigo-entities'
import { Form } from 'react-bootstrap'
import { EquiposAIDto, iAutoInventario } from '../../shared/domain/iAutoInventario'
import ButtonFloating from '../../../../shared/Components/ButtonFloating/ButtonFloating'
import { ZXingScanner } from './BarcodeScanner'

interface iProps {
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
    stockPersonal: DtoResponseSelectStockPersonal | undefined
    handleSaveSeries: (data: EquiposAIDto[]) => void
    updateAutoInventario: iAutoInventario
    autoInventario: boolean
}

export const Series = (props: iProps) => {

    const { setShowModal, showModal, stockPersonal, updateAutoInventario, autoInventario } = props
    const { dataStockPersonalEquipos } = useSelector((state: RootState) => state.autoInventario)
    const [stockEquipo, setStockEquipo] = useState<StockPersonalEquiposENTITY>(new StockPersonalEquiposENTITY())
    const [data, setData] = useState<EquiposAIDto[]>([])

    useEffect(() => {
        const buscarStockPersonalEquipo = async () => {
            const stockEquipo = dataStockPersonalEquipos.find(el => el.ID_StockPersonal === stockPersonal?.ID_StockPersonal &&
                el.CodigoLlave === stockPersonal.CodigoLlave &&
                el.CodigoBusqueda === stockPersonal.CodigoBusqueda
            )
            if (!stockEquipo) {
                await AdapterGenerico.createMessage('Alerta', 'No se pudo obtener stock alamcén equipo', 'error')
                setShowModal(false)
                return
            }
            setStockEquipo(stockEquipo)
        }
        if (stockPersonal?.Item.Ind_SB === 'S') {
            buscarStockPersonalEquipo()
        }
    }, [stockPersonal])

    useEffect(() => {
        const equipos = stockEquipo.Equipos
            .filter(e => e.Estado.ID_Estado === 2)
        setData(equipos.map(e => {
            const exist = updateAutoInventario.Equipos.find(el => el.Serie === e.Serie)
            if (exist) { return exist }
            return {
                Estado: false,
                Observacion: '',
                Serie: e.Serie
            }
        }))
    }, [stockEquipo])

    return (
        <OffCanvasBottom
            height={90}
            show={showModal}
            setShow={setShowModal}
            children={
                <Body
                    data={data}
                    setData={setData}
                    handleSaveSeries={props.handleSaveSeries}
                    autoInventario={autoInventario}
                />
            }
        />
    )
}

const Body = (
    { data, setData, handleSaveSeries, autoInventario }:
        {
            data: EquiposAIDto[],
            setData: Dispatch<SetStateAction<EquiposAIDto[]>>,
            handleSaveSeries: (data: EquiposAIDto[]) => void,
            autoInventario: boolean
        }
) => {

    const dataRef = useRef(data)

    useEffect(() => {
        dataRef.current = data
    }, [data])

    const onScan = (code: string) => {
        try {
            const alreadyScanned = dataRef.current.find(item => item.Serie === code)

            if (!alreadyScanned) {
                AdapterGenerico.createToast(`Serie ${code} no pertece a su stock o no está en estado VALIDADO`, 'error')
            }

            if (alreadyScanned && alreadyScanned.Estado) {
                AdapterGenerico.createToast(`Ya ha escaneado la serie ${code}`, 'error')
            }

            setData(prevData =>
                prevData.map(item =>
                    item.Serie === code ? { ...item, Estado: true, Observacion: '' } : item
                )
            )
        } catch (error) {
            console.error(error)
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
        }
    }

    return (
        <div>
            {
                autoInventario ?
                    <ZXingScanner onDetected={onScan} />
                    : null
            }
            <div className='mt-2 bg-body-secondary p-1'>
                {
                    Children.toArray(
                        data.map((e, i) => (
                            <Form className='d-flex gap-2'>
                                <Form.Check
                                    type='checkbox'
                                    id={i.toString()}
                                    label={e.Serie}
                                    className='d-flex align-items-center gap-1'
                                    disabled
                                    checked={!!e.Observacion.length || e.Estado}
                                />
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    value={e.Observacion}
                                    onChange={(event) => {
                                        const newObservacion = event.target.value.length < 2
                                            ? event.target.value.trim()
                                            : event.target.value
                                        setData((prevData) =>
                                            prevData.map((item, index) =>
                                                index === i ? { ...item, Observacion: newObservacion } : item
                                            )
                                        )
                                    }}
                                    disabled={e.Estado}
                                />
                            </Form>
                        ))
                    )
                }
            </div>
            {
                autoInventario ?
                    <ButtonFloating
                        onClick={() => handleSaveSeries(data)}
                        icono='fa-solid fa-floppy-disk'
                        colorIcono='white'
                        backgroundColor='#049bed'
                        right={20}
                    />
                    : null
            }
        </div>
    )
}
