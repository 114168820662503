import { Dispatch, SetStateAction, useState } from 'react'
import { Container } from '../../../../shared/Components/Container/Container'
import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { AdapterConfigure } from '../Infraestructure/AdapterConfigure'
import { OffCanvasBottom } from '../../../../shared/Components/ReactBootstrap/OffCanvasBottom/OffCanvasBottom'
import ButtonFloating from '../../../../shared/Components/ButtonFloating/ButtonFloating'
import { FormAdd } from './FormAdd'
import { DtoDataSelectAsignaciones } from '../Domain/DtoDataSelectAsignaciones'
import { FormikHelpers } from 'formik'
import { DtoInitialValues } from '../../Shared/Preliquidar/Interfaces/DtoInitialValues'
import { DtoDataAddedValorizaciones } from '../../Shared/Preliquidar/Interfaces/DtoDataAddedValorizaciones'
import { AddedValorizaciones } from './AddedValorizaciones'
import { DtoDocumentacionBaremoPEX, DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import AddedReservaMO from './AddedReservaMO'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { DtoPrecioEspecialidad } from '../../../../../app/Domain/DtoPrecioEspecialidad'
import { DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { DtoMaterialesUtiRet } from '../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { DtoInitialValuesAddMO } from '../../Shared/Preliquidar/Interfaces/DtoInitialValuesAddMO'
import { DtoDataMateriales } from '../Domain/DtoDataMateriales'
import { codMOTroncalAL04 } from 'sigo-package'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
// import { codMOExcluidos } from '../../Shared'
import { ValorizacionesTroncal } from './ValorizacionesTroncal'
import { typeEstadosAddressID } from '../../Shared/Domain'

interface props {
  trabajo: DtoTrabajosDE | null
  showOffCanvas: boolean
  setShowOffCanvas: Dispatch<SetStateAction<boolean>>
  asignaciones: DtoDataSelectAsignaciones[]
  valorizaciones: DtoDataAddedValorizaciones[]
  onSubmit: (
    values: DtoInitialValues,
    formikHelpers: FormikHelpers<DtoInitialValues> | null,
    materialesInstalado: DtoMaterialesUtiRet[],
    materialesRetirado: DtoMaterialesUtiRet[]
  ) => void | Promise<any>
  onSubmitFormAddMO: (values: DtoInitialValuesAddMO, formikHelpers: FormikHelpers<DtoInitialValuesAddMO>) => void | Promise<any>
  onRemove: (LineaCodigoCub: string) => void
  setDocumentacionValorizacion: Dispatch<SetStateAction<DtoNodoDoc[]>>
  documentacionValorizacion: DtoNodoDoc[],
  setNewFilesUpload: Dispatch<SetStateAction<File[]>>
  newFilesUpload: File[]
  dataDocumentacionBaremoPEX: DtoDocumentacionBaremoPEX[]
  onSave: (valRechazadaXMetraje: DtoValorizacion | null) => void
  dataManoObraGlobal: DtoManoObraGlobal[]
  dataPrecioEspecialidad: DtoPrecioEspecialidad[]
  onChange: (name: string, value: any, materialesInstalado: DtoMaterialesUtiRet[]) => { dataAlmacen: DtoDataMateriales[], dataMateriales: DtoDataMateriales[] } | undefined
  dataMateriales: DtoDataMateriales[]
  dataMaterialesAlmacen: DtoDataMateriales[]
  cambiarEstadoHPList: (campo: typeEstadosAddressID) => Promise<void>
}

export const ViewMain = (props: props) => {

  const {
    trabajo,
    showOffCanvas,
    setShowOffCanvas,
    asignaciones,
    valorizaciones,
    onSubmit,
    onRemove,
    documentacionValorizacion,
    setDocumentacionValorizacion,
    newFilesUpload,
    setNewFilesUpload,
    dataDocumentacionBaremoPEX,
    onSave,
    dataManoObraGlobal,
    dataPrecioEspecialidad,
    onSubmitFormAddMO,
    onChange,
    dataMateriales,
    dataMaterialesAlmacen
  } = props
  const languageTranslate = LanguageTranslate()
  const [showModal, setShowModal] = useState(false)
  const [showPreliquidaciones, setShowPreliquidaciones] = useState(false)
  const [valRechazadaXMetraje, setValRechazadaXMetraje] = useState<DtoValorizacion | null>(null)

  return (
    <>
      <Container>
        <ElementBreadCrumb
          list={[
            {
              text: languageTranslate.moduloMain.textoProgramados,
              navigate: true,
              path: `${AdapterConfigure.ROUTE_PROGRAMADOS}`,
            },
            {
              text: languageTranslate.menu['004'].titulo,
              navigate: true,
              path: `${AdapterConfigure.ROUTE_TRABAJOS}`,
            },
            {
              text: trabajo?.ColeccionObras[0].OrdenTrabajo || '',
              navigate: false,
              path: '',
            },
          ]}
        />
        <AddedValorizaciones
          valorizaciones={valorizaciones}
          onRemove={(LineaCodigoCub) => {
            onRemove(LineaCodigoCub)
            setValRechazadaXMetraje(null)
          }}
        />
      </Container>
      {
        !valRechazadaXMetraje &&
        <ButtonFloating
          onClick={() => setShowOffCanvas(true)}
          icono='fa-solid fa-plus'
          colorIcono='white'
          backgroundColor='#079455'
        />
      }
      <ButtonFloating
        onClick={() => { onSave(valRechazadaXMetraje) }}
        icono='fa-solid fa-floppy-disk'
        colorIcono='white'
        backgroundColor='#033c9c'
        right={70}
      />
      <ButtonFloating
        onClick={() => setShowPreliquidaciones(true)}
        icono='fa-solid fa-list'
        colorIcono='white'
        backgroundColor='#146ebe'
        right={125}
      />
      <OffCanvasBottom
        height={65}
        show={showOffCanvas}
        setShow={setShowOffCanvas}
        children={
          <FormAdd
            asignaciones={asignaciones
              .filter(e => codMOTroncalAL04.includes(e.ManoObra.Codigo.trim()))
              .filter(el => ![
                trabajo?.Ultima_PreLiquidacion.Asfaltado,
                trabajo?.Ultima_PreLiquidacion.OC,
                trabajo?.Ultima_PreLiquidacion.Soplado
              ].filter((e, i, arr) => arr[1]?.Estado && e?.Estado)
                .map(elem => elem?.Especialidad)
                .includes(el.Especialidad.Codigo)
              )
            }
            valorizaciones={valorizaciones}
            onSubmit={onSubmit}
            documentacionValorizacion={documentacionValorizacion}
            setDocumentacionValorizacion={setDocumentacionValorizacion}
            newFilesUpload={newFilesUpload}
            setNewFilesUpload={setNewFilesUpload}
            dataDocumentacionBaremoPEX={dataDocumentacionBaremoPEX}
            trabajo={trabajo}
            onClick={() => setShowModal(!showModal)}
            onChange={onChange}
            dataMateriales={dataMateriales}
            dataMaterialesAlmacen={dataMaterialesAlmacen}
            setShowOffCanvas={setShowOffCanvas}
            setValRechazadaXMetraje={setValRechazadaXMetraje}
            valRechazadaXMetraje={valRechazadaXMetraje}
          />
        }
      />
      <AddedReservaMO
        show={showModal}
        setShow={setShowModal}
        dataManoObraGlobal={dataManoObraGlobal.filter(e => codMOTroncalAL04.includes(e.Codigo))}
        dataPrecioEspecialidad={dataPrecioEspecialidad}
        trabajo={trabajo}
        onSubmitFormAddMO={onSubmitFormAddMO}
        type='Troncal'
      />
      <OffCanvasBottom
        height={65}
        show={showPreliquidaciones}
        setShow={setShowPreliquidaciones}
        children={
          <ValorizacionesTroncal
            trabajo={trabajo as DtoTrabajosDE}
            cambiarEstadoHPList={props.cambiarEstadoHPList}
          />
        }
      />
    </>
  )
}