import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { EntityNotificacionTerminos } from "../../../shared/Domain/EntityNotificacionTerminos";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { addCountProcess } from "../../../shared/Infraestructure/SliceGenerico";
import { DtoRequestForm } from "../Domain/DtoRequestForm";
import { DtoRequestSelectStockPersonal } from "../Domain/DtoRequestSelectStockPersonal";
import { DtoResponseSelectStockPersonal } from "../Domain/DtoResponseSelectStockPersonal";
import { DtoTrabajos } from "../../Home/Domain/DtoTrabajos";
import { EntityTermsForUser } from "../Domain/EntityTermsForUser";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { AdapterConfigure } from "./AdapterConfigure";
import { DtoReqSelectTrabajos } from "../Domain/DtoReqSelectTrabajos";
import { DtoReqSelectTrabajoDE } from '../../../Programados/TrabajosDE/Trabajos/Domain/DtoReqSelectTrabajoDE';
import { DtoTrabajosDE } from '../../../Programados/TrabajosDE/Trabajos/Domain/DtoTrabajosDE';
import { DtoRequestSelectStockAlmacen } from '../Domain/DtoRequestSelectStockAlmacen';
import { DtoStockAlmacen } from '../Domain/DtoStockAlmacen';
import { DtoRequestSelectConsumoMaterial } from '../Domain/DtoRequestSelectConsumoMaterial';
import { DtoConsumoMaterial } from '../Domain/DtoConsumoMaterial';
import { iBodyReqReporteBaremosPeriodoActual } from '../Domain';
import { GetCodigoFechaActual } from 'sigo-package'

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

  async reporteBaremosPeriodoActual(params: iBodyReqReporteBaremosPeriodoActual) {
    const url: string = `${this.urlBaseReportesPex}${AdapterConfigure.SELECT_REP_BAREMOS_PERIODO_ACTUAL_COL_9612}`
    const response = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', { 'request-decrypt-response': true })
    return typeof response === 'string'
      ? AdapterGenerico.isJSON(response)
        ? JSON.parse(response)
        : response
      : response
  }

  public async callTermns(user: EntityDataUsuario): Promise<any[]> {
    return [];
  }

  public async getNotificacionTerminos(): Promise<EntityNotificacionTerminos[]> {
    return await this.dbLocal.selectAllStore('NotificacionesTerminos');
  }

  public async getTermsAccepted(): Promise<EntityTermsForUser[]> {
    return await this.dbLocal.selectAllStore('TermsAccept');
  }

  public async onSaveAcceptTerms(params: DtoRequestForm): Promise<EntityTermsForUser> {
    try {
      let response = await this._save(params);
      let rowLocal = await this._saveLocal(params);

      if (response !== null) {
        await this.dbLocal.deleteByIndexStore({ nameStore: 'TermsAccept', value: rowLocal._id });
        await this.dbLocal.insertDataStore({ nameStore: 'TermsAccept', data: response ? response : rowLocal });
      }

      return !!response ? response : rowLocal;
    } catch (error) {
      throw error;
    }
  }

  private async _saveLocal(params: DtoRequestForm): Promise<EntityTermsForUser> {
    const StatusOffline = { Estado: "No Enviado", IdEstado: -1 }
    let response: any = { ...params, Estado: StatusOffline, dataSend: params, _id: Date.now().toString() };

    await this.dbLocal.insertDataStore({ nameStore: 'TermsAccept', data: response });
    return response;
  }

  private async _save(params: DtoRequestForm): Promise<EntityTermsForUser | null> {
    if (!navigator.onLine) { this.dispatch(addCountProcess()); return null; }
    const response: string = await this.websocket.emit('ProcesoNotificacionesHistorico', { dataSend: params });
    const _response = JSON.parse(response)[0] as EntityTermsForUser;
    await this.onSendEmailAcceptTerms(params, _response);
    return { ..._response, _id: Date.now().toString() };
  }

  private async onSendEmailAcceptTerms(params: DtoRequestForm, notificacionAccepted: EntityTermsForUser): Promise<any | null> {
    const infoEmail = {
      NotificacionHistorico: {
        IdNotificacionHistorico: notificacionAccepted.IdNotificacionHistorico,
        TituloDocumento: notificacionAccepted.TituloDocumento,
        ContenidoAceptado: notificacionAccepted.ContenidoAceptado,
        Plataforma: notificacionAccepted.DatosPlataforma.Plataforma
      },
      Usuario: { ...params.Usuario, Email: params.Usuario.Email },
    }
    await this.websocket.emit('EnviarEmailAceptaTerminos', infoEmail);
  }

  public async selectStockPersonal(params: DtoRequestSelectStockPersonal, user: EntityDataUsuario): Promise<DtoResponseSelectStockPersonal[]> {
    let url: string = `${this.urlBase}${AdapterConfigure.SELECT_STOCK_PERSONAL}`;
    let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {});
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
    for (const stockPersonal of response) {
      stockPersonal.Personal = { Identificacion: user.Identificacion };
    }
    response = await this._saveLocalStockPersonal(response, user);
    return response;
  }

  private async _saveLocalStockPersonal(response: any, user: EntityDataUsuario): Promise<DtoTrabajos[]> {
    const storeExist = await this.dbLocal.existsStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`);
    if (!storeExist) throw Error(`Error al obtener coleccion StockPersonal`);
    await this.dbLocal.clearStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`);
    await this.dbLocal.insertDataStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`, data: response });
    return response;
  }

  public async selectStockAlmacen(params: DtoRequestSelectStockAlmacen, user: EntityDataUsuario): Promise<DtoStockAlmacen[]> {
    const url: string = `${this.urlBase}${AdapterConfigure.SELECT_STOCK_ALMACEN}`;
    let response = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {});
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
    response = await this._saveLocalStockAlmacen(response, user);
    return response;
  }

  private async _saveLocalStockAlmacen(response: DtoStockAlmacen[], user: EntityDataUsuario): Promise<DtoStockAlmacen[]> {
    const storeExist = await this.dbLocal.existsStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockAlmacen`)
    if (!storeExist) throw Error(`Error al obtener coleccion StockAlmacen`)
    await this.dbLocal.clearStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockAlmacen`)
    await this.dbLocal.insertDataStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockAlmacen`, data: response })
    return response
  }

  public async selectConsumoMaterial(params: DtoRequestSelectConsumoMaterial, user: EntityDataUsuario): Promise<DtoConsumoMaterial[]> {
    const url: string = `${this.urlBase}${AdapterConfigure.SELECT_CONSUMO_MATERIAL}`;
    let response = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {});
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
    response = await this._saveLocalConsumoMaterial(response, user);
    return response;
  }

  private async _saveLocalConsumoMaterial(response: DtoConsumoMaterial[], user: EntityDataUsuario): Promise<DtoConsumoMaterial[]> {
    const storeExist = await this.dbLocal.existsStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_ConsumoMaterial`)
    if (!storeExist) throw Error(`Error al obtener coleccion ConsumoMaterial`)
    await this.dbLocal.clearStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_ConsumoMaterial`)
    await this.dbLocal.insertDataStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_ConsumoMaterial`, data: response })
    return response
  }

  public async selectStockPersonalLocal(user: EntityDataUsuario): Promise<DtoResponseSelectStockPersonal[]> {
    const result: DtoResponseSelectStockPersonal[] = await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`);
    return result.filter(e => e.FlagPex === 1)
  }

  public async selectStockAlmacenLocal(user: EntityDataUsuario): Promise<DtoStockAlmacen[]> {
    return await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockAlmacen`);
  }

  public async selectTrabajosLocal(user: EntityDataUsuario): Promise<DtoTrabajos[]> {
    return await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`);
  }

  public async selectTrabajos(params: DtoReqSelectTrabajos, user: EntityDataUsuario): Promise<DtoTrabajos[]> {

    // params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, this.websocket.key, false);

    let url: string = `${this.urlBase}${AdapterConfigure.SELECT_TRABAJOS}`;
    let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {});
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
    for (const trab of response) {
      trab.Ultima_asignacion.Personal = [{ Identificacion: user.Identificacion }]
    }
    response = await this._saveLocalTrabajos(response, user);
    return response;
  }

  public async selectTrabajosDE(params: DtoReqSelectTrabajoDE, user: EntityDataUsuario): Promise<DtoTrabajosDE[]> {

    // params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, this.websocket.key, false);

    let url: string = `${this.urlBase}${AdapterConfigure.SELECT_TRABAJOS}`;
    let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {});
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;
    for (const trab of response) {
      trab.Ultima_asignacion.Personal = [{ Identificacion: user.Identificacion }]
    }
    response = await this._saveLocalTrabajos(response, user);
    return response;
  }

  private async _saveLocalTrabajos(response: any, user: EntityDataUsuario): Promise<DtoTrabajos[]> {
    const storeExist = await this.dbLocal.existsStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`);
    if (!storeExist) throw Error(`Error al obtener coleccion Trabajos`);
    await this.dbLocal.clearStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`);
    await this.dbLocal.insertDataStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, data: response });
    return response;
  }

  public async verifyToken(): Promise<any> {
    const url: string = `${this.urlBase}${AdapterConfigure.VERIFY_TOKEN}`
    await this.service.call<any>('GET', url, JSON.stringify({}), 'bearer', 'json', 'json', {})
  }

  async verifyAutoInventario(user: EntityDataUsuario) {
    const url: string = `${this.urlBaseAuth}${AdapterConfigure.VERIFY_AUTOINVENTARIO}`
    const KeyFecha = GetCodigoFechaActual()
    const body = [
      {
        $match: {
          KeyFecha,
          Identificacion: user.Identificacion
        }
      }
    ]
    return await this.service.call<any>('POST', url, JSON.stringify(body), 'bearer', 'json', 'json', { 'request-decrypt-response': true })
  }

}