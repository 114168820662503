import { FormikHelpers } from 'formik'
import { DtoInitialValues } from './Interfaces/DtoInitialValues'
import { DtoMaterialesUtiRet } from '../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { DtoDataAddedValorizaciones } from './Interfaces/DtoDataAddedValorizaciones'
import { Dispatch, SetStateAction } from 'react'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { DtoDataSelectAsignaciones } from '../../Preliquidar/Domain/DtoDataSelectAsignaciones'
import { CodigosMaterialDuctosAL04, CodsMapeoMOMaterial } from 'sigo-package'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { PreLiquidationProcess } from '../../Preliquidar/Application/UseCasePreLiquidationProcesses/UseCasePreLiquidationProcesses'
import { DtoItems } from '../../../../../app/Domain/DtoItems'

export const onSubmitShared = (
  values: DtoInitialValues,
  formikHelpers: FormikHelpers<DtoInitialValues> | null,
  materialesInstalado: DtoMaterialesUtiRet[],
  materialesRetirado: DtoMaterialesUtiRet[],
  documentacionValorizacion: DtoNodoDoc[],
  setValorizaciones: Dispatch<SetStateAction<DtoDataAddedValorizaciones[]>>,
  setDocumentacionValorizacion: Dispatch<SetStateAction<DtoNodoDoc[]>>,
  dataManoObraGlobal: DtoManoObraGlobal[],
  dataStockPersonal: DtoResponseSelectStockPersonal[],
  dataItems: DtoItems[],
  valorizaciones: DtoDataAddedValorizaciones[]
): DtoDataAddedValorizaciones | any | undefined => {

  const faltan = documentacionValorizacion.find(e => e.Files.filter(e => e.File).length < e.CantidadMin)
  if (faltan) return AdapterGenerico.createMessage('', 'Faltan agregar archivos', 'warning', false)

  if (typeof values.asignacion !== 'string') {
    const homePassMO = structuredClone(values.homePassMO.dataComplete)
    const matInstalado = materialesInstalado.map(material => {
      const consumirStockPersonal = CodigosMaterialDuctosAL04.includes(material.Codigo)
      if (!consumirStockPersonal) {
        material.Motivo.Descripcion = 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS'
      }
      return material
    })
    const newValAdd = {
      ...values.asignacion.dataComplete,
      CantidadPreLiquidado: values.cantidad,
      Adjuntos: documentacionValorizacion,
      materialesInstalado: matInstalado,
      materialesRetirado,
      homePassMO: [homePassMO],
      tipoBaremoSubcontrata: values.tipoBaremoSubcontrata.dataComplete
    }

    setValorizaciones((prev) => ([...prev, newValAdd]))
    if (formikHelpers) formikHelpers.resetForm()
    setDocumentacionValorizacion([])
    return newValAdd
  }
}
