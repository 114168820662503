import { DtoDocumentacionBaremoPEX } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoResponseEstadoInterno } from '../../../../../app/Domain/DtoResponseEstadoInterno'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { EntityLoadData } from '../Domain/EntityLoadData'
import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseLoadData {
  private repository: RepositoryMain
  private languageTranslate = LanguageTranslate()

  constructor(_repository: RepositoryMain) {
    this.repository = _repository
  }

  async exec(user: EntityDataUsuario, trabajo: DtoTrabajosDE): Promise<EntityLoadData> {
    const [
      DocumentacionBaremoPEX,
      EstadosInternos
    ] = await Promise.all([
      this.getDocumentacionBaremoPEX(user, trabajo),
      this.getEstadoInterno(user),
    ])
    return {
      DocumentacionBaremoPEX,
      EstadosInternos
    }
  }

  private async getDocumentacionBaremoPEX(user: EntityDataUsuario, trabajo: DtoTrabajosDE): Promise<DtoDocumentacionBaremoPEX[]> {
    if (trabajo.ColeccionObras.length !== 1) throw new Error(this.languageTranslate.general.Error_al_obtener_Obra_Comunícate_con_Soporte)
    const items = await this.repository.selectDocumentacionBaremoPEX()
    return items.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo && e.ContratoCod === trabajo.ColeccionObras[0].ContratoOT.Codigo)
  }

  private async getEstadoInterno(user: EntityDataUsuario): Promise<DtoResponseEstadoInterno[]> {
    const data = await this.repository.selectEstadoInterno();
    return data.filter(e => e.Tipo === 'Amap' && e.Pais.Codigo === user.Pais.Codigo);
  }

}