import { createSlice } from '@reduxjs/toolkit';
import { AdapterIndexedDB } from './AdapterIndexedDB';
import { AdapterWebSocket } from './AdapterWebSocket';
import { AdapterConfigureGenerico } from './AdapterConfigureGenerico';
import { AdapterStorage } from './AdapterStorage';

const { autoInventario } = AdapterStorage.get('autoInventario')

interface SliceGenericoState {
	iconMenu: boolean;
	showSaludo: boolean;

	loading: boolean;
	textLoading: string;

	websocket: AdapterWebSocket;

	dbLocal: AdapterIndexedDB;

	online: boolean;
	countProcess: number;

	loadingMaestros: boolean;
	availableUpdate: boolean;
	dataStockPersonalTotal: Array<any>;

	autoInventario: boolean | null
};

const initialState: SliceGenericoState = {
	iconMenu: false,
	showSaludo: false,

	loading: false,
	textLoading: '',
	websocket: new AdapterWebSocket(AdapterConfigureGenerico.WS_HOST, Number(AdapterConfigureGenerico.WS_PORT) || 80, AdapterConfigureGenerico.WS_SECURE === 'true' ? true : false, AdapterConfigureGenerico.WS_PATH, {}),

	dbLocal: new AdapterIndexedDB(`db_${process.env.REACT_APP_SISTEMA}_${process.env.REACT_APP_VERSION_MAJOR}`, Number(process.env.REACT_APP_INDEXEDDB_VERSION) || 1, [
		{ name: 'Pais', pk: '_id', index: [], encrpyt: false },
		{ name: 'Personal', pk: '_id', index: [], encrpyt: false },
		{ name: 'EstadoInterno', pk: '_id', index: [], encrpyt: false },
		{ name: 'Items', pk: '_id', index: [], encrpyt: false },
		{ name: 'ContratoOT', pk: 'ID_ContratoOT', index: [], encrpyt: false },
		{ name: 'DocumentacionBaremoPEX', pk: 'ID_DocumentacionBaremoPEX', index: [], encrpyt: false },
		{ name: 'DocumentacionPEX', pk: 'ID_DocumentacionPEX', index: [], encrpyt: false },
		{ name: 'PrecioEspecialidad', pk: '_id', index: [], encrpyt: false },
		{ name: 'PrecioMaterial', pk: '_id', index: [], encrpyt: false },
		{ name: 'ManoObraGlobal', pk: '_id', index: [], encrpyt: false },
		{ name: 'Cuadrillas', pk: '_id', index: [], encrpyt: false },
		{ name: 'CHILE_9512_Trabajos', pk: 'ID_Trabajo', index: [], encrpyt: false },
		{ name: 'COLOMBIA_9612_Trabajos', pk: 'ID_Trabajo', index: [], encrpyt: false },
		{ name: 'ALEMANIA_AL02_Trabajos', pk: 'ID_Trabajo', index: [], encrpyt: false },
		{ name: 'ALEMANIA_AL04_Trabajos', pk: 'ID_Trabajo', index: [], encrpyt: false },
		{ name: 'CHILE_9512_StockPersonal', pk: 'ID_StockPersonal', index: [], encrpyt: false },
		{ name: 'COLOMBIA_9612_StockPersonal', pk: 'ID_StockPersonal', index: [], encrpyt: false },
		{ name: 'ALEMANIA_AL02_StockPersonal', pk: 'ID_StockPersonal', index: [], encrpyt: false },
		{ name: 'ALEMANIA_AL04_StockPersonal', pk: 'ID_StockPersonal', index: [], encrpyt: false },
		{ name: 'ALEMANIA_AL02_AddressList', pk: 'ID_AddressList', index: [], encrpyt: false },
		{ name: 'ALEMANIA_AL04_AddressList', pk: 'ID_AddressList', index: [], encrpyt: false },
		{ name: 'ALEMANIA_AL02_Obras', pk: 'ID_incidencia', index: [], encrpyt: false },
		{ name: 'ALEMANIA_AL04_Obras', pk: 'ID_incidencia', index: [], encrpyt: false },
		{ name: 'CHILE_9512_StockAlmacen', pk: 'ID_StockAlmacen', index: [], encrpyt: false },
		{ name: 'COLOMBIA_9612_StockAlmacen', pk: 'ID_StockAlmacen', index: [], encrpyt: false },
		{ name: 'ALEMANIA_AL02_StockAlmacen', pk: 'ID_StockAlmacen', index: [], encrpyt: false },
		{ name: 'ALEMANIA_AL04_StockAlmacen', pk: 'ID_StockAlmacen', index: [], encrpyt: false },
		{ name: 'ALEMANIA_AL02_ConsumoMaterial', pk: 'ID_ConsumoMaterial', index: [], encrpyt: false },
		{ name: 'ALEMANIA_AL04_ConsumoMaterial', pk: 'ID_ConsumoMaterial', index: [], encrpyt: false },
		{ name: 'CHILE_9512_ConsumoMaterial', pk: 'ID_ConsumoMaterial', index: [], encrpyt: false },
		{ name: 'COLOMBIA_9612_ConsumoMaterial', pk: 'ID_ConsumoMaterial', index: [], encrpyt: false },
	]),

	online: navigator.onLine,
	countProcess: 0,

	loadingMaestros: true,
	availableUpdate: false,
	dataStockPersonalTotal: [],
	autoInventario: autoInventario
};

initialState.websocket.create();
initialState.websocket.init();

initialState.dbLocal.run();

const genericoSlice = createSlice({
	name: 'genericoSlice',
	initialState,
	reducers: {
		addLoading: (state, action) => {
			return {
				...state,
				loading: true,
				textLoading: action.payload.textLoading,
			};
		},
		removeLoading: state => {
			return {
				...state,
				loading: false,
				textLoading: '',
			};
		},
		showIconMenu: state => {
			return {
				...state,
				iconMenu: true,
			};
		},
		hideIconMenu: state => {
			return {
				...state,
				iconMenu: false,
			};
		},
		changeSaludo: (state, action) => {
			return {
				...state,
				showSaludo: action.payload,
			};
		},
		changeOnline: (state, action) => {
			return {
				...state,
				online: action.payload,
			};
		},
		changeCountProcess: (state, action) => {
			return {
				...state,
				countProcess: action.payload,
			};
		},
		changeAutoInventario: (state, action) => {
			AdapterStorage.set('autoInventario', action.payload)
			return {
				...state,
				autoInventario: action.payload,
			};
		},
		addCountProcess: (state) => {
			let count: number = state.countProcess;
			count += 1;
			return {
				...state,
				countProcess: count,
			};
		},
		removeLoadingMaestros: (state) => {
			return {
				...state,
				loadingMaestros: false
			};
		},
		changeAvailableUpdate: (state, action) => {
			return {
				...state,
				availableUpdate: action.payload,
			};
		},
		saveDataStockPersonalTotal: (state, action) => {
			return {
				...state,
				dataStockPersonalTotal: action.payload,
			};
		},
		deleteDataStockPersonalTotal: (state) => {
			return {
				...state,
				dataStockPersonalTotal: [],
			};
		},
	},
});


export const {
	showIconMenu,
	hideIconMenu,
	addLoading,
	removeLoading,
	changeSaludo,
	changeOnline,
	changeCountProcess,
	addCountProcess,
	removeLoadingMaestros,
	changeAvailableUpdate,
	saveDataStockPersonalTotal,
	deleteDataStockPersonalTotal,
	changeAutoInventario,
} = genericoSlice.actions;

export default genericoSlice.reducer;