import { useSelector } from 'react-redux'
import { Container } from '../../../../shared/Components/Container/Container'
import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { Children, FC, useEffect, useState } from 'react'
import { Card } from './card'
import ButtonFloating from '../../../../shared/Components/ButtonFloating/ButtonFloating'
import { InputSearch } from '../../../../shared/Components/ReactBootstrap/InputSearch/InputSearch'
import { AdapterConfigureGenerico } from '../../../../shared/Infraestructure/AdapterConfigureGenerico'

const countrySchedule: Record<string, { start: number, end: number }> = {
  '480': { start: 5, end: 9 },
  '512': { start: 5, end: 9 },
  '004': { start: 5, end: 9 }
}

interface iProps {
  handleSave: () => Promise<void>
}

export const View: FC<iProps> = ({ handleSave }) => {

  const langTrans = LanguageTranslate()
  const { dataAutoInventario } = useSelector((state: RootState) => state.autoInventario)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isVisible, setIsVisible] = useState(false)

  const { autoInventario } = useSelector((state: RootState) => state.generico)
  const { user } = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    const currentHour = new Date().getHours()
    if (!countrySchedule[user.Pais.Codigo]) {
      return
    }
    if (AdapterConfigureGenerico.MODE === 'prod') {
      const { start, end } = countrySchedule[user.Pais.Codigo]
      setIsVisible(currentHour >= start && currentHour < end)
    } else {
      setIsVisible(true)
    }
  }, [])

  return (
    <Container>
      <ElementBreadCrumb list={[
        { text: langTrans.moduloMain.textoLogistica, navigate: true, path: '/main/logistica' },
        { text: langTrans.menu['054'].titulo, navigate: false, path: '' }
      ]} />
      <InputSearch
        handleChange={(text) => setSearchTerm(text)}
        textLength={1}
        placeholder='Buscar por código'
      />
      <div className='d-flex flex-column gap-3'>
        {
          Children.toArray(
            dataAutoInventario
              .filter(e => e.CodigoLlave.toLowerCase().includes(searchTerm.toLowerCase()))
              .map(e => <Card {...e} />)
          )
        }
      </div>
      {
        (autoInventario && isVisible) &&
        <ButtonFloating
          onClick={handleSave}
          icono='fa-solid fa-floppy-disk'
          colorIcono='white'
          backgroundColor='#049bed'
          right={20}
        />
      }
    </Container>
  )
}
