import { Children, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { iDtoHomePassValorizacion } from '../../DocHP/Domain/iDtoHomePassValorizacion'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
import { FormikState } from 'formik'
import { DtoInitialValues } from '../../Shared'

interface iProps {
  trabajo: DtoTrabajosDE | null
  setValRechazadaXMetraje: Dispatch<SetStateAction<DtoValorizacion | null>>
  valRechazadaXMetraje: DtoValorizacion | null
  resetForm: (nextState?: Partial<FormikState<DtoInitialValues>> | undefined) => void
}

export const Valorizaciones = (props: iProps) => {

  const { trabajo, setValRechazadaXMetraje, valRechazadaXMetraje, resetForm } = props
  const langTranslate = LanguageTranslate()
  const [data, setData] = useState<iDtoHomePassValorizacion[]>([])

  useEffect(() => {
    if (!trabajo) return
    const data = trabajo.Ultima_PreLiquidacion.Valorizacion
      .flatMap((curr) =>
        curr.HomePass.filter((e) =>
          e.ClientType === 'Troncal' &&
          [
            'RECHAZADA POR METRAJE'
          ].includes(curr.Ultimo_Estado_Interno.Descripcion)
        ).map(e => ({
          ...e,
          valorizacion: curr
        }))
      )
    setData(data)
  }, [trabajo])

  if (!data.length) return null

  return (
    <table className='table table-striped' style={{ backgroundColor: 'gray' }}>
      <thead>
        <tr>
          <th scope='col'>{langTranslate.general.Codigo}</th>
          <th scope='col'>{langTranslate.general.Descripcion}</th>
          <th scope='col' className='text-center'>{langTranslate.general.Cantidad}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          Children.toArray(
            data.map(e =>
              <tr>
                <td>{e.valorizacion.DetalleChile.ManoObra.Codigo}</td>
                <td>{e.valorizacion.DetalleChile.ManoObra.Nombre}</td>
                <td className='text-center'>{e.Cantidad.toFixed(2)}</td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {
                    (
                      ['RECHAZADA POR METRAJE']
                        .includes(e.valorizacion.Ultimo_Estado_Interno.Descripcion) &&
                      e.valorizacion.Semana === 0
                    )
                      ? <input
                        type='radio'
                        name='seleccion'
                        checked={valRechazadaXMetraje?.ID_Valorizacion === e.valorizacion.ID_Valorizacion}
                        onChange={() => {
                          if (setValRechazadaXMetraje)
                            setValRechazadaXMetraje(e.valorizacion)
                          resetForm()
                        }}
                      />
                      : null
                  }
                </td>
              </tr>
            )
          )
        }
      </tbody>
    </table>
  )
}
