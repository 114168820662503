import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { ElementItemCard } from '../../../shared/Components/ElementItemCard';
import { EntityInformationDataInitial } from '../../../shared/Domain/EntityInformationDataInitial';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import './Style.scss';

interface PropsViewMain {
  informationDataInitial: EntityInformationDataInitial;
  onClickLoadCatalogo: Function;
  onClickLoadAll: Function;
};

export const ViewMain = (props: PropsViewMain) => {
  const languageTranslate = LanguageTranslate()
  return (
    <div className='ViewVariable'>
      <div className="container-fluid">
        {/** BLOQUE DE BREADCRUMB */}
        <div className="">
          <ElementBreadCrumb list={[{ text: languageTranslate.textoCatalogos, navigate: false, path: '' }]} />
          <button hidden className='position-absolute btn btn-sm btn-primary w-auto btn-accion' onClick={() => { props.onClickLoadAll(); }}>
            <i className="fa-solid fa-refresh" style={{ paddingRight: '5px' }} />{languageTranslate.moduloCatalogo.textoRefrescarTodo}
          </button>
        </div>

        {Object.keys(Object.fromEntries(Object.entries(props.informationDataInitial).filter(([key, value]) => value.date !== ''))).map((key: string) => (
          <ElementItemCard
            key={key}
            textHeader={key}
            textSubHeader={
              <span style={{ fontSize: '13px', paddingLeft: '5px', color: 'black' }} onClick={() => props.onClickLoadCatalogo(key)}>
                <i className="fa-solid fa-refresh" style={{ fontSize: '14px', cursor: 'pointer', paddingRight: '10px' }} />{languageTranslate.moduloCatalogo.textoRefrescar}
              </span>
            }
            ago={!props.informationDataInitial[key as keyof typeof props.informationDataInitial].called ? '' : AdapterGenerico.calculoTiempo(props.informationDataInitial[key as keyof typeof props.informationDataInitial].date as Date, languageTranslate.code as 'es' | 'en' | 'de')}
            state={props.informationDataInitial[key as keyof typeof props.informationDataInitial].called ? languageTranslate.moduloCatalogo.textoSincronizado : languageTranslate.moduloCatalogo.textoNoSincronizado}

          />
        ))}
      </div>
    </div >
  )
};