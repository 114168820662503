import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { Repository } from '../infraestructure/repository'

export class UseCaseSelectStockPersonalLocal {
    private repository: Repository

    constructor(_repository: Repository) {
        this.repository = _repository
    }

    public async exec(state: RootState) {
        return await this._exec(state)
    }

    private async _exec(state: RootState) {
        const data = await this.repository.selectStockPersonalLocal(state.auth.user)
        return data.filter(e => e.TipoStock.Nombre === 'NUEVO')
    }
}
