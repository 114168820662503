import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { DtoAmap } from '../../../Home/Domain/DtoAmap'
import { ObtenerMONoReservada } from 'sigo-package'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { DtoPrecioEspecialidad } from '../../../../../app/Domain/DtoPrecioEspecialidad'
import { InputSelect } from '../../../../shared/Components/ElementInputsCostume'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { InputNumber } from '../../../../shared/Components/ReactBootstrap/InputNumber/InputNumber'
import { DtoHPList, DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { DtoInitialValuesAddMO } from '../../Shared/Preliquidar/Interfaces/DtoInitialValuesAddMO'
import { typeHPList } from '../../DocHP/Domain/typeHPList'

interface props {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  dataManoObraGlobal: DtoManoObraGlobal[]
  dataPrecioEspecialidad: DtoPrecioEspecialidad[]
  trabajo: DtoTrabajosDE | null
  onSubmitFormAddMO: (values: DtoInitialValuesAddMO, formikHelpers: FormikHelpers<DtoInitialValuesAddMO>) => void | Promise<any>
  type?: typeHPList
  updateHP?: DtoHPList
}

const AddedReservaMO = ({ show, setShow, dataManoObraGlobal, dataPrecioEspecialidad, trabajo, onSubmitFormAddMO, type, updateHP }: props) => {

  const [dataMONoReservada, setDataMONoReservada] = useState<DtoAmap[]>([])
  const languageTranslate = LanguageTranslate()

  const schema = yup.object().shape({
    manoObra: yup.object().required('Campo es requerido'),
    cantidad: yup.number().min(0.1, 'Ingrese una cantidad mayor a 0').required('Campo es requerido')
  })

  const initialValues: DtoInitialValuesAddMO = {
    manoObra: '',
    cantidad: 0,
  }

  useEffect(() => {
    if (show && trabajo) {
      let data = ObtenerMONoReservada({ dataManoObraGlobal, dataPrecioEspecialidad, ManoObra: trabajo.ColeccionManoObra[0], Obra: trabajo.ColeccionObras[0] })
      for (const amap of data) {
        amap.ID_Amap = -100
      } 
      if ((type === 'Business' || type === 'Residential') && updateHP) {
        data = data.filter(e => ![
          updateHP.Asfaltado,
          updateHP.OC,
          updateHP.Soplado
        ].filter((e, i, arr) => arr[1].Estado && e.Estado)
          .map(e => e.Especialidad)
          .includes(e.DetalleChile.Especialidad.Codigo)
        )
      }
      if (type === 'Troncal') {
        data = data.filter(e => ![
          trabajo.Ultima_PreLiquidacion.Asfaltado,
          trabajo.Ultima_PreLiquidacion.OC,
          trabajo.Ultima_PreLiquidacion.Soplado
        ].filter((e, i, arr) => arr[1].Estado && e.Estado)
          .map(e => e.Especialidad)
          .includes(e.DetalleChile.Especialidad.Codigo)
        )
      }
      setDataMONoReservada(data)
    }
  }, [show])

  return (
    <Modal show={show} onHide={() => setShow(!show)}>
      <Modal.Header closeButton>
        <Modal.Title className='text-white'>Agregar MO</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={onSubmitFormAddMO}
        >
          {
            ({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form className='h-100 d-flex flex-column justify-content-between' noValidate onSubmit={handleSubmit}>
                <div>
                  <Row>
                    <InputSelect
                      label={languageTranslate.general.ManoDeObra}
                      name='manoObra'
                      onChange={(e, a) => handleChange({ target: { value: a, name: e } })}
                      values={values}
                      isRequired
                      options={dataMONoReservada
                        .filter(e => ![
                          ...trabajo?.Ultima_asignacion.Valorizaciones.map(v => v.ManoObra.Codigo) ?? [],
                        ].includes(e.DetalleChile.ManoObra.Codigo))
                        .map(e => ({ dataComplete: e, label: `${e.DetalleChile.ManoObra.Codigo} - ${e.DetalleChile.ManoObra.Nombre}`, value: e.DetalleChile.LineaCodigoCub }))
                      }
                      loading={false}
                      disabled={false}
                      disabledVirtualized
                      errors={errors}
                    />
                  </Row>
                  <Row>
                    <InputNumber
                      label={languageTranslate.general.Cantidad}
                      name='cantidad'
                      errors={errors}
                      touched={touched}
                      values={values}
                      handleChange={handleChange}
                      required
                      type='number'
                    />
                  </Row>
                </div>
                <Row>
                  <Col>
                    <Button className='w-100' type='submit'>Guardar</Button>
                  </Col>
                </Row>
              </Form>
            )
          }
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AddedReservaMO