import { Dispatch } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import {
	addLoading,
	hideIconMenu,
	removeLoading,
} from '../../../../shared/Infraestructure/SliceGenerico'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { useEffect, useState } from 'react'
import { UseCaseSelectTrabajosLocal } from '../Application/UseCaseSelectTrabajosLocal'
import { AdapterConfigure } from './AdapterConfigure'
import { RepositoryImplMain } from './RepositoryImplMain'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { UseCaseSearchTrabajos } from '../Application/UseCaseSearchTrabajos'
import { changeTextSearch } from './SliceTrabajosDE'
import { UseCaseSelectTrabajos } from '../Application/UseCaseSelectTrabajos'
import { DtoTrabajosDE } from '../Domain/DtoTrabajosDE'

export const Controller = () => {

	const dispatch: Dispatch = useDispatch()
	const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
	const { user } = useSelector((state: RootState) => state.auth)
	const repository = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY)
	const [showOffCanvas, setShowOffCanvas] = useState<boolean>(false)
	const [dataTrabajos, setDataTrabajos] = useState<DtoTrabajosDE[]>([])
	const [trabajos, setTrabajos] = useState<DtoTrabajosDE[]>([])
	useEffect(() => setTrabajos(dataTrabajos), [dataTrabajos])

	const init = async () => {
		try {
			dispatch(hideIconMenu())
			dispatch(addLoading({ textLoading: 'Buscando Trabajos ...' }))
			const trabajos = await new UseCaseSelectTrabajosLocal(repository).exec(user)
			setDataTrabajos(trabajos)
		} catch (error) {
			console.error(error)
			AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
		} finally {
			dispatch(removeLoading())
		}
	}

	const handleSearch = async (text: string) => {
		try {
			dispatch(addLoading({ textLoading: 'Buscando Trabajo' }))
			const trabajos = await new UseCaseSearchTrabajos(repository).exec(user, text)
			if (!trabajos.length) AdapterGenerico.createToast(`Sin resultados para ${text}`, 'info')
			setDataTrabajos(trabajos)
		} catch (error) {
			console.error(error)
			AdapterGenerico.createMessage('Error', (error as Error).message, 'error')
		} finally {
			dispatch(removeLoading())
		}
	}

	const handleChange = (text: string) => {
		try {
			dispatch(changeTextSearch(text))
		} catch (error) {
			console.error(error)
			AdapterGenerico.createMessage('Error', (error as Error).message, 'error')
		}
	}

	const handleRefresh = async () => {
		try {
			dispatch(addLoading({ textLoading: 'Cargando trabajos...' }))
			const trabajos = await new UseCaseSelectTrabajos(repository).exec(user)
			setDataTrabajos(trabajos)
		} catch (error) {
			console.error(error)
			AdapterGenerico.createMessage('Error', (error as Error).message, 'error')
		} finally {
			dispatch(removeLoading())
		}
	}

	return {
		init,
		handleSearch,
		handleChange,
		trabajos,
		showOffCanvas,
		setShowOffCanvas,
		handleRefresh
	}
}
