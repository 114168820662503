import { Children, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { InputSelect } from '../../../../shared/Components/ElementInputsCostume'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { typeMaterial } from '../Domain/types'
import { DtoFormAddMaterial } from '../Domain/DtoFormAddMaterial'
import { DtoMaterialesUtiRet } from '../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { Button, Col, Row } from 'react-bootstrap'
import { InputNumber } from '../../../../shared/Components/ReactBootstrap/InputNumber/InputNumber'
import { Formik } from 'formik'
import * as yup from 'yup'
import { DtoValueOnSelect } from '../Domain/DtoValueOnSelect'
import { ShowAvailable } from './ShowAvailable'
import { DtoInitialValues } from '../../Shared'
// import { typeHPList } from '../../DocHP/Domain/typeHPList'
import {
	CodigosMaterialDuctosAL04,
	IMO,
	mapaMOMaterialAL04,
} from 'sigo-package'
import { DtoDataMateriales } from '../Domain/DtoDataMateriales'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { DtoDataSelectAsignaciones } from '../Domain/DtoDataSelectAsignaciones'
interface props {
	dataMateriales: DtoDataMateriales[]
	onChange: (name: string, value: any, materialesInstalado: DtoMaterialesUtiRet[]) => { dataAlmacen: DtoDataMateriales[], dataMateriales: DtoDataMateriales[] } | undefined
	materialesInstalado: DtoMaterialesUtiRet[]
	materialesRetirado: DtoMaterialesUtiRet[]
	setMaterialesRetirado: Dispatch<SetStateAction<DtoMaterialesUtiRet[]>>
	setMaterialesInstalado: Dispatch<SetStateAction<DtoMaterialesUtiRet[]>>
	valuesAux: DtoInitialValues,
	materialFijo: boolean
	// address?: {
	// 	ID_AddressList: number
	// 	Home_ID: string
	// 	typeHPList: typeHPList
	// }
	dataMaterialesAlmacen: DtoDataMateriales[]
}

export const AddedMaterial = ({
	dataMateriales,
	onChange,
	setMaterialesInstalado,
	setMaterialesRetirado,
	materialesInstalado,
	materialesRetirado,
	valuesAux,
	materialFijo,
	// address,
	dataMaterialesAlmacen
}: props) => {

	const langTranslate = LanguageTranslate()
	const dataTipoMaterial: typeMaterial[] = ['Instalado', 'Retirado']
	const [moSeleccionado, setMoSeleccionado] = useState<DtoDataSelectAsignaciones>({} as DtoDataSelectAsignaciones)
	const [cantidadBloqueada, setCantidadBloqueada] = useState(false)
	const [existsInRulesMO, setExistsInRulesMO] = useState<IMO>()

	useEffect(() => {
		const existsInRulesMO = mapaMOMaterialAL04.get(valuesAux.asignacion.dataComplete.ManoObra.Codigo)
		setExistsInRulesMO(existsInRulesMO)
		setMoSeleccionado(valuesAux.asignacion.dataComplete)
	}, [valuesAux.asignacion])

	const initialValues: DtoFormAddMaterial = {
		tipoMaterial: new DtoValueOnSelect(),
		material: new DtoValueOnSelect(),
		cantidad: 1
	}

	const schema = yup.object().shape({
		tipoMaterial: yup
			.object()
			.shape({ value: yup.string().min(1, 'Seleccione una opción') }),
		material: yup
			.object()
			.shape({
				value: yup
					.string()
					.min(1, 'Seleccione una opción')
			}),
		cantidad: yup.number().min(0.1, 'Ingrese una cantidad mayor a 0')
			.required('Campo es requerido')
			.test({
				test(value, ctx) {
					if (this.parent.tipoMaterial.dataComplete === 'Retirado') return true
					if (valuesAux.cantidad <= 0)
						return ctx.createError({ message: `Ingrese una cantidad mayor a 0 de la tarea` })
					if (value !== undefined) {
						const disponible = this.parent.material.dataComplete.CantidadDisponible
						if (value > disponible) {
							return ctx.createError({ message: `Cantidad supera lo Disponible` })
						}
					}
					return true
				}
			})
	})

	const deleteMaterial = async (tipoMaterial: string, CodigoLlave: string) => {
		switch (tipoMaterial) {
			case 'Instalado':
				setMaterialesInstalado((prev) => prev.filter(e => e.CodigoLlave !== CodigoLlave && e.Motivo.Descripcion !== CodigoLlave))
				break
			case 'Retirado':
				setMaterialesRetirado((prev) => prev.filter(e => e.CodigoLlave !== CodigoLlave))
				break
		}
	}

	const agregarSubMateriales = (CodigoMat: string, CodigoLlave: string) => {
		const matAux: DtoMaterialesUtiRet[] = []
		if (existsInRulesMO && existsInRulesMO.Materiales instanceof Map) {
			const existMat = existsInRulesMO.Materiales.get(CodigoMat)
			if (existMat && existMat.Materiales instanceof Array) {
				for (const material of existMat.Materiales) {
					const dataMateriales = onChange('tipoMaterial', { value: 'Instalado' }, materialesInstalado)
					if (!dataMateriales) throw new Error(`¡No se pudo seleccionar item!`)
					const consumirStockPersonal = CodigosMaterialDuctosAL04.includes(material.Codigo)
					const dataMaterial = consumirStockPersonal ? dataMateriales.dataMateriales : dataMateriales.dataAlmacen
					const stocks = dataMaterial.filter(e => e.Codigo === material.Codigo)
					if (!stocks.length) {
						throw new Error(`¡No tiene en stock ${consumirStockPersonal ? 'personal' : 'almacén'} ${material.Codigo}!`)
					}
					const cantidad = (material.CantidadFija ? material.Cantidad : valuesAux.cantidad) * material.Factor
					for (const [i, stock] of stocks.entries()) {
						if (stock.CantidadDisponible < cantidad && i === stocks.length - 1) {
							throw new Error(`¡No tiene stock ${consumirStockPersonal ? 'personal' : 'almacén'} disponible material ${material.Codigo}!`)
						} else if (stock.CantidadDisponible < cantidad) {
							continue
						} else {
							stock.Motivo.Codigo = CodigoMat
							stock.Motivo.Descripcion = CodigoLlave
							stock.Cantidad = cantidad
							stock.CantidadInformada = cantidad
							matAux.push(stock)
							break
						}
					}
				}
			}
		}
		return matAux
	}

	return (
		<Formik
			validationSchema={schema}
			initialValues={initialValues}
			onSubmit={(values, { resetForm }) => {
				try {
					values.material.dataComplete.Cantidad = values.cantidad
					values.material.dataComplete.CantidadInformada = values.cantidad
					const matAux = agregarSubMateriales(values.material.dataComplete.Codigo, values.material.dataComplete.CodigoLlave)
					const materialAdicional = matAux.map((e: any) => {
						const { CantidadDisponible, Lote, Bobina, ...el } = e
						return el
					})
					delete (values.material.dataComplete as any).CantidadDisponible
					delete (values.material.dataComplete as any).Lote
					delete (values.material.dataComplete as any).Bobina
					switch (values.tipoMaterial.dataComplete) {
						case 'Instalado':
							setMaterialesInstalado((prev) => [...prev, values.material.dataComplete, ...materialAdicional])
							break
						case 'Retirado':
							setMaterialesRetirado((prev) => [...prev, values.material.dataComplete, ...materialAdicional])
							break
						default:
							break
					}
					resetForm()
				} catch (error) {
					console.error(error)
					AdapterGenerico.createMessage('¡Alerta!', (error as Error).message, 'error')
				}
			}}
		>
			{
				({ values, handleChange, errors, handleSubmit, touched, setFieldValue, resetForm }) => {
					// eslint-disable-next-line react-hooks/rules-of-hooks
					useEffect(() => {
						try {
							if (!moSeleccionado.Codigo) return
							setCantidadBloqueada(false)
							setFieldValue('cantidad', 0)
							if (existsInRulesMO && existsInRulesMO.Materiales instanceof Map) {
								const existMat = existsInRulesMO.Materiales.get(values.material?.dataComplete?.Codigo);
								if (existMat && existMat.Materiales instanceof Array && existMat.CantidadPadre) {
									setFieldValue('cantidad', valuesAux.cantidad)
									setCantidadBloqueada(existMat.CantidadPadre)
								}
							}
						} catch (error) {
							console.error(error)
							AdapterGenerico.createMessage('¡Alerta!', (error as Error).message, 'error')
							resetForm()
						}
					}, [values.material, setFieldValue])
					// eslint-disable-next-line react-hooks/rules-of-hooks
					useEffect(() => {
						resetForm()
						setMaterialesInstalado([])
						// setMaterialesRetirado([])
					}, [valuesAux, resetForm])
					return (
						<div>
							{
								materialFijo
									? null
									: <InputSelect
										label={langTranslate.general.TipoMaterial}
										name='tipoMaterial'
										onChange={(name, value) => {
											handleChange({ target: { name, value } })
											onChange(name, value, materialesInstalado)
										}}
										values={values}
										isRequired
										options={dataTipoMaterial.map(e => ({
											label: e === 'Retirado' ? 'Chatarra' : e,
											value: e,
											dataComplete: e
										}))}
										errors={errors}
										touched={touched}
									/>
							}
							{
								values.tipoMaterial.value &&
								<Row>
									<InputSelect
										label={langTranslate.general.Material}
										name='material'
										onChange={(name, value) => handleChange({ target: { name, value } })}
										values={values}
										isRequired
										options={dataMateriales
											.concat(dataMaterialesAlmacen)
											.map(e => ({
												label: `${e.Codigo} - ${e.Descripcion} ${e.Lote ? `- Lote: ${e.Lote}` : ''} ${e.Bobina ? `- Bobina: ${e.Bobina}` : ''}`.trim(),
												value: e.CodigoLlave,
												dataComplete: e
											}))
											.filter(e => !new Set([
												...materialesInstalado.map(e => e.CodigoLlave),
												...materialesRetirado.map(e => e.CodigoLlave)
											]).has(e.value))
											.filter(mat =>
												existsInRulesMO /*&& existsInRulesMO.Observacion === 'MOSTRAR_MATERIAL_PROPIO'*/
													? existsInRulesMO.Materiales instanceof Map
														? existsInRulesMO.Materiales.has(mat.dataComplete.Codigo)
														: Array.isArray(existsInRulesMO.Materiales)
															? existsInRulesMO.Materiales.some(el => el.Codigo === mat.dataComplete.Codigo)
															: true
													: true
											)
										}
										errors={errors}
										touched={touched}
									/>
								</Row>
							}
							{
								(values.tipoMaterial.dataComplete === 'Instalado' && values.material.value) &&
								<ShowAvailable cantidadDisponible={values.material.dataComplete.CantidadDisponible} />
							}
							{
								values.tipoMaterial.value &&
								<>
									<Row className='mb-2'>
										<Col xs='6'>
											<InputNumber
												label={langTranslate.general.Cantidad}
												name='cantidad'
												values={values}
												handleChange={handleChange}
												errors={errors}
												touched={touched}
												type='number'
												disabled={cantidadBloqueada}
											/>
										</Col>
									</Row>
									<Row>
										<Col xs='12' className='d-flex flex-column justify-content-center'>
											<Button onClick={(e) => handleSubmit()} className='btn-success text-white'>
												<i className='fa-solid fa-plus'></i>
											</Button>
										</Col>
									</Row>
								</>
							}
							{
								(materialesInstalado.length || materialesRetirado.length) ?
									<Row>
										<table className='table table-striped' style={{ backgroundColor: 'gray' }}>
											<thead>
												<tr>
													<th scope='col'>{langTranslate.general.Cod}</th>
													<th scope='col'>{langTranslate.general.Desc}</th>
													<th scope='col'>{langTranslate.general.Cant}</th>
													<th scope='col'>{langTranslate.general.TipoMaterial}</th>
													<th scope='col' style={{ textAlign: 'center' }}>x</th>
												</tr>
											</thead>
											<tbody>
												{

													Children.toArray(
														[
															...materialesInstalado.map(e => ({ ...e, Tipo: 'Instalado' })),
															...materialesRetirado.map(e => ({ ...e, Tipo: 'Retirado' })),
														].map(e =>
															<tr>
																<td>{e.Codigo}</td>
																<td>{e.Descripcion}</td>
																<td>{e.Cantidad}</td>
																<td>{e.Tipo}</td>
																<td
																	onClick={() => e.Motivo.Descripcion.length ? null : deleteMaterial(e.Tipo, e.CodigoLlave)}
																	style={{ verticalAlign: 'middle', textAlign: 'center' }}
																>
																	{
																		e.Motivo.Descripcion.length
																			? null
																			: <i className='fa-solid fa-trash' style={{ fontSize: 15, color: 'red' }} />
																	}
																</td>
															</tr>
														)
													)
												}
											</tbody>
										</table>
									</Row>
									: null
							}
						</div>
					)
				}
			}
		</Formik>
	)
}
