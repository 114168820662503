import { createSlice } from '@reduxjs/toolkit'

interface Slice {
  textSearch: string
}

const initialState: Slice = {
  textSearch: '',
}

const slice = createSlice({
  name: 'programadosDEAL04Slice',
  initialState,
  reducers: {
    changeTextSearch: (state, action) => {
      return {
        ...state,
        textSearch: action.payload,
      }
    },
  },
})

export const {
  changeTextSearch,
} = slice.actions

export default slice.reducer