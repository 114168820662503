import { DtoTrabajosDE } from '../../../Lista/Domain/DtoTrabajosDE'
import { DtoData } from '../../Domain/DtoData'
import { CalcularResumenLiquidaciones } from 'sigo-package'

export const generarData = (trabajo: DtoTrabajosDE): DtoData[] => {
  const { MoUo } = CalcularResumenLiquidaciones(trabajo, [trabajo])
  return trabajo.Ultima_asignacion.Valorizaciones.map(e => {
    const asignacion = MoUo.find(el => el.LineaCodigoCub === e.LineaCodigoCub)
    return { ...e, CantidadUsado: asignacion ? asignacion.Cantidad : 0 }
  })
}