import { RepositoryMain } from '../Domain/RepositoryMain'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { DtoReqSelectTrabajoDE } from '../../Lista/Domain/DtoReqSelectTrabajoDE'

export class UseCaseSelectTrabajo {
  private repository: RepositoryMain

  constructor(_repository: RepositoryMain) {
    this.repository = _repository
  }

  public async exec(user: EntityDataUsuario, ID_Trabajo: number): Promise<DtoTrabajosDE> {
    return await this._exec(user, ID_Trabajo)
  }

  private async _exec(user: EntityDataUsuario, ID_Trabajo: number): Promise<DtoTrabajosDE> {

    const params: DtoReqSelectTrabajoDE = {
      Header: {
        Coleccion: {
          Codigo: user.Delegacion.Codigo,
          Nombre: user.Pais.Nombre,
        },
        Filtros: [
          {
            $match: { ID_Trabajo }
          },
          {
            $lookup: {
              from: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Obras`,
              localField: 'ID_GOM',
              foreignField: 'AreaGOM.GOMS.ID_GOM',
              as: 'ColeccionObras'
            }
          },
          {
            $lookup: {
              from: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_ManoObra`,
              localField: 'ID_GOM',
              foreignField: 'ID_GOM',
              as: 'ColeccionManoObra'
            }
          },
          {
            $lookup: {
              from: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Reserva`,
              localField: 'ID_GOM',
              foreignField: 'ID_GOM',
              as: 'ColeccionReserva'
            }
          },
          {
            $lookup: {
              from: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_HPListMO`,
              let: { ordenTrabajo: { $arrayElemAt: ['$ColeccionObras.OrdenTrabajo', 0] } },
              pipeline: [
                {
                  $match: {
                    $expr: {
                      $eq: ['$Plan', '$$ordenTrabajo']
                    }
                  }
                }
              ],
              as: 'ColeccionHPListMO'
            }
          },
          {
            $lookup: {
              from: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_AddressList`,
              let: { ordenTrabajo: { $arrayElemAt: ['$ColeccionObras.OrdenTrabajo', 0] } },
              pipeline: [
                {
                  $match: {
                    $expr: {
                      $eq: ['$KeyDP', '$$ordenTrabajo']
                    }
                  }
                }
              ],
              as: 'ColeccionAddressList'
            }
          },
          {
            $match: {}
          },
          { $sort: { _id: -1 } }, { $limit: 10 },
          {
            $project: {
              ID_Trabajo: 1,
              ID_GOM: 1,
              ZonaAsignada: 1,
              'Ultima_asignacion.Fecha': 1,
              'Ultima_asignacion.Personal.Identificacion': 1,
              'Ultima_asignacion.Empresa.Codigo': 1,
              'Ultima_asignacion.Empresa.Propia': 1,
              'Ultima_asignacion.Valorizaciones': 1,
              'Ultima_asignacion.Cuadrilla.Codigo': 1,
              'Ultimo_Estado_Interno.Descripcion': 1,
              'Ultima_PreLiquidacion.Valorizacion': 1,
              'Ultima_PreLiquidacion.OC': 1,
              'Ultima_PreLiquidacion.Soplado': 1,
              'Ultima_PreLiquidacion.Asfaltado': 1,
              'Pais.Nombre': 1,
              'Pais.Codigo': 1,
              'Delegacion.Codigo': 1,
              'PartesDiarios': 1,
              'ColeccionObras.ID_incidencia': 1,
              'ColeccionObras.Proyecto': 1,
              'ColeccionObras.OrdenTrabajo': 1,
              'ColeccionObras.ContratoOT.Codigo': 1,
              'ColeccionObras.ContratoOT.Sistema': 1,
              'ColeccionObras.ZonaAsignada': 1,
              'ColeccionObras.DetalleChile.agencia': 1,
              'ColeccionObras.DetalleChile.numoe': 1,
              'ColeccionObras.DetalleChile.codigo': 1,
              'ColeccionObras.Pais.Codigo': 1,
              'ColeccionObras.Delegacion.Codigo': 1,
              'ColeccionManoObra.ID_ManoObra': 1,
              'ColeccionManoObra.Valorizacion': 1,
              'ColeccionReserva.Item': 1,
              'ColeccionAddressList.ID_AddressList': 1,
              'ColeccionAddressList.KeyDP': 1,
              'ColeccionAddressList.AddressID': 1,
              'ColeccionAddressList.StreetName': 1,
              'ColeccionAddressList.HouseNumber': 1,
              'ColeccionAddressList.HouseNumbreComplement': 1,
              'ColeccionAddressList.ResidentialHPList': 1,
              'ColeccionAddressList.BusinessHPList': 1,
              'ColeccionHPListMO.ID_HPListMO': 1,
              'ColeccionHPListMO.Type': 1,
              'ColeccionHPListMO.Cantidad': 1,
              'ColeccionHPListMO.Identification': 1,
              'ColeccionObras.Anexos': 1,
              'ColeccionObras.TotalesGeneralObra.PresupuestadoTotalObra.TotMetros': 1,
              'ColeccionObras.DetalleChile.central': 1,
              'ColeccionObras.Ubicacion.Municipio': 1
            },
          }
        ],
      },
    }
    const trabajo = await this.repository.selectTrabajo(params, user, ID_Trabajo)
    if (!trabajo || !Array.isArray(trabajo) || trabajo.length !== 1) throw Error(`¡No se pudo obtener trabajo!`)
    if (!trabajo[0].ColeccionObras || !Array.isArray(trabajo[0].ColeccionObras) || trabajo[0].ColeccionObras.length !== 1) throw Error(`¡No se pudo obtener obra!`)
    if (!trabajo[0].ColeccionManoObra || !Array.isArray(trabajo[0].ColeccionManoObra) || trabajo[0].ColeccionManoObra.length !== 1) throw Error(`¡No se pudo obtener mano obra!`)
    if (!trabajo[0].ColeccionReserva || !Array.isArray(trabajo[0].ColeccionReserva) || trabajo[0].ColeccionReserva.length !== 1) throw Error(`¡No se pudo obtener reserva!`)
    if (!trabajo[0].ColeccionAddressList || !Array.isArray(trabajo[0].ColeccionAddressList)) throw Error(`¡No se pudo obtener reserva!`)
    return trabajo[0]
  }
}
