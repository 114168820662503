import { Dispatch, SetStateAction, useState } from 'react'
import { ElementItemCardGenerico } from '../../../../shared/Components/Programados/ElementItemCardGenerico'
import { EmptyData } from '../../../../shared/Components/ReactBootstrap/EmptyData/EmptyData'
import { InputSearch } from '../../../../shared/Components/ReactBootstrap/InputSearch/InputSearch'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { OffCanvasBottom } from '../../../../shared/Components/ReactBootstrap/OffCanvasBottom/OffCanvasBottom'
import ButtonFloating from '../../../../shared/Components/ButtonFloating/ButtonFloating'
import { AdapterConfigure } from '../Infrastructure/AdapterConfigure'
import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb'
import { Container } from '../../../../shared/Components/Container/Container'
import { DtoTrabajosDE } from '../Domain/DtoTrabajosDE'
import { Link } from 'react-router-dom'

interface Props {
    handleSeach: (text: string) => void
    handleChange: (text: string) => void
    handleRefresh: () => void
    trabajos: DtoTrabajosDE[]
    showOffCanvas: boolean
    setShowOffCanvas: Dispatch<SetStateAction<boolean>>
}

export const ViewMain = (props: Props) => {

    const { handleSeach, handleChange, handleRefresh, trabajos, setShowOffCanvas, showOffCanvas } = props
    const languageTranslate = LanguageTranslate()
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [searchRechzadas, setSearchRechazadas] = useState<boolean>(false)

    const handleFilterRechazadas = () => {
        setSearchTerm('')
        setSearchRechazadas(!searchRechzadas)
    }

    return (
        <>
            <Container>
                <ElementBreadCrumb list={[
                    { text: languageTranslate.moduloMain.textoProgramados, navigate: true, path: `${AdapterConfigure.ROUTE_PROGRAMADOS}` },
                    { text: languageTranslate.menu['005'].titulo, navigate: false, path: '' }]}
                />
                <InputSearch
                    handleChange={(text) => {
                        setSearchRechazadas(false)
                        setSearchTerm(text.toUpperCase())
                    }}
                />
                {
                    trabajos.length
                        ? trabajos
                            .filter((el) => {
                                const OrdenTrabajo = el.ColeccionObras[0].OrdenTrabajo.toUpperCase()
                                const estados = ['RECHAZADA POR DOCUMENTACION', 'RECHAZADA POR METRAJE']
                                const estadoInterno = el.Ultima_PreLiquidacion.Valorizacion.some(e => estados.includes(e.Ultimo_Estado_Interno.Descripcion))
                                if (searchTerm) {
                                    if (OrdenTrabajo.includes(searchTerm)) return el
                                    return false
                                }
                                if (searchRechzadas) {
                                    if (estadoInterno) return el
                                    else return false
                                }
                                return el
                            })
                            .map((e, i) => (
                                <div key={i} style={{ position: 'relative' }}>
                                    {
                                        e.Ultima_PreLiquidacion.Valorizacion.some(v => ['RECHAZADA POR DOCUMENTACION'].includes(v.Ultimo_Estado_Interno.Descripcion)) &&
                                        <Link to={`${AdapterConfigure.ROUTE_TRABAJOS}/${e.ID_Trabajo}/RECHAZADA-POR-DOCUMENTACION`}>
                                            <i
                                                className='fa-solid fa-triangle-exclamation'
                                                style={{ padding: 4, position: 'absolute', top: 8, left: 8, zIndex: 1, color: 'orange', fontSize: 24 }}
                                            />
                                        </Link>
                                    }
                                    {
                                        e.Ultima_PreLiquidacion.Valorizacion.some(v => ['RECHAZADA POR METRAJE'].includes(v.Ultimo_Estado_Interno.Descripcion)) &&
                                        <Link to={`${AdapterConfigure.ROUTE_TRABAJOS}/${e.ID_Trabajo}/RECHAZADA-POR-METRAJE`}>
                                            <i
                                                className='fa-solid fa-triangle-exclamation'
                                                style={{
                                                    padding: 4,
                                                    position: 'absolute',
                                                    top: 8,
                                                    left: e.Ultima_PreLiquidacion.Valorizacion.some(v => ['RECHAZADA POR DOCUMENTACION'].includes(v.Ultimo_Estado_Interno.Descripcion))
                                                        ? 48
                                                        : 8,
                                                    zIndex: 1,
                                                    color: 'red',
                                                    fontSize: 24
                                                }}
                                            />
                                        </Link>
                                    }
                                    <ElementItemCardGenerico
                                        key={i}
                                        icono
                                        linkIcono1={`${AdapterConfigure.ROUTE_TRABAJOS}/${e.ID_Trabajo}`}
                                        icono2='fa-solid fa-screwdriver-wrench'
                                        linkIcono2={`${AdapterConfigure.ROUTE_PRELIQUIDAR}/${e.ID_Trabajo}`}
                                        icono3='fa-solid fa-folder-open'
                                        linkIcono3={`${AdapterConfigure.ROUTE_DOCHP}/${e.ID_Trabajo}`}
                                        icono4='fa-regular fa-rectangle-list'
                                        linkIcono4={`${AdapterConfigure.ROUTE_MO}/${e.ID_Trabajo}`}
                                        text1={languageTranslate.general.Obra}
                                        data1={<span style={{ color: 'white' }}>{e.ColeccionObras[0].OrdenTrabajo}</span>}
                                        text2={languageTranslate.general.Estado}
                                        data2={<span style={{ color: 'white' }}>{e.Ultimo_Estado_Interno.Descripcion}</span>}
                                        text3={languageTranslate.general.Comunidad}
                                        data3={<span style={{ color: 'white' }}>{e.ColeccionObras[0].DetalleChile.central}</span>}
                                    />
                                </div>
                            ))
                        : <EmptyData />
                }
            </Container>
            {
                navigator.onLine &&
                <>
                    <ButtonFloating
                        onClick={() => setShowOffCanvas(true)}
                        icono='fa-solid fa-magnifying-glass'
                        colorIcono='#2C3E50'
                    />
                    <ButtonFloating
                        onClick={handleRefresh}
                        icono='fa-solid fa-arrows-rotate'
                        colorIcono='white'
                        backgroundColor='#079455'
                        right={70}
                    />
                    <ButtonFloating
                        onClick={handleFilterRechazadas}
                        icono='fa-solid fa-triangle-exclamation'
                        colorIcono='#ffa811'
                        backgroundColor='#ff1d13'
                        right={124}
                    />
                </>
            }
            <OffCanvasBottom
                height={15}
                show={showOffCanvas}
                setShow={setShowOffCanvas}
                children={
                    <InputSearch handleSearch={(e) => {
                        handleSeach(e)
                        setShowOffCanvas(false)
                    }}
                    />
                }
            />
        </>
    )
}