import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AdapterStorage } from "../../../shared/Infraestructure/AdapterStorage";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { signOut } from "../../../shared/Infraestructure/SliceAuthentication";
import { hideIconMenu, showIconMenu } from "../../../shared/Infraestructure/SliceGenerico";
import { UseCaseLogout } from "../Application/UseCaseLogout";
import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { UseCaseRespuestaConexionFirma } from "../Application/UseCaseRespuestaConexionFirma";
import { changeRoute } from '../Domain/SliceMain'
import { useEffect } from "react";
import { changeShowBtnFirmar } from '../../../Logistica/DespachosAlmacen/Domain/SliceDespachoAlmacen';

export const Controller = () => {
  const { user, auth, menu } = useSelector((state: RootState) => state.auth);
  const { iconMenu, showSaludo, websocket, dbLocal, online, countProcess } = useSelector((state: RootState) => state.generico);
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useDispatch();
  const languageTranslate = LanguageTranslate();

  const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

  const location = useLocation();

  useEffect(() => { dispatch(changeRoute(location.pathname)) }, [location]);

  interface Data {
    Accion: keyof typeof actions;
    data: any
  }

  const actions = {
    PWA_LOGISTICS_CONN: async ({ Identificacion, ID_DespachoAlmacen }: any) => {
      if (Identificacion !== user.Identificacion) return;
      const confirmacion: boolean = await AdapterGenerico.createMessage(
        languageTranslate.general.tituloPreguntaFirma,
        languageTranslate.general.descripcionPreguntaFirma, 'question', true);
      if (confirmacion) {
        await new UseCaseRespuestaConexionFirma(repository).exec(true);
        dispatch(changeShowBtnFirmar(true));
        navigate(`${AdapterConfigure.REDIRECT_FIRMAR}${ID_DespachoAlmacen}`);
      } else {
        await new UseCaseRespuestaConexionFirma(repository).exec(false);
      }
    }
  };
  const Rooms = [
    {
      channel: 'SALA_LOGISTICA',
      listener: async (data: Data) => {
        try {
          const accion = actions[data.Accion];
          if (accion) {
            await accion(data.data);
          } else {
            console.warn(`Acción desconocida: ${data.Accion}`);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  ]

  const init = async () => {
    if (navigator.onLine) iniciarSocket();
  };

  const iniciarSocket = async () => {
    websocket.init();
    websocket.removeChannelListener(Rooms.map(e => e.channel));
    websocket.addChannelListener(Rooms);
  };

  const onClickHome = async () => {
    try {
      dispatch(hideIconMenu());
      navigate(`/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_HOME}`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const onClickProgramados = async () => {
    try {
      dispatch(hideIconMenu());
      navigate(`/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS}`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  }

  const onClickLogistica = async () => {
    try {
      dispatch(hideIconMenu());
      navigate(`/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA}`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  }

  const onClickATC = async () => {
    try {
      dispatch(hideIconMenu());
      navigate(`/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_ATC}`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  }

  const onClickHelp = async () => {
    try {
      let confirmacion: boolean = await AdapterGenerico.createMessage(languageTranslate.moduloMain.textoTituloPreguntaAyuda, languageTranslate.moduloMain.textoDescripcionPreguntaAyuda, 'question', true);
      if (!confirmacion) return;
      window.open('https://www.cobraperu.com.pe/helpcenter/sistema-sgso/', '_blank');
    } catch (error) {
      console.error(error);
    }
  };

  const onClickReport = async () => {
    try {
      let confirmacion: boolean = await AdapterGenerico.createMessage(languageTranslate.moduloMain.textoTituloPreguntaReporte, languageTranslate.moduloMain.textoDescripcionPreguntaReporte, 'question', true);
      if (!confirmacion) return;
      window.open('https://www.cobraperu.com.pe/glpi/', '_blank');
    } catch (error) {
      console.error(error);
    }
  };

  const onClickProfile = async () => {
    try {
      dispatch(hideIconMenu());
      navigate(`/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROFILE}`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const onClickVariable = async () => {
    try {
      navigate(`/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_VARIABLE}`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const onClickLogout = async () => {
    let { token }: { token: string; } = AdapterStorage.get('token');
    (new UseCaseLogout(repository)).exec(token);
    dispatch(hideIconMenu());
    dispatch(signOut());
    // dispatch(changePermisoVariable({ arrIdPaises: [], arrIdGrupos: [], arrIdDelegaciones: [], arrIdOT: [] }));
    navigate(`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`, { replace: true });
  };

  const onShowIconMenu = () => {
    dispatch(showIconMenu());
  };

  const onHideIconMenu = () => {
    dispatch(hideIconMenu());
  };

  const end = async () => {
  };

  return {
    auth,
    user,
    menu,

    iconMenu,
    showSaludo,

    online,
    countProcess,

    init,
    end,

    onClickHome,
    onClickProgramados,
    onClickLogistica,
    onClickATC,
    onClickHelp,
    onClickReport,
    onClickProfile,
    onClickVariable,
    onClickLogout,
    onShowIconMenu,
    onHideIconMenu,
  };
}