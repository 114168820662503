import { useDispatch } from 'react-redux'
import { addLoading, changeAutoInventario, hideIconMenu, removeLoading } from '../../../../shared/Infraestructure/SliceGenerico'
import { UseCaseSelectStockPersonalLocal } from '../application/useCaseSelectStockPersonalLocal'
import { Repository } from './repository'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { AdapterConfigure } from '../../shared/infrastructure/AdapterConfigure'
import { UseCaseSelect } from '../application/useCaseSelect'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { UseCaseSave } from '../application/useCaseSave'
import { setDataAutoInventario, setDataStockPersonal, setDataStockPersonalEquipos } from '../../shared/infrastructure/slice'
import { UseCaseSelectStockPersonalEquipos } from '../application/useCaseSelectStockPersonalEquipos'

export const Controller = () => {

  const dispatch = useDispatch()
  const navigate: NavigateFunction = useNavigate()
  const state = useSelector((state: RootState) => state)
  const { ROUTE_LOGISTICA, ENTITY, SCHEMA } = AdapterConfigure
  const repository = new Repository(
    state.generico.websocket,
    state.generico.dbLocal,
    dispatch,
    SCHEMA,
    ENTITY,
    state.auth.user
  )

  const init = async () => {
    try {
      dispatch(hideIconMenu())
      if (!state.autoInventario.dataStockPersonal.length) {
        dispatch(addLoading({ textLoading: 'Cargando datos...' }))
        const data = await new UseCaseSelectStockPersonalLocal(repository).exec(state)
        dispatch(setDataStockPersonal(data))
        const dataStockPersonalEquipos = await new UseCaseSelectStockPersonalEquipos(repository).exec(data)
        dispatch(setDataStockPersonalEquipos(dataStockPersonalEquipos))
        const response = await new UseCaseSelect(repository, state).exec(data)
        if (!response.length) {
          AdapterGenerico.createMessage('Información', 'No ha sido designado para reportar auto inventario o no tiene stock personal', 'info')
        }
        dispatch(setDataAutoInventario(response))
      }
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error').then(() => navigate(ROUTE_LOGISTICA))
    } finally {
      dispatch(removeLoading())
    }
  }

  const handleSave = async () => {
    try {
      await AdapterGenerico.createMessage('Alerta', '¿Está seguro de los datos reportados?', 'question', true).then(async (res) => {
        if (res) {
          dispatch(addLoading({ textLoading: 'Cargando datos...' }))
          await new UseCaseSave(repository, state).exec()
          dispatch(changeAutoInventario(false))
          dispatch(removeLoading())
          await AdapterGenerico.createMessage('¡Éxito!', 'Auto inventario reportado correctamente.', 'success').then(() => {
            navigate(ROUTE_LOGISTICA)
          })
        }
      })
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error').then(() => navigate(ROUTE_LOGISTICA))
    } finally {
      dispatch(removeLoading())
    }
  }

  return {
    init,
    handleSave
  }
}