import { RepositoryMain } from '../Domain/RepositoryMain'
import { RepositoryImplGenerico } from '../../../../shared/Infraestructure/RepositoryImplGenerico'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { DtoDocumentacionBaremoPEX } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { AdapterConfigure } from './AdapterConfigure'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { FTPService } from '../../../../shared/Infraestructure/AdapterFileSystem'
import { DtoReqUpdateAddressList } from '../Domain/DtoReqUpdateAddressList'
import { DtoRequestUpdateTrabajo } from '../../Preliquidar/Domain/DtoRequestUpdateTrabajo'

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

  async selectTrabajoLocal(user: EntityDataUsuario, ID_Trabajo: number): Promise<DtoTrabajosDE> {
    return await this.dbLocal.selectByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, value: ID_Trabajo })
  }

  async selectDocumentacionBaremoPEX(): Promise<DtoDocumentacionBaremoPEX[]> {
    return await this.dbLocal.selectAllStore('DocumentacionBaremoPEX')
  }

  async selectEstadoInterno(): Promise<any[]> {
    return await this.dbLocal.selectAllStore('EstadoInterno')
  }

  async updateAddressList(params: DtoReqUpdateAddressList, files: File[], usuario: EntityDataUsuario, trabajo: DtoTrabajosDE): Promise<void> {

    const url: string = `${this.urlBase}${AdapterConfigure.UPDATE_ADDRESSLIST}`

    let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {})
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response

    const trabajoIndexedLocal = await this.selectTrabajoLocal(usuario, params.Body.Trabajo.ID_Trabajo)

    if (!trabajoIndexedLocal) throw Error(`No se pudo actualizar data local`)
    const trabajoUpdated: DtoTrabajosDE = {
      ColeccionObras: trabajoIndexedLocal.ColeccionObras,
      ColeccionReserva: trabajoIndexedLocal.ColeccionReserva,
      ColeccionManoObra: trabajoIndexedLocal.ColeccionManoObra,
      ColeccionAddressList: trabajoIndexedLocal.ColeccionAddressList,
      ColeccionHPListMO: trabajoIndexedLocal.ColeccionHPListMO,
      ...response.filter(e => e.ID_Trabajo && e.Ultima_PreLiquidacion && e.PartesDiarios)[0]
    }

    const Address = response.filter(e => e.ID_AddressList && e.KeyDP && e.ResidentialHPList)
    if (Address.length !== 1) throw Error(`No se pudo actualizar data local`)
    const indexToUpdate = trabajoUpdated.ColeccionAddressList.findIndex(al => al.ID_AddressList === Address[0].ID_AddressList)
    if (indexToUpdate === -1) throw Error(`No se pudo actualizar data local`)
    trabajoUpdated.ColeccionAddressList.splice(indexToUpdate, 1, Address[0])
    await this.dbLocal.updateByIndexStore({ nameStore: `${usuario.Pais.Nombre}_${usuario.Delegacion.Codigo}_Trabajos`, value: trabajoUpdated })

  }

  async uploadFiles(files: File[]) {
    console.info(`¡Se subirán ${files.length} archivos!`)
    if (navigator.onLine) {
      for await (const file of files) {
        await FTPService.setFileToServer(file.name, file)
      }
    }
  }

  async updateTrabajos(params: DtoRequestUpdateTrabajo, user: EntityDataUsuario): Promise<void> {

    const url: string = `${this.urlBase}${AdapterConfigure.UPDATE_TRABAJOS}`
    const trabajoIndexedLocal = await this.selectTrabajoLocal(user, params.Body.ID_Trabajo)

    let response: Array<any> = await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {})
    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response

    response = response.reduce((acc, el) => {
      if (Array.isArray(el)) return [...acc, ...el]
      else return [...acc, el]
    }, [])
    const coleccion = `${user.Pais.Nombre}_${user.Delegacion.Codigo}`

    if (trabajoIndexedLocal) {
      const trabajoUpdated: DtoTrabajosDE = {
        ColeccionObras: trabajoIndexedLocal.ColeccionObras,
        ColeccionReserva: trabajoIndexedLocal.ColeccionReserva,
        ColeccionManoObra: trabajoIndexedLocal.ColeccionManoObra,
        ...response.filter(e => e.ID_Trabajo && e.Ultima_PreLiquidacion && e.PartesDiarios)[0]
      }
      await this.dbLocal.updateByIndexStore({ nameStore: `${coleccion}_Trabajos`, value: trabajoUpdated })
      await this.updateStockPersonal(response, coleccion)
    }
    await this.updateStockAlmacen(response, coleccion)
    await this.updateConsumoMaterial(response, coleccion)
  }

  private async updateStockPersonal(response: any[], coleccion: string): Promise<any> {
    const data = response.filter(e => e.ID_StockPersonal && e.Reserva)
    for await (const item of data) {
      await this.dbLocal.updateByIndexStore({ nameStore: `${coleccion}_StockPersonal`, value: item })
    }
  }

  private async updateStockAlmacen(response: any[], coleccion: string) {
    const data = response.filter(e => e.ID_StockAlmacen)
    for await (const item of data) {
      await this.dbLocal.updateByIndexStore({ nameStore: `${coleccion}_StockAlmacen`, value: item })
    }
  }

  private async updateConsumoMaterial(response: any[], coleccion: string) {
    const data = response.filter(e => e.ID_ConsumoMaterial)
    for await (const item of data) {
      await this.dbLocal.updateByIndexStore({ nameStore: `${coleccion}_ConsumoMaterial`, value: item })
    }
  }

}