const {
  REACT_APP_MAESTRO_SEDE_SCHEMA,
  REACT_APP_MAESTRO_SEDE_ENTITY,
  REACT_APP_ROUTE_PATH_MAIN,
  REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS,
  REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_AL04_TRABAJOS,
} = process.env

const SCHEMA: string = REACT_APP_MAESTRO_SEDE_SCHEMA || ''
const ENTITY: string = REACT_APP_MAESTRO_SEDE_ENTITY || ''
const ROUTE_PROGRAMADOS: string = `/${REACT_APP_ROUTE_PATH_MAIN}/${REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS}`
const ROUTE_TRABAJOS: string = `${ROUTE_PROGRAMADOS}/${REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_AL04_TRABAJOS}`
const SELECT_TRABAJOS: string = '/SigoPwa/Programados/Gestion/Trabajos/Select'

export const AdapterConfigure = {
  SCHEMA,
  ENTITY,
  SELECT_TRABAJOS,
  ROUTE_PROGRAMADOS,
  ROUTE_TRABAJOS,
}