import { FC, useEffect, useState } from 'react'
import { iAutoInventario } from '../../shared/domain/iAutoInventario'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { Link } from 'react-router-dom'
import { AdapterConfigure } from '../../shared/infrastructure/AdapterConfigure'

interface Props extends iAutoInventario { }

export const Card: FC<Props> = ({ ID_StockPersonal, Codigo, Cantidad }) => {

  const { dataStockPersonal } = useSelector((state: RootState) => state.autoInventario)
  const [stockPersonal, setStockPersonal] = useState<DtoResponseSelectStockPersonal>()
  const [stock, setStock] = useState<number>(0)

  useEffect(() => {
    const stockPersonal = dataStockPersonal.find(e => e.ID_StockPersonal === ID_StockPersonal)
    if (stockPersonal) {
      setStockPersonal(stockPersonal)

      const Despacho = stockPersonal.Despacho
      const Devolucion = stockPersonal.Devolucion
      const Liquidacion = stockPersonal.Liquidacion
      const DespachoTR = stockPersonal.DespachoTR
      const DevolucionTR = stockPersonal.DevolucionTR

      let stock = Despacho - Devolucion - Liquidacion + DespachoTR - DevolucionTR

      const precision = 1e-10
      if (Math.abs(stock) < precision) {
        stock = 0
      }

      setStock(Number(stock.toFixed(2)))

    }
  }, [ID_StockPersonal, dataStockPersonal])

  if (!stockPersonal) return <></>

  return (
    <Link to={`${AdapterConfigure.ROUTE_AUTO_INVENTARIO}/${Codigo}`}>
      <div className='d-flex flex-row card rounded-3 p-3' style={{ backgroundColor: '#343e56' }}>
        <div className='col-2 d-flex align-items-center'>
          <div
            className='rounded-circle d-flex align-items-center justify-content-center'
            style={{
              width: 50,
              height: 50,
              backgroundColor: '#049bed'
            }}
          >
            <span className='text-white fw-bold fs-6'>
              {stock}
            </span>
          </div>
        </div>
        <div className='col-9'>
          <table className=''>
            <tbody className='text-white'>
              <tr className=''>
                <td>Código</td>
                <td className='ps-1'>{stockPersonal.Item.Codigo}</td>
              </tr>
              <tr>
                <td>Descripción</td>
                <td className='ps-1'>{stockPersonal.Item.Descripcion}</td>
              </tr>
              {
                stockPersonal.Lote
                  ? <tr>
                    <td>Lote</td>
                    <td className='ps-1'>{stockPersonal.Lote}</td>
                  </tr>
                  : null
              }
              {
                stockPersonal.FlagPex
                  ? <tr>
                    <td>OrdenTrabajo</td>
                    <td className='ps-1'>{stockPersonal.OrdenTrabajo}</td>
                  </tr>
                  : null
              }
              {
                stockPersonal.Item.Ind_SB === 'S'
                  ? <tr>
                    <td>Seriado</td>
                    <td className='ps-1' style={{ color: '#ff1a1a' }}>{stockPersonal.Item.Ind_SB}</td>
                  </tr>
                  : null
              }
            </tbody>
          </table>
        </div>
        <div className='col-1 d-flex align-items-center justify-content-center'>
          {
            stock === Cantidad
              ? <i className='fa-solid fa-circle-check text-success fs-4'></i>
              : <i className='fa-solid fa-circle-exclamation text-warning fs-4'></i>
          }
        </div>
      </div>
    </Link>
  )
}
