import { Suspense } from "react";
import { BrowserRouter, Navigate, Route } from "react-router-dom"
import { AuthGuard } from "./AuthGuard"
import { RoleGuard } from "./RoleGuard";
import { ElementLoader } from "../../../context/shared/Components/ElementLoader"
import { ElementLoaderLazy } from "../../../context/shared/Components/ElementLoaderLazy";
import RoutesWithNotFound from '../../../context/shared/Components/RoutesWithNotFound';

//Module Master
import ViewMasterLogin from '../../../context/Master/Login';
import ViewMasterRecuperarPassword from '../../../context/Master/RecuperarPassword';
import ViewMasterMain from '../../../context/Master/Main';
import ViewMasterProfile from '../../../context/Master/Profile';
import ViewMasterVariable from '../../../context/Master/Variable';

//Module Home
import ViewMasterHome from '../../../context/Master/Home';
import ViewHomeStockTotal from '../../../context/Master/HomeTotalStock';

//Module Programados
import ViewProgramadosHome from '../../../context/Programados/Home';
import ViewProgramadosTrabajos from '../../../context/Programados/Trabajos/Trabajos';
import ViewProgramadosTrabajosDetalle from '../../../context/Programados/Trabajos/DetalleTrabajo';
import ViewProgramadosTrabajoAsignacion from '../../../context/Programados/Trabajos/Asignacion';
import ViewProgramadosTrabajoParteDiario from '../../../context/Programados/Trabajos/ParteDiario';
import ViewProgramadosTrabajoPartesDiarios from '../../../context/Programados/Trabajos/PartesDiarios';
import ViewProgramadosReplanteo from '../../../context/Programados/Obras/Replanteo';
//Module Programados DE
import ViewPEXTrabajosDE from '../../../context/Programados/TrabajosDE/Trabajos';
import ViewPEXTrabajosDEAL04 from '../../../context/Programados/TrabajosDEAL04/Lista';
import ViewPEXTrabajosDEDetalle from '../../../context/Programados/TrabajosDE/Detalle';
import ViewPEXTrabajosDEAL04Detalle from '../../../context/Programados/TrabajosDEAL04/Detalle';
import ViewPEXTrabajosDEPreliquidar from '../../../context/Programados/TrabajosDE/Preliquidar'
import ViewPEXTrabajosDEAL04Preliquidar from '../../../context/Programados/TrabajosDEAL04/Preliquidar'
import ViewPEXTrabajosDEDocHP from '../../../context/Programados/TrabajosDE/DocHP'
import ViewPEXTrabajosDEAL04DocHP from '../../../context/Programados/TrabajosDEAL04/DocHP'
import ViewPEXTrabajosDEDetalleMO from '../../../context/Programados/TrabajosDE/DetalleAsignacion'
import ViewPEXTrabajosDEAL04DetalleMO from '../../../context/Programados/TrabajosDEAL04/DetalleAsignacion'

//Module Logistica
import ViewLogisticaHome from '../../../context/Logistica/Home';
import ViewLogisticaTrabajos from '../../../context/Logistica/Trabajos';
import ViewLogisticaStockPersonal from '../../../context/Logistica/StockPersonal';
import ViewLogisticaDetalleStockPersonal from '../../../context/Logistica/DetalleStockPersonal';
import ViewLogisticaFirmaAlbaran from '../../../context/Logistica/FirmaAlbaran';
import ViewLogisticaDespachosAlmacen from '../../../context/Logistica/DespachosAlmacen';
import ViewLogisticaStockPersonalTotal from '../../../context/Logistica/StockPersonalTotal';
import ViewLogisticaDetalleStockPersonalTotal from '../../../context/Logistica/DetalleStockPersonalTotal';
import { ManagerAutoInventarios } from '../../../context/Logistica/autoInventario/list'
import { ManagerAutoInventario } from '../../../context/Logistica/autoInventario/detail'
import { ManagerStockAlmacen } from '../../../context/Logistica/StockAlmacen'

const {
  REACT_APP_ROUTE_PATH_TRABAJOS_ASIGNACION,
  REACT_APP_ROUTE_PATH_TRABAJOS_PARTEDIARIO,
  REACT_APP_ROUTE_PATH_TRABAJOS_PARTESDIARIOS,
  REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_TRABAJOS,
  REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_OBRAS,
  REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_REPLANTEO,
  REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_TRABAJOS,
  REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_AL04_TRABAJOS,
  REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_TRABAJOS_PRELIQUIDAR,
  REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_TRABAJOS_DOCHP,
  REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_TRABAJOS_MO,
  REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_AUTO_INVENTARIO,
  REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_STOCK_ALMACEN
} = process.env;

export const AppRoutes = () => {
  return (
    <Suspense fallback={<ElementLoaderLazy />}>
      <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
        <ElementLoader />
        <RoutesWithNotFound>
          <Route path="" element={<Navigate to={`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`} />} />
          <Route path={`${process.env.REACT_APP_ROUTE_PATH_LOGIN}`} element={<AuthGuard element={<ViewMasterLogin />} privateContent={false} />} />
          <Route path={`${process.env.REACT_APP_ROUTE_PATH_RECOVERPASSWORD}`} element={<AuthGuard element={<ViewMasterRecuperarPassword />} privateContent={false} />} />
          <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN}`} element={<AuthGuard element={<ViewMasterMain />} privateContent={true} />}>
            <Route path={``} element={<ViewMasterHome />} />
            <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_HOME}`} element={<ViewMasterHome />} />
            <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_PROFILE}`} element={<ViewMasterProfile />} />
            <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_VARIABLE}`} element={<ViewMasterVariable />} />
            <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_HOME_ASIGNADOS}/:codigoLlave`} element={<ViewHomeStockTotal />} />
            <Route element={<RoleGuard />}>
              <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS}`}>
                <Route path={``} element={<ViewProgramadosHome />} />
                <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_TRABAJOS}`}>
                  <Route path={``} element={<ViewProgramadosTrabajos />} />
                  <Route path={`${REACT_APP_ROUTE_PATH_TRABAJOS_PARTESDIARIOS}/:id`} element={<ViewProgramadosTrabajoPartesDiarios />} />
                  <Route path={`${REACT_APP_ROUTE_PATH_TRABAJOS_ASIGNACION}/:id/:codParteDiario?`} element={<ViewProgramadosTrabajoAsignacion />} />
                  <Route path={`${REACT_APP_ROUTE_PATH_TRABAJOS_PARTEDIARIO}/:id/:origen?`} element={<ViewProgramadosTrabajoParteDiario />} />
                  <Route path={`:id`} element={<ViewProgramadosTrabajosDetalle />} />
                </Route>
                <Route path={REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_TRABAJOS}>
                  <Route path={``} element={<ViewPEXTrabajosDE />} />
                  <Route path={`:id/:estado?`} element={<ViewPEXTrabajosDEDetalle />} />
                  <Route path={`${REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_TRABAJOS_PRELIQUIDAR}/:id`} element={<ViewPEXTrabajosDEPreliquidar />} />
                  <Route path={`${REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_TRABAJOS_DOCHP}/:id`} element={<ViewPEXTrabajosDEDocHP />} />
                  <Route path={`${REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_TRABAJOS_MO}/:id`} element={<ViewPEXTrabajosDEDetalleMO />} />
                </Route>
                <Route path={REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_AL04_TRABAJOS}>
                  <Route path={``} element={<ViewPEXTrabajosDEAL04 />} />
                  <Route path={`:id/:estado?`} element={<ViewPEXTrabajosDEAL04Detalle />} />
                  <Route path={`${REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_TRABAJOS_PRELIQUIDAR}/:id`} element={<ViewPEXTrabajosDEAL04Preliquidar />} />
                  <Route path={`${REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_TRABAJOS_DOCHP}/:id`} element={<ViewPEXTrabajosDEAL04DocHP />} />
                  <Route path={`${REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_DE_TRABAJOS_MO}/:id`} element={<ViewPEXTrabajosDEAL04DetalleMO />} />
                </Route>
                <Route path={`${REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_OBRAS}`}>
                  <Route path={`${REACT_APP_ROUTE_PATH_MAIN_PROGRAMADOS_REPLANTEO}`} element={<ViewProgramadosReplanteo />} />
                </Route>
              </Route>
              <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA}`}>
                <Route path={``} element={<ViewLogisticaHome />} />
                <Route path={REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_TRABAJOS} >
                  <Route path={``} element={<ViewLogisticaTrabajos />} />
                  <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_STOCKPERSONAL}/:trabajo`} element={<ViewLogisticaStockPersonal />} />
                  <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_STOCKPERSONAL}/:trabajo/:id`} element={<ViewLogisticaDetalleStockPersonal />} />
                </Route>
                <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_FIRMA_ALBARAN}`} element={<ViewLogisticaFirmaAlbaran />} />
                <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_FIRMA_ALBARAN}/:id`} element={<ViewLogisticaDespachosAlmacen />} />
                <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_STOCK_PERSONAL_TOTAL}`} element={<ViewLogisticaStockPersonalTotal />} />
                <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_STOCK_PERSONAL_TOTAL}/:codigoLlave`} element={<ViewLogisticaDetalleStockPersonalTotal />} />
                <Route path={REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_AUTO_INVENTARIO}>
                  <Route index element={<ManagerAutoInventarios />} />
                  <Route path=':codigo' element={<ManagerAutoInventario />} />
                </Route>
                <Route path={REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_STOCK_ALMACEN}>
                  <Route index element={<ManagerStockAlmacen />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </RoutesWithNotFound>
      </BrowserRouter>
    </Suspense >
  )
}