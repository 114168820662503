import './FormAdd.scss'
import { Formik, FormikErrors, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap'
import { InputSelect } from '../../../../shared/Components/ElementInputsCostume'
import { InputNumber } from '../../../../shared/Components/ReactBootstrap/InputNumber/InputNumber'
import { DtoDataSelectAsignaciones } from '../Domain/DtoDataSelectAsignaciones'
import { DtoInitialValues } from '../../Shared/Preliquidar/Interfaces/DtoInitialValues'
import { DtoDataAddedValorizaciones } from '../../Shared/Preliquidar/Interfaces/DtoDataAddedValorizaciones'
import { Adjuntos } from '../../../../shared/Components/Adjuntos/Adjuntos'
import { DtoDocumentacionBaremoPEX, DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import {
	CodigosMaterialDuctosAL04,
	mapaMOMaterialAL04,
	paisMap
} from 'sigo-package'
import { DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { AddedMaterial } from './AddedMaterial'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { DtoMaterialesUtiRet } from '../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { DtoValueOnSelect } from '../Domain/DtoValueOnSelect'
import { ShowAvailable } from './ShowAvailable'
import { typeHPList } from '../../DocHP/Domain/typeHPList'
import { DtoHomePass } from '../../../Trabajos/Asignacion/Domain/DtoHomePassSimple'
import { DtoDataMateriales } from '../Domain/DtoDataMateriales'
import { Valorizaciones } from './Valorizaciones'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
import { AddedHPMO } from './AddedHPMO'

interface props {
	trabajo: DtoTrabajosDE | null
	asignaciones: DtoDataSelectAsignaciones[]
	valorizaciones: DtoDataAddedValorizaciones[]
	onSubmit: (
		values: DtoInitialValues,
		formikHelpers: FormikHelpers<DtoInitialValues> | null,
		materialesInstalado: DtoMaterialesUtiRet[],
		materialesRetirado: DtoMaterialesUtiRet[]
	) => void | Promise<any>
	setDocumentacionValorizacion: Dispatch<SetStateAction<DtoNodoDoc[]>>
	documentacionValorizacion: DtoNodoDoc[]
	setNewFilesUpload: Dispatch<SetStateAction<File[]>>
	newFilesUpload: File[]
	dataDocumentacionBaremoPEX: DtoDocumentacionBaremoPEX[]
	onClick: () => void
	onChange: (name: string, value: any, materialesInstalado: DtoMaterialesUtiRet[]) => { dataAlmacen: DtoDataMateriales[], dataMateriales: DtoDataMateriales[] } | undefined
	dataMateriales: DtoDataMateriales[]
	address?: {
		ID_AddressList: number
		Home_ID: string
		typeHPList: typeHPList
	}
	newFiles?: File[]
	dataMaterialesAlmacen: DtoDataMateriales[]
	setShowOffCanvas?: Dispatch<SetStateAction<boolean>>
	setValRechazadaXMetraje: Dispatch<SetStateAction<DtoValorizacion | null>>
	valRechazadaXMetraje: DtoValorizacion | null
}

export function FormAdd(props: props) {

	const {
		trabajo,
		onClick,
		onChange,
		dataMateriales,
		address,
		asignaciones,
		dataDocumentacionBaremoPEX,
		documentacionValorizacion,
		newFiles,
		newFilesUpload,
		onSubmit,
		setDocumentacionValorizacion,
		setNewFilesUpload,
		valorizaciones,
		dataMaterialesAlmacen,
		setShowOffCanvas,
		setValRechazadaXMetraje,
		valRechazadaXMetraje
	} = props

	const [materialesInstalado, setMaterialesInstalado] = useState<DtoMaterialesUtiRet[]>([])
	const [materialesRetirado, setMaterialesRetirado] = useState<DtoMaterialesUtiRet[]>([])
	const [existRechazadaPorMetraje, setExistRechazadaPorMetraje] = useState<boolean>(false)
	const [sinMaterial, setSinMaterial] = useState<boolean>(false)
	const [materialFijo, setMaterialFijo] = useState<boolean>(false)
	const langTranslate = LanguageTranslate()
	const initialValues: DtoInitialValues = {
		asignacion: new DtoValueOnSelect(),
		tipoBaremoSubcontrata: new DtoValueOnSelect(),
		// materialSoplado: new DtoValueOnSelect(),
		homePassMO: new DtoValueOnSelect(),
		cantidad: 0,
		calle: '',
		direcciones: '',
		observacion: '',
	}
	const schema = yup.object().shape({
		asignacion: yup
			.object()
			.shape({ value: yup.string().min(1, 'Seleccione una opción') }),
		homePassMO: yup
			.object()
			.shape({
				value: yup.string().test({
					test(value, ctx) {
						if (
							address &&
							!value
						) {
							return ctx.createError({ message: `Seleccione una opción` })
						}
						return true
					}
				})
			}),
		cantidad: yup
			.number()
			.min(0.1, `Ingrese una cantidad mayor a 0`)
			.required('Campo es requerido')
			.test({
				test(value, ctx) {
					try {
						if (value === undefined) return true
						const codMO = this.parent.asignacion.dataComplete.ManoObra.Codigo
						verificarReglas(codMO, value)
						return true
					} catch (error) {
						return ctx.createError({ message: (error as Error).message })
					}
				}
			}),
		calle: yup
			.string()
			.test({
				test(value, ctx) {
					if (!address && !value) {
						return ctx.createError({ message: 'Campo es requerido' })
					}
					return true
				}
			}),
		direcciones: yup
			.string()
			.test({
				test(value, ctx) {
					if (!address && !value) {
						return ctx.createError({ message: 'Campo es requerido' })
					}
					return true
				}
			}),
	})

	useEffect(() => {
		if (!trabajo) return
		setExistRechazadaPorMetraje(
			trabajo.Ultima_PreLiquidacion.Valorizacion
				.some(e => ['RECHAZADA POR METRAJE']
					.includes(e.Ultimo_Estado_Interno.Descripcion) &&
					e.HomePass.length && e.HomePass[0].ClientType === 'Troncal'
				)
			&& !address
		)
	}, [trabajo])

	const selectAddress = (codigoMO: string, ID_AddressList: number, typeHPList: typeHPList, Home_ID: string) => {
		if (trabajo) {

			// Buscar Address List
			const addressList = trabajo.ColeccionAddressList.filter(e => e.ID_AddressList === ID_AddressList)
			if (addressList.length !== 1) throw Error(`¡No se pudo Obtener AddressList! Comunícate con Soporte`)

			// Buscar Address
			const direccion = (
				typeHPList === 'Business'
					? addressList[0].BusinessHPList
					: addressList[0].ResidentialHPList
			).filter(e => e.Home_ID === Home_ID)
			if (direccion.length !== 1) throw Error(`¡No se pudo obtener Address! Comunícate con Soporte`)

			// BuscarHPListMO
			let hpListMO: any = []
			const HPMOAux: any = ''

			return { HPMOAux, hpListMO, addressList, direccion: direccion[0] }
		}
	}

	const selectAsignacion = async (
		setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<DtoInitialValues>>,
		value: DtoValueOnSelect<DtoDataSelectAsignaciones>
	) => {
		if (!address) return //Si es troncal hago un return
		const codMO = value.dataComplete.ManoObra.Codigo.trim()

		//#endregion

		//#region 
		const result = selectAddress(codMO, address.ID_AddressList, address.typeHPList, address.Home_ID)
		if (result) {
			const { HPMOAux, addressList, hpListMO, direccion } = result
			const homePassMO: DtoValueOnSelect<DtoHomePass> = {
				label: `${direccion.Street} ${direccion.HouseNumber} ${direccion.HouseNumberAffix} (${address.typeHPList})`,
				value: `${addressList[0].ID_AddressList}${direccion.Home_ID}${address.typeHPList}`,
				dataComplete: {
					ID_HPListMO: hpListMO.length ? hpListMO[0].ID_HPListMO : 0,
					Type: hpListMO.length ? hpListMO[0].Type : '',
					Cantidad: 0,
					ID_AddressList: addressList[0].ID_AddressList,
					AddressID: addressList[0].AddressID,
					Home_ID: direccion.Home_ID,
					ClientType: address.typeHPList,
					StreetName: direccion.Street,
					HouseNumber: direccion.HouseNumber.toString(),
					HouseNumbreComplement: direccion.HouseNumberAffix,
				}
			}
			await setFieldValue('homePassMO', homePassMO)
			return {
				Cantidad: hpListMO.length ? hpListMO[0].Cantidad : 0,
				Utilizado: HPMOAux ? HPMOAux.CantidadUtilizado : 0
			}
		}
		//#endregion

	}

	const onSelectAsignacion = (a: DtoDataSelectAsignaciones) => {
		try {
			const documentacionBaremo = dataDocumentacionBaremoPEX.find(e => e.CodigoMO === a.ManoObra.Codigo)
			if (!!!documentacionBaremo) console.warn(`No existe DocumentacionBaremoPEX para el codigo ManoObra ${a.ManoObra.Codigo}`)
			const defaultBaremo = new DtoDocumentacionBaremoPEX()
			const newNodo = new DtoNodoDoc()
			newNodo.id = 999999
			newNodo.label = 'OTROS ANEXOS'
			newNodo.Titulo = 'OTROS ANEXOS'
			newNodo.Codigo = 'Cod999999'
			newNodo.Descripcion = 'OTROS ANEXOS'
			newNodo.Extension = ['ALL']
			newNodo.Size.Size = 50
			newNodo.Size.UM = 'MB'
			newNodo.CantidadMin = trabajo && paisMap[trabajo.Pais.Codigo] ? paisMap[trabajo.Pais.Codigo].CantidadMin : 1
			defaultBaremo.Nodos = [newNodo]
			setDocumentacionValorizacion(documentacionBaremo ? documentacionBaremo.Nodos : defaultBaremo.Nodos)
		} catch (error) {
			console.error(error)
			AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
		}
	}

	const verificarReglas = (CodigoMO: string, Cantidad: number) => {
		const matAux: DtoMaterialesUtiRet[] = []
		const existMO = mapaMOMaterialAL04.get(CodigoMO)
		setSinMaterial(false)
		setMaterialFijo(false)
		if (!existMO) return
		if (Array.isArray(existMO.Materiales)) {
			if (existMO.Materiales.length) {
				setSinMaterial(false)
				setMaterialFijo(true)
			} else {
				setSinMaterial(true)
			}
			setMaterialesRetirado([])
			setMaterialesInstalado([])
			for (const material of existMO.Materiales) {
				const dataMateriales = onChange('tipoMaterial', { value: 'Instalado' }, materialesInstalado)
				if (!dataMateriales) throw new Error(`¡No se pudo seleccionar item!`)
				const consumirStockPersonal = CodigosMaterialDuctosAL04.includes(material.Codigo)
				const dataMaterial = consumirStockPersonal ? dataMateriales.dataMateriales : dataMateriales.dataAlmacen
				const stocks = dataMaterial.filter(e => e.Codigo === material.Codigo)
				if (!stocks.length) {
					throw new Error(`¡No tiene en stock ${consumirStockPersonal ? 'personal' : 'almacén'} ${material.Codigo}!`)
				}
				const cantidad = (material.CantidadFija ? material.Cantidad : Cantidad) * material.Factor
				for (const [i, stock] of stocks.entries()) {
					if (stock.CantidadDisponible < cantidad && i === stocks.length - 1) {
						throw new Error(`¡No tiene stock ${consumirStockPersonal ? 'personal' : 'almacén'} disponible material ${material.Codigo}!`)
					} else if (stock.CantidadDisponible < cantidad) {
						continue
					} else {
						stock.Motivo.Codigo = material.Codigo
						stock.Motivo.Descripcion = stock.CodigoLlave
						stock.Cantidad = cantidad
						stock.CantidadInformada = cantidad
						matAux.push(stock)
						break
					}
				}
			}
			setMaterialesInstalado(matAux)
		} else if (existMO.Materiales instanceof Map) { }
	}

	return (
		<Formik
			validationSchema={schema}
			initialValues={initialValues}
			onSubmit={async (values, formikHelpers) => {
				if (!address) {
					const newHP = new DtoHomePass()
					newHP.ClientType = 'Troncal'
					newHP.StreetName = values.calle
					newHP.HouseNumber = values.direcciones
					newHP.HouseNumbreComplement = values.observacion
					values.homePassMO.dataComplete = newHP
				}
				await onSubmit(values, formikHelpers, materialesInstalado, materialesRetirado)
				setMaterialesInstalado([])
				setMaterialesRetirado([])
				if (valRechazadaXMetraje && !address && setShowOffCanvas) {
					setShowOffCanvas(false)
				}
			}}
		>
			{
				({ handleSubmit, handleChange, values, touched, errors, setFieldValue, resetForm }) => (
					<Form
						className='h-100 d-flex flex-column justify-content-between'
						noValidate
						onSubmit={async (e) => {
							e.preventDefault()
							if (newFiles && newFiles.length && !values.asignacion.value && address) {
								await onSubmit(values, null, materialesInstalado, materialesRetirado)
							}
							handleSubmit()
						}}
					>
						<div>
							<Row className='mb-3'>
								<Col>
									<button onClick={onClick} type='button' className='btn btn-warning'><i className='fa-regular fa-square-plus'></i></button>
								</Col>
							</Row>
							{
								(existRechazadaPorMetraje && !valorizaciones.length) &&
								<Row>
									<Col>
										<Accordion className='mb-2' defaultActiveKey='0'>
											<Accordion.Item eventKey='0'>
												<Accordion.Header>{langTranslate.general.Rechazadas_por_metraje}</Accordion.Header>
												<Accordion.Body>
													<Valorizaciones
														trabajo={trabajo}
														valRechazadaXMetraje={valRechazadaXMetraje}
														setValRechazadaXMetraje={setValRechazadaXMetraje}
														resetForm={resetForm}
													/>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</Col>
								</Row>
							}
							<Row>
								<InputSelect
									label={langTranslate.general.Asignacion}
									name='asignacion'
									onChange={async (name, value: DtoValueOnSelect<DtoDataSelectAsignaciones>) => {
										try {
											const codigoMO = value.dataComplete.ManoObra.Codigo
											const existMO = mapaMOMaterialAL04.get(codigoMO)
											await setFieldValue('cantidad', existMO ? existMO.CantidadFija ? existMO.Cantidad : 0 : 0)
											setMaterialesRetirado([])
											setMaterialesInstalado([])
											await selectAsignacion(setFieldValue, value)
											onSelectAsignacion(value.dataComplete)
											handleChange({ target: { value, name } })
										} catch (error) {
											console.error(error)
											AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
										}
									}}
									values={values}
									isRequired
									options={asignaciones
										.filter(e => {
											if (valRechazadaXMetraje) {
												return e.ManoObra.Codigo.trim() === valRechazadaXMetraje.DetalleChile.ManoObra.Codigo.trim()
											}
											return address ? true : !new Set(valorizaciones.map(e => e.LineaCodigoCub)).has(e.LineaCodigoCub)
										}
										).map(val => ({
											label: `${val.ManoObra.Codigo} - ${val.ManoObra.Nombre} => (${val.Linea})`,
											value: val.LineaCodigoCub,
											dataComplete: val
										}))
									}
									loading={false}
									disabled={false}
									disabledVirtualized
									errors={newFiles && newFiles.length && address ? undefined : errors}
									touched={touched}
								/>
							</Row>
							{
								values.asignacion.value
								&& <>
									<Row>
										<Col>
											{
												address
													? <AddedHPMO
														handleChange={(name, value: DtoValueOnSelect<DtoHomePass>) => {
															const result = selectAddress(
																values.asignacion.dataComplete.ManoObra.Codigo,
																value.dataComplete.ID_AddressList,
																value.dataComplete.ClientType,
																value.dataComplete.Home_ID)
															if (result) {
																const { HPMOAux, addressList, hpListMO, direccion } = result
																const Cantidad = hpListMO.length ? hpListMO[0].Cantidad : 0
																const Utilizado = HPMOAux ? HPMOAux.CantidadUtilizado : 0
																values.asignacion.dataComplete.CantidadMO = Cantidad
																values.asignacion.dataComplete.CantidadMODisponible = Cantidad - Utilizado
															}
															handleChange({ target: { name, value } })
														}}
														values={values}
														errors={errors}
														touched={touched}
														trabajo={trabajo}
														valorizaciones={valorizaciones}
														address={address}
													/> :
													<>
														<InputNumber
															label={langTranslate.general.Calle}
															name='calle'
															errors={errors}
															touched={touched}
															values={values}
															handleChange={handleChange}
															required
															type='text'
														/>
														<InputNumber
															label={langTranslate.general.Direcciones}
															name='direcciones'
															errors={errors}
															touched={touched}
															values={values}
															handleChange={handleChange}
															required
															type='text'
														/>
														<InputNumber
															label={langTranslate.general.Observacion}
															name='observacion'
															errors={errors}
															touched={touched}
															values={values}
															handleChange={handleChange}
															type='text'
														/>
													</>
											}
											<ShowAvailable cantidadDisponible={values.asignacion.dataComplete.CantidadMODisponible} />
										</Col>
									</Row>
									<Row>
										<InputNumber
											label={langTranslate.general.Cantidad}
											name='cantidad'
											errors={errors}
											touched={touched}
											values={values}
											handleChange={handleChange}
											required
											disabled={(() => {
												const codigoMO = values.asignacion.dataComplete.ManoObra.Codigo
												const existMO = mapaMOMaterialAL04.get(codigoMO)
												if (!existMO) return false
												return existMO.CantidadFija
											})()}
											type='number'
										/>
									</Row>
									<Row className='mb-2'>
										<Adjuntos
											Nodos={documentacionValorizacion}
											newFiles={newFilesUpload}
											setNewFiles={setNewFilesUpload}
											setNodos={setDocumentacionValorizacion}
											ruta='Fotos_Anexos'
										/>
									</Row>
									{
										sinMaterial
											? null
											: <Accordion className='mb-2'>
												<Accordion.Item eventKey='0'>
													<Accordion.Header>{langTranslate.general.Material}</Accordion.Header>
													<Accordion.Body>
														<AddedMaterial
															dataMateriales={dataMateriales}
															onChange={onChange}
															materialesInstalado={materialesInstalado}
															materialesRetirado={materialesRetirado}
															setMaterialesInstalado={setMaterialesInstalado}
															setMaterialesRetirado={setMaterialesRetirado}
															valuesAux={values}
															materialFijo={materialFijo}
															dataMaterialesAlmacen={dataMaterialesAlmacen}
														/>
													</Accordion.Body>
												</Accordion.Item>
											</Accordion>
									}
								</>
							}
						</div>
						<Row>
							<Col>
								<Button className='w-100' type='submit'>{langTranslate.btnGuardar}</Button>
							</Col>
						</Row>
					</Form>
				)
			}
		</Formik>
	)
}