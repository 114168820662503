import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoResponseEstadoInterno } from '../../../../../app/Domain/DtoResponseEstadoInterno'
import { EntityEstadoInterno } from '../../../../shared/Domain/Entity/EntityEstadoInterno'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
import { DtoFilesDoc } from '../../../Trabajos/Trabajos/Domain/DtoFilesDoc'
import { typeHPList } from '../../DocHP/Domain/typeHPList'
import { DtoTrabajosDE } from '../../Trabajos/Domain/DtoTrabajosDE'
import { DtoReqUpdateAddressList } from '../Domain/DtoReqUpdateAddressList'
import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseUpdateAddressList {
  private repository: RepositoryMain
  private user: EntityDataUsuario
  private userGestor: EntityDataUsuario
  private estadosInternos: DtoResponseEstadoInterno[]

  constructor(_repository: RepositoryMain, _user: EntityDataUsuario, estadosInternos: DtoResponseEstadoInterno[], gestor: EntityDataUsuario) {
    this.repository = _repository
    this.user = _user
    this.estadosInternos = estadosInternos
    this.userGestor = gestor
  }

  public async exec(
    trabajo: DtoTrabajosDE,
    nodos: DtoNodoDoc[],
    typeHPList: typeHPList,
    Home_ID: string,
    ID_AddressList: number,
    newFiles: File[],
    valorizacionUpdate: DtoValorizacion
  ) {
    return await this._exec(trabajo, nodos, typeHPList, Home_ID, ID_AddressList, newFiles, valorizacionUpdate)
  }

  private async _exec(
    trabajo: DtoTrabajosDE,
    nodos: DtoNodoDoc[],
    typeHPList: typeHPList,
    Home_ID: string,
    ID_AddressList: number,
    newFiles: File[],
    valorizacionUpdate: DtoValorizacion
  ) {

    // Buscar AddressList a actualizar
    const updatedAddressList = trabajo.ColeccionAddressList.filter(e => e.ID_AddressList === ID_AddressList)
    if (updatedAddressList.length !== 1) throw Error(`¡No se pudo Obtener AddressList! Comunícate con Soporte`)

    // Determinar tIpo de listas de casa y obtener la casa por su id
    const HPListUpdate = typeHPList === 'Business'
      ? updatedAddressList[0].BusinessHPList.find(e => e.Home_ID === Home_ID)
      : updatedAddressList[0].ResidentialHPList.find(e => e.Home_ID === Home_ID)
    if (!HPListUpdate) throw Error(`¡No se pudo actualizar Address List! Comunícate con Soporte`)

    // Determinar la Asignacion
    const asignacion = HPListUpdate.Asignaciones.find(e => e.Empresa === trabajo.Ultima_asignacion.Empresa.Codigo)
    if (!asignacion) throw Error(`¡No se pudo obtener asignación!`)

    // Obtener los anexos de manera recursiva
    const newAnexos = this.getAnexosHPList(nodos)
    this.eliminarFile(newAnexos)

    // Actualizar los anexos de la casa obtenida 
    asignacion.Anexos = newAnexos

    //Actualizar estado valorizacion
    const estado = this.obtenerEstadoInterno()
    trabajo.Ultima_PreLiquidacion.Valorizacion.forEach(e => {
      if (e.ID_Valorizacion === valorizacionUpdate.ID_Valorizacion) {
        e.Ultimo_Estado_Interno = estado
        e.Estados.push(estado)
      }
    })

    // Construir Body
    const body: DtoReqUpdateAddressList = {
      Header: { Coleccion: { Codigo: this.user.Delegacion.Codigo, Nombre: this.user.Pais.Nombre } },
      Body: { AddressList: updatedAddressList[0], Trabajo: trabajo }
    }

    await this.repository.uploadFiles(newFiles)
    await this.repository.updateAddressList(body, newFiles, this.user, trabajo)

  }

  private getAnexosHPList(nodos: DtoNodoDoc[]): DtoFilesDoc[] {
    return [
      ...nodos.flatMap(e => e.Files.filter(e => e.Estado.ID_Estado === 1).map(f => f)),
      ...nodos.flatMap(e => this.getAnexosHPList(e.Children))
    ]
  }

  private eliminarFile(anexos: DtoFilesDoc[]) {
    for (const anexo of anexos) {
      anexo.Ultimo_Flujo.Usuario.Identificacion = this.userGestor.Identificacion
      anexo.Ultimo_Flujo.Usuario.Apellidos = `${this.userGestor.ApellidoPaterno} ${this.userGestor.ApellidoMaterno}`
      anexo.Ultimo_Flujo.Usuario.Nombres = this.userGestor.Nombres
      delete anexo.File
    }
  }

  private obtenerEstadoInterno() {
    const estado = this.estadosInternos.filter(e => e.Descripcion.trim().toUpperCase() === 'PRELIQUIDADA')
    if (estado.length !== 1) throw Error(`¡No se pudo obtener Estado Interno! Comúnicate con Soporte`)
    const newEstadoInterno = new EntityEstadoInterno()
    newEstadoInterno.ID_EstadoInterno = estado[0].ID_EstadoInterno
    newEstadoInterno.Descripcion = estado[0].Descripcion
    newEstadoInterno.Icono = estado[0].Icono
    newEstadoInterno.Color = estado[0].Color
    newEstadoInterno.Orden = estado[0].Orden
    newEstadoInterno.ColorFondo = estado[0].ColorFondo
    newEstadoInterno.Tipo = estado[0].Tipo
    newEstadoInterno.Observacion = estado[0].Observacion
    newEstadoInterno.Sistema = estado[0].Sistema
    newEstadoInterno.Anexos = estado[0].Anexos
    newEstadoInterno.Pais = estado[0].Pais
    newEstadoInterno.UsuarioEdicion.Identificacion = this.userGestor.Identificacion
    newEstadoInterno.UsuarioEdicion.User = this.userGestor.User
    newEstadoInterno.UsuarioEdicion.Cuadrilla = 'GESTOR'
    return newEstadoInterno
  }

}