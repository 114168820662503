//Dependency External
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

//Adapters
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { addLoading, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterConfigure } from './AdapterConfigure';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';

//Repository
import { RepositoryImplMain } from './RepositoryImplMain';

//UseCase
import { UseCaseLogin } from '../Application/UseCaseLogin';

//Entities
import { DtoRequestLogin } from '../Domain/DtoRequestLogin';
import { DtoResponseLogin } from '../Domain/DtoResponseLogin';


import { changePreference, signIn, signInInvite } from '../../../shared/Infraestructure/SliceAuthentication';
import { useSelector } from 'react-redux';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { AdapterEncrypt } from '../../../shared/Infraestructure/AdapterEncrypt';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { UseCaseCargaMaestro } from '../Application/UseCaseCargaMaestro';
import { EntityInformationDataInitial } from '../../../shared/Domain/EntityInformationDataInitial';
import { AdapterGeolocation } from '../../../shared/Infraestructure/AdapterGeolocation';
import { DtoCoordenadas } from '../../../shared/Domain/Dto/DtoCoordenadas';
import { UseCaseLoadData } from '../Application/UseCaseLoadData';
import { DtoResponseCuadrillas } from '../../../../app/Domain/DtoResponseCuadrillas';
import { UseCaseV1InfoUser } from '../Application/UseCaseV1InfoUser';
import { DtoRequestV1InfoUser } from '../Domain/DtoRequestV1InfoUser';

export const Controller = () => {
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();

    let { recordar } = AdapterStorage.get('recordar');

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const [showPassword, setShowPassword] = useState(false);
    const [coordenadas, setCoordenadas] = useState<DtoCoordenadas>({ X: '', Y: '' })
    const [urlGoogleMaps, setUrlGoogleMaps] = useState<string>('')
    const [dataCuadrillas, setDataCuadrillas] = useState<DtoResponseCuadrillas[]>([])
    const [showDialogo, setShowDialogo] = useState<boolean>(false)
    const [userGestor, setUserGestor] = useState<EntityDataUsuario>({} as EntityDataUsuario)

    useEffect(() => {
        const getCoordenadas = async () => {
            const { latitud, longitud } = await AdapterGeolocation.getLocation()
            setCoordenadas({ X: longitud.toString(), Y: latitud.toString() })
            if (latitud && longitud) {
                function Coord2DMS(lat: number, long: number) {
                    if (long < 0) {
                        long = long * -1;
                    }
                    var long_deg = Math.floor(long);
                    var long_min = Math.floor((long - Math.floor(long)) * 60);
                    var long_sec = Math.round((((long - Math.floor(long)) * 60) - Math.floor(((long - Math.floor(long)) * 60))) * 60 * 1000) / 1000
                    const longAux = `${(long_deg).toFixed(0)}%C2%B0${long_min.toFixed(0)}'${long_sec.toFixed(1)}%22W`

                    if (lat < 0) {
                        lat = lat * -1;
                    }
                    var lat_deg = Math.floor(lat);
                    var lat_min = Math.floor((lat - Math.floor(lat)) * 60);
                    var lat_sec = Math.round((((lat - Math.floor(lat)) * 60) - Math.floor(((lat - Math.floor(lat)) * 60))) * 60 * 10000) / 10000
                    const latAux = `${(lat_deg).toFixed(0)}%C2%B0${lat_min.toFixed(0)}'${lat_sec.toFixed(1)}%22S`
                    return `${latAux}+${longAux}`
                }
                const urlGoogleMaps = `https://www.google.com/maps/place/${Coord2DMS(latitud, longitud)}/@${latitud},${longitud},500m/data=!3m1!1e3!4m4!3m3!8m2!3d${latitud}!4d${longitud}?entry=ttu`
                setUrlGoogleMaps(urlGoogleMaps)
            }
        }
        getCoordenadas()
    }, [])


    const init = async () => {
        try {
        } catch (error) {
            console.error(error);
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
        } finally {
            dispatch(removeLoading())
        }
    }

    const formLogin = useFormik({
        // initialValues: { username: 'lhuaman', password: 'G3n3r4l*/*@#', recordar: false, },
        initialValues: { username: !!recordar ? recordar.username : '', password: !!recordar ? recordar.password : '', recordar: !!recordar, },
        validationSchema: Yup.object({
            username: Yup.string().required('Ingresar Usuario').min(3, 'Mímimo 3 caracteres'),
            password: Yup.string().required('Ingresar Contraseña').min(3, 'Mímimo 3 caracteres'),
            recordar: Yup.boolean().notRequired(),
        }),

        onSubmit: (values, formikHelpers) => { },
    });

    const onChange = (name: string, value: any) => {
        if (value === null) { return; }
        formLogin.setFieldValue(name, value);
    };

    const onSubmit = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await formLogin.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formLogin.validateForm()); } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: 'Cargando...' }));

            const { user, response } = await login(
                AdapterEncrypt.encrypt(formLogin.values.username, process.env.REACT_APP_KEY_ENCRYPT_V1 || '', false),
                AdapterEncrypt.encrypt(formLogin.values.password, process.env.REACT_APP_KEY_ENCRYPT_V1 || '', false)
            )
            setUserGestor(user)
            const esGestorALEMANIAAL02 = await verificarSiEsGestorALEMANIAAL02(user)
            if (esGestorALEMANIAAL02) return
            const esGestorCHILE9512 = await verificarSiEsGestorCHILE9512(user, response)
            if (esGestorCHILE9512) return
            // let arrIdPaises: number[] = [];
            // let arrIdGrupos: number[] = [];
            // let arrIdDelegaciones: number[] = [];
            // let arrIdOT: number[] = [];

            // switch (user.DatosRol.Codigo) {
            //     case '02AP':
            //         arrIdPaises = user.Pais.map(row => row.IdPais);
            //         break;
            //     case '03AD':
            //     case '05DC':
            //     case '04U':
            //     case "Supervisor":
            //         arrIdPaises = user.Pais.map(row => row.IdPais);
            //         arrIdGrupos = user.Pais.flatMap(row => row.Grupos.map(subrow => subrow.IdGrupo));
            //         arrIdDelegaciones = user.Delegacion.map((item) => item.IdDelegacion);
            //         arrIdOT = user.Delegacion.flatMap(row => row.OT.map(subrow => subrow.IdOT));
            //         break;
            // }
            terminarLogin(user, response, user)
        } catch (error) {
            dispatch(removeLoading());
            console.error(error);
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        };
    };

    const login = async (username: string, password: string) => {
        const params: DtoRequestLogin = {
            User: username,
            Password: password,
            IP_Public: '',
            IP_Private: '',
            Navegador: navigator.userAgent.split(' AppleWebKit', 1)[0],
            Geolocalizacion: coordenadas,
            UrlGoogleMaps: urlGoogleMaps
        }
        const response: DtoResponseLogin | null = await new UseCaseLogin(repository).exec(params)
        if (response === null) { throw Error(`¡No se pudo iniciar sessión!`); }

        const user: EntityDataUsuario = {
            User: response.usuario.User,
            IdUsuario: response.usuario.IdUsuario,
            ApellidoPaterno: response.usuario.ApellidoPaterno,
            ApellidoMaterno: response.usuario.ApellidoMaterno,
            Nombres: response.usuario.Nombres,
            TipoDocumento: {
                TipoDocumento: response.usuario.TipoDocumento.TipoDocumento,
            },
            Identificacion: response.usuario.Identificacion,
            Codigo: response.usuario.Codigo,
            Empresa: {
                CodEmpresa: response.usuario.Empresa.CodEmpresa,
                CDEmpresa: response.usuario.Empresa.CDEmpresa,
                Empresa: response.usuario.Empresa.Empresa,
                Grupo: response.usuario.Empresa.Grupo,
            },
            Pais: {
                Codigo: response.usuario.Pais.Codigo,
                Nombre: response.usuario.Pais.Nombre
            },
            Delegacion: {
                ID_Delegacion: response.usuario.Delegacion.ID_Delegacion,
                Codigo: response.usuario.Delegacion.Codigo,
                Nombre: response.usuario.Delegacion.Nombre,
                Estado: {
                    ID_Estado: response.usuario.Delegacion.Estado.ID_Estado,
                    Nombre: response.usuario.Delegacion.Estado.Nombre,
                }
            },
            PaisesGestion: response.usuario.PaisesGestion,
            Email: response.usuario.Email,
            DatosTrabajo: {
                Delegacion: response.usuario.DatosTrabajo.Delegacion,
                OT: response.usuario.DatosTrabajo.OT,
                Area: response.usuario.DatosTrabajo.Area,
                Cargo: response.usuario.DatosTrabajo.Cargo,
            },
            Profile: response.usuario.Profile,
            Perfil: {
                IdPerfil: response.usuario.Perfil.IdPerfil,
                Perfil: response.usuario.Perfil.Perfil,
                Codigo: response.usuario.Perfil.Codigo,
            },
            Sistemas: response.usuario.Sistemas,
            ResetPassword: response.usuario.ResetPassword,
            ZonaTrabajo: response.usuario.ZonaTrabajo,
            Almacen: response.usuario.Almacen,
            SuperUsuario: response.usuario.SuperUsuario,
            ColeccionEmpresa: response.empresa,
            token: response.token,
            AlmacenEmpresa: response.usuario.AlmacenEmpresa
        }
        return { user, response }
    }

    const terminarLogin = (user: EntityDataUsuario, response: DtoResponseLogin, userGestor: EntityDataUsuario) => {
        if (formLogin.values.recordar) { AdapterStorage.set('recordar', JSON.stringify({ username: formLogin.values.username, password: formLogin.values.password })); }
        else { AdapterStorage.remove('recordar'); }
        var { [`preference${user.IdUsuario}`]: preference } = AdapterStorage.get(`preference${user.IdUsuario}`);
        dispatch(signIn({ token: response.token, tokenRefresh: '', user, userGestor, menu: response.permisos.menu, }));
        // await loadMaestro({ pais: arrIdPaises, grupo: arrIdGrupos, delegacion: arrIdDelegaciones, ot: arrIdOT });
        // dispatch(changePermisoVariable({ arrIdPaises, arrIdGrupos, arrIdDelegaciones, arrIdOT }));
        dispatch(changePreference(preference));
        formLogin.resetForm();
    }

    const verificarSiEsGestorALEMANIAAL02 = async (user: EntityDataUsuario) => {
        if (
            !['004'].includes(user.Pais.Codigo) &&
            !['AL02','AL04'].includes(user.Delegacion.Codigo)
        ) return false
        const esGestor = user.Profile.find(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo)
        if (!esGestor) throw Error(`No se pudo obtener perfil`)
        if (esGestor.Codigo !== 'RA_004AL02') return false
        const { dataCuadrillas } = await new UseCaseLoadData(repository).exec(user)
        setDataCuadrillas(dataCuadrillas)
        setShowDialogo(true)
        return true
    }

    const verificarSiEsGestorCHILE9512 = async (user: EntityDataUsuario, response: DtoResponseLogin) => {
        if (
            !['512'].includes(user.Pais.Codigo) &&
            !['9512'].includes(user.Delegacion.Codigo)
        ) return false
        if (user.ColeccionEmpresa.length !== 1) {
            throw Error(`¡No se pudo obtener Empresa!`)
        }
        const empresa = user.ColeccionEmpresa[0]
        if (empresa.Propia) return false
        const { dataCuadrillas } = await new UseCaseLoadData(repository).exec(user)
        const cuadrillas = dataCuadrillas.filter(
            (e) => e.Empresa.Codigo === empresa.Codigo &&
                e.Empresa.Codigo === response.cuadrilla.Empresa.Codigo &&
                empresa.Codigo === response.cuadrilla.Empresa.Codigo &&
                e.Codigo !== response.cuadrilla.Codigo
        )
        if (!cuadrillas.length) return false
        dispatch(removeLoading())
        const confirmacion = await AdapterGenerico.createMessage('Seleccionar Cuadrilla', '¿Desea seleccionar otra cuadrilla?', 'question', true)
        dispatch(addLoading({ textLoading: 'Cargando...' }))
        if (!confirmacion) return false
        setDataCuadrillas(cuadrillas)
        setShowDialogo(true)
        return true
    }

    const loadMaestro = async (params: { pais: Array<number>, grupo: Array<number>, delegacion: Array<number>, ot: Array<number> }) => {
        try {
            let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial } = AdapterStorage.get('servicesCalleds');
            await (new UseCaseCargaMaestro(repository)).exec(params, servicesCalleds);
            AdapterStorage.set('servicesCalleds', servicesCalleds);
        } catch (error) {
            throw error;
        }
    };

    const onClickRecuperarPassword = () => {
        navigate(`/${process.env.REACT_APP_ROUTE_PATH_RECOVERPASSWORD}`, { replace: true });
    };

    const onClickRegisterRDI = async () => {
        try {
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            await loadMaestro({ pais: [], grupo: [], delegacion: [], ot: [] });
            dispatch(removeLoading());
            dispatch(signInInvite());
            navigate(`/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI}/${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI_FORM}`, { replace: true });
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
        }
    }

    const selectCuadrilla = async (cuadrilla: DtoResponseCuadrillas) => {
        try {
            const params: DtoRequestV1InfoUser = {
                Usuario: formLogin.values.username,
                Contrasenia: formLogin.values.password,
                Identificacion: cuadrilla.Encargado.Identificacion
            }
            dispatch(addLoading({ textLoading: 'Cargando...' }))
            const data = AdapterEncrypt.encrypt(JSON.stringify(params), process.env.REACT_APP_KEY_ENCRYPT_V1 ?? '', false)
            const result = await new UseCaseV1InfoUser(repository).exec(data)
            const { user, response } = await login(result.User, result.Password)
            terminarLogin(user, response, userGestor)
        } catch (error) {
            dispatch(removeLoading());
            console.error(error);
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error', false)
        } finally {
            dispatch(removeLoading())
        }
    }

    return {
        showPassword,
        formLogin,
        onChange,
        onSubmit,
        setShowPassword,
        onClickRecuperarPassword,
        onClickRegisterRDI,
        init,
        dataCuadrillas,
        showDialogo,
        setShowDialogo,
        selectCuadrilla
    };
}