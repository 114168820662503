import { GetCodigoFechaActual } from 'sigo-package'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { iAutoInventario } from '../../shared/domain/iAutoInventario'
import { Repository } from '../infraestructure/repository'

export class UseCaseSelect {

  private repository: Repository
  private state: RootState

  constructor(repository: Repository, state: RootState) {
    this.repository = repository
    this.state = state
  }

  async exec(data: DtoResponseSelectStockPersonal[]) {
    return await this._exec(data)
  }

  private async _exec(data: DtoResponseSelectStockPersonal[]) {
    if (!this.state.generico.autoInventario) {
      return await this.consultarDataAutoinventario(data)
    }
    return this.generarDataAutoInventario(data)
  }

  private async consultarDataAutoinventario(data: DtoResponseSelectStockPersonal[]) {

    const IDS_StockPersonal = data.map(e => e.ID_StockPersonal)

    return await this.repository.find({
      filter: [
        { $match: { ID_StockPersonal: { $in: IDS_StockPersonal } } }
      ],
      fields: {
        Codigo: this.state.auth.user.Delegacion.Codigo,
        Nombre: this.state.auth.user.Pais.Nombre,
      },
      limit: null,
      skip: null,
      sort: null
    })

  }

  private generarDataAutoInventario(dataStockPersonal: DtoResponseSelectStockPersonal[]) {

    const data: iAutoInventario[] = []

    dataStockPersonal.forEach(e => {
      const newItem = new iAutoInventario()
      newItem.ID_StockPersonal = e.ID_StockPersonal
      newItem.KeyFecha = GetCodigoFechaActual()
      newItem.Codigo = `${e.ID_StockPersonal}_${newItem.KeyFecha}`
      newItem.Observacion = 'Confirmado'
      let stock = e.Despacho - e.Devolucion - e.Liquidacion + e.DespachoTR - e.DevolucionTR
      const precision = 1e-10
      if (Math.abs(stock) < precision) {
        stock = 0
      }
      newItem.Cantidad = Number(stock.toFixed(2))
      newItem.Stock = Number(stock.toFixed(2))
      newItem.Estado.ID_Estado = 2
      newItem.Estado.Nombre = 'VALIDADO'
      newItem.Identificacion = e.Personal.Identificacion
      newItem.Ind_SB = e.Item.Ind_SB
      newItem.CodigoLlave = e.CodigoLlave
      newItem.CodigoBusqueda = e.CodigoBusqueda
      data.push(newItem)
    })

    return data

  }

}