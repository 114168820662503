import { EntityDataUsuario } from "../../../../shared/Domain/EntityDataUsuario"
import { typeEstadosAddressID } from "../../Shared/Domain"
import { DtoTrabajosDE } from "../../Lista/Domain/DtoTrabajosDE"
import { DtoRequestUpdateTrabajo } from "../Domain/DtoRequestUpdateTrabajo"
import { RepositoryMain } from "../Domain/RepositoryMain"

export class UseCaseChangeEstadoHPList {

    private repository: RepositoryMain
    private trabajo: DtoTrabajosDE
    private user: EntityDataUsuario

    constructor(repository: RepositoryMain, trabajo: DtoTrabajosDE, user: EntityDataUsuario) {
        this.repository = repository
        this.trabajo = trabajo
        this.user = user
    }

    async exec(campo: typeEstadosAddressID) {
        return await this._exec(campo)
    }

    private async _exec(campo: typeEstadosAddressID) {
        this.updateState(campo)
        const params: DtoRequestUpdateTrabajo = {
            Header: {
                Coleccion: {
                    Codigo: this.user.Delegacion.Codigo,
                    Nombre: this.user.Pais.Nombre
                }
            },
            Body: this.trabajo
        }
        return await this.repository.updateTrabajos(params, this.user)
    }

    private updateState(campo: typeEstadosAddressID) {
        if (campo === 'OC') {
            this.trabajo.Ultima_PreLiquidacion.OC.Estado = true
            this.trabajo.Ultima_PreLiquidacion.Asfaltado.Estado = false
            this.trabajo.Ultima_PreLiquidacion.Soplado.Estado = false
        }
        if (campo === 'ASPH') {
            this.trabajo.Ultima_PreLiquidacion.OC.Estado = true
            this.trabajo.Ultima_PreLiquidacion.Asfaltado.Estado = true
        }
        if (campo === 'SOP') {
            this.trabajo.Ultima_PreLiquidacion.OC.Estado = true
            this.trabajo.Ultima_PreLiquidacion.Soplado.Estado = true
        }
    }

}