const SCHEMA: string = 'Maestro';
const ENTITY: string = 'Pais';

const TIMEZONE: string = 'America/Lima';
const SELECT_STOCK_PERSONAL: string = '/SigoPwa/Logistica/Reportes/StockPersonal/Select';
const SELECT_STOCK_ALMACEN: string = '/SigoPwa/Logistica/Reportes/StockAlmacen/Select';
const SELECT_CONSUMO_MATERIAL: string = '/SigoPwa/Logistica/Reportes/ConsumoMaterial/Select';
const SELECT_TRABAJOS: string = '/SigoPwa/Programados/Gestion/Trabajos/Select';
const VERIFY_TOKEN: string = '/SigoPwa/auth/verify'
const VERIFY_AUTOINVENTARIO: string = '/maestros/usuariosautoinventario/verify'
const SELECT_REP_BAREMOS_PERIODO_ACTUAL_COL_9612 = '/colombia/9612/produccion/trabajos/reporte-baremos-periodo-actual'

const {
  REACT_APP_ROUTE_PATH_MAIN,
  REACT_APP_ROUTE_PATH_MAIN_LOGISTICA,
  REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_AUTO_INVENTARIO,
} = process.env
const ROUTE_LOGISTICA = `/${REACT_APP_ROUTE_PATH_MAIN}/${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA}`
const ROUTE_AUTO_INVENTARIO = `${ROUTE_LOGISTICA}/${REACT_APP_ROUTE_PATH_MAIN_LOGISTICA_AUTO_INVENTARIO}`

export const AdapterConfigure = {
  TIMEZONE,

  SCHEMA,
  ENTITY,
  SELECT_STOCK_PERSONAL,
  SELECT_STOCK_ALMACEN,
  SELECT_TRABAJOS,
  VERIFY_TOKEN,
  VERIFY_AUTOINVENTARIO,
  SELECT_CONSUMO_MATERIAL,
  SELECT_REP_BAREMOS_PERIODO_ACTUAL_COL_9612,
  ROUTE_AUTO_INVENTARIO
};