import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";
import { LanguageTranslate } from "./LanguageTranslate";

export class AdapterGenerico {
  public static async createMessage(title: string = '', html: string = '', icon: SweetAlertIcon = 'info', buttonCancel: boolean = false, target: string = 'body', clickConfirm = () => { }, cancelButtonText?: string, cancelButtonColor?: string) {
    let language = LanguageTranslate();
    let config: SweetAlertOptions = {
      title: title,
      html: html,
      icon,
      allowOutsideClick: true,
      allowEscapeKey: true,
      allowEnterKey: true,
      confirmButtonText: language.code === 'es' ? 'Aceptar' : language.code === 'en' ? 'Accept' : 'Accept',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      backdrop: true,
      reverseButtons: false,
      focusConfirm: true,
      target: target,
    };

    if (buttonCancel === true) { Object.assign(config, { showCancelButton: true, cancelButtonColor: cancelButtonColor ? cancelButtonColor : '#f44336', cancelButtonText: cancelButtonText ? cancelButtonText : 'Cancelar', }); }

    let Message = await Swal.fire(config).then((result) => {
      if (result.isConfirmed) clickConfirm();
      return result;
    });

    return Reflect.has(Message, 'dismiss') ? false : true;
  }

  public static createToast(html: string = '', icon: SweetAlertIcon = 'info', target: string = 'body') {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      target,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        toast.style.zIndex = '9999999'
      }
    });

    Toast.fire({ icon, html });
  }

  public static randomString(longitud: number = 11) {
    let caracteres = "abcdefghijkmnpqrtuvwxyz2346789";
    let cadena = "";
    for (let i = 0; i < longitud; i++) cadena += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    return cadena;
  }

  public static isJSON(str: string) {
    try {
      str = JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  public static calcularEdad(fecha: string) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }

    return edad;
  }

  public static toBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  public static base64ToFile(base64String: string, fileName: string): File {
    const matches = base64String.match(/^data:([A-Za-z-+/]+);base64,(.+)$/) || [];
    const type = matches[1];
    const data = atob(matches[2]);
    const dataArray = new Uint8Array(data.length);
    for (let i = 0; i < data.length; i++) {
      dataArray[i] = data.charCodeAt(i);
    }
    return new File([dataArray], fileName, { type: type });
  }

  public static convertStringToDate(stringDate: string = '01/01/1900') {
    try {
      let i = 0;
      let f = 0;
      let y: any = '';
      let m: any = '';
      let d: any = '';
      let h: any = '';
      let mi: any = '';
      let s: any = '';
      let Tipo = 0;

      Tipo = stringDate.indexOf('-') !== -1
        ? 1
        : stringDate.indexOf('/') !== -1
          ? 2
          : 0;
      switch (Tipo) {
        case 1:
          f = 4;
          y = stringDate.slice(i, f);
          if (y.indexOf('-') !== -1) { f--; y = stringDate.slice(i, f); }
          else { y = y === '' ? 1 : parseInt(y); }
          i = f + 1;
          f = i + 2;

          m = stringDate.slice(i, f);
          if (m.indexOf('-') !== -1) { f -= 1; m = stringDate.slice(i, f); }
          else { m = m === '' ? 0 : parseInt(m) - 1; }
          i = f + 1;
          f = i + 2;

          d = stringDate.slice(i, f);
          if (d.indexOf('/') !== -1) { f -= 1; d = stringDate.slice(i, f); }
          else { d = d === '' ? 1 : parseInt(d); }
          i = f + 1;
          f = i + 2;

          h = stringDate.slice(i, f);
          h = h === '' ? 0 : parseInt(h);
          i = f + 1;
          f = i + 2;

          mi = stringDate.slice(i, f);
          mi = mi === '' ? 0 : parseInt(mi);
          i = f + 1;
          f = i + 2;

          s = stringDate.slice(i, f);
          s = s === '' ? 0 : parseInt(s);
          break;
        case 2:
          f = 2;
          d = stringDate.slice(i, f);
          if (d.indexOf('/') !== -1) { f--; d = stringDate.slice(i, f); }
          else { d = d === '' ? 1 : parseInt(d); }
          i = f + 1;
          f = i + 2;

          m = stringDate.slice(i, f);
          if (m.indexOf('/') !== -1) { f -= 1; m = stringDate.slice(i, f); }
          else { m = m === '' ? 0 : parseInt(m) - 1; }
          i = f + 1;
          f = i + 4;

          y = stringDate.slice(i, f);
          if (y.indexOf('/') !== -1) { f--; y = stringDate.slice(i, f); }
          else { y = y === '' ? 1 : parseInt(y); }
          i = f + 1;
          f = i + 2;

          h = stringDate.slice(i, f);
          h = h === '' ? 0 : parseInt(h);
          i = f + 1;
          f = i + 2;

          mi = stringDate.slice(i, f);
          mi = mi === '' ? 0 : parseInt(mi);
          i = f + 1;
          f = i + 2;

          s = stringDate.slice(i, f);
          s = s === '' ? 0 : parseInt(s);
          break;
        default:
          throw new Error('formato desconocido');
      }
      let resp: Date = new Date(y, m, d, h, mi, s, 0);
      if (Number.isNaN(resp.getTime())) {
        throw Error('Fecha no es Válida')
      }
      return resp;
    } catch (error) {
      throw error;
    }
  }

  public static convertDateToString(Fecha: Date = new Date(), Tipo: number = 1) {

    try {
      Fecha = typeof Fecha === 'string' ? new Date(Fecha) : Fecha;
      let meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
      let y = Fecha.getFullYear();
      let m = Fecha.getMonth() + 1;
      let d = Fecha.getDate();

      let h = Fecha.getHours();
      let mi = Fecha.getMinutes();
      let s = Fecha.getSeconds();

      let am = h > 11 ? false : true;

      let h2 = h > 12 ? h - 12 : h;

      let result = '';

      switch (Tipo) {
        case 1:
          result = Fecha.toISOString();
          break;
        case 2:
          result = `${(d < 10 ? '0' : '') + d}/${(m < 10 ? '0' : '') + m}/${y} ${(h < 10 ? '0' : '') + h}:${(mi < 10 ? '0' : '') + mi}:${(s < 10 ? '0' : '') + s}`;
          break;
        case 3:
          result = `${(d < 10 ? '0' : '') + d}/${(m < 10 ? '0' : '') + m}/${y}`;
          break;
        case 4:
          result = `${(d < 10 ? '0' : '') + d} de ${meses[m - 1]} del ${y}`;
          break;
        case 5:
          result = `${(d < 10 ? '0' : '') + d}/${(m < 10 ? '0' : '') + m}/${y} ${(h2 < 10 ? '0' : '') + h2}:${(mi < 10 ? '0' : '') + mi} ${am ? 'AM' : 'PM'}`;
          break;
        case 6:
          result = `${y}-${(m < 10 ? '0' : '') + m}-${(d < 10 ? '0' : '') + d}`;
          break;
        default:
          throw new Error('Datos no correctos');
      }
      return result;
    } catch (error) {
      throw error;
    }
  }

  public static convertFormat(value: string, format: string) {
    try {
      if (typeof format !== 'string') { throw new Error('formato debe estar en string.'); }
      value = typeof value !== 'string' ? `${value}` : value;
      return value.length > format.length ? value : format.substring(0, format.length - value.length) + value;
    } catch (error) {
      throw error;
    }
  }

  public static decodeTokenJWT(Token: string) {
    try {
      let data = Token.split('.')[1];
      data = atob(data);
      data = AdapterGenerico.isJSON(data) ? JSON.parse(data) : data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  public static getBase64ImageFromURL(url: string) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext("2d");
        if (ctx !== null) {
          ctx.drawImage(img, 0, 0);
          let dataURL = canvas.toDataURL("image/png");
          resolve(dataURL);
        }
        else { reject(new Error('ctx null')) }
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  public static summa(arrayJson: Array<any>, Clave: string, noincluir: any, incluir: any) {
    try {
      if (arrayJson.length === 0) { return 0 };
      let ClaveArrai = Clave.split('.');
      if (arrayJson.length === 0) {
        return 0;
      } else {
        switch (ClaveArrai.length) {
          case 1:
            return arrayJson.map((a) => {
              if (typeof noincluir !== 'undefined') {
                if (a[noincluir.key] === noincluir.value) {
                  return 0
                } else {
                  return a[ClaveArrai[0]];
                }
              } else if (typeof incluir !== 'undefined') {
                let ClaveArraiIncluir = incluir.key.split('.');
                switch (ClaveArraiIncluir.length) {
                  case 1:
                    if (a[ClaveArraiIncluir[0]] === incluir.value) {
                      return a[ClaveArrai[0]];
                    } else {
                      return 0
                    }
                  case 2:
                    if (a[ClaveArraiIncluir[0]][ClaveArraiIncluir[1]] === incluir.value) {
                      return a[ClaveArrai[0]];
                    } else {
                      return 0
                    }
                  default:
                    return 0;
                }
              } else {
                return a[ClaveArrai[0]];
              }
            }).reduce((b, c) => {
              return b + c
            })
          case 2:
            return arrayJson.map((a) => {
              if (typeof noincluir !== 'undefined') {
                let ClaveArraiNoIncluir = noincluir.key.split('.');
                switch (ClaveArraiNoIncluir.length) {
                  case 1:
                    if (a[ClaveArraiNoIncluir[0]] === noincluir.value) {
                      return 0
                    } else {
                      return a[ClaveArrai[0]][ClaveArrai[1]];
                    }
                  case 2:
                    if (a[ClaveArraiNoIncluir[0]][ClaveArraiNoIncluir[1]] === noincluir.value) {
                      return 0
                    } else {
                      return a[ClaveArrai[0]][ClaveArrai[1]];
                    }
                  default:
                    return 0;
                }
              } else if (typeof incluir !== 'undefined') {
                let ClaveArraiIncluir = incluir.key.split('.');
                switch (ClaveArraiIncluir.length) {
                  case 1:
                    if (a[ClaveArraiIncluir[0]] === incluir.value) {
                      return a[ClaveArrai[0]][ClaveArrai[1]];
                    } else {
                      return 0
                    }
                  case 2:
                    if (a[ClaveArraiIncluir[0]][ClaveArraiIncluir[1]] === incluir.value) {
                      return a[ClaveArrai[0]][ClaveArrai[1]];
                    } else {
                      return 0
                    }
                  default:
                    return 0;
                }
              } else {
                return a[ClaveArrai[0]][ClaveArrai[1]];
              }
            }).reduce((b, c) => {
              return b + c
            })
          default:
            return 0;
        }
      }
    } catch (error: any) {
      throw Error(error.message);
    }
    /* Ejemplos:
    //Sin exclusion
        SummationValuesinArrayJson(Documentos, 'DatosImporte.Monto')
    //Con Exclusion 1 nivel
        SummationValuesinArrayJson(Documentos, 'DatosImporte.Monto', {
            key: 'ide',
            value: Documento_Editar.ide
        })
    //Con Exclusion 2 niveles
    SummationValuesinArrayJson(a.Documentos, 'DatosImporte.Monto', {
        key: 'Status.IdStatus',
        value: 10
    });
    //Con Inclusión
    await SummationValuesinArrayJson(a.Documentos, 'DatosImporte.Monto', undefined, {
        key: 'Status.IdStatus',
        value: 1
    });
    */
  }

  public static convertFormatDecimal(number: number, decimal: number) {
    return new Intl.NumberFormat("en-EN", { maximumFractionDigits: decimal, minimumFractionDigits: decimal }).format(number);
  }

  public static ordenarArray(data: Array<any> = [], campo: string = '', dir: number = 1) {
    data.sort((a, b) => a[campo] < b[campo] ? (dir * -1) : a[campo] > b[campo] ? (dir * 1) : 0);
    return data;
  }

  public static patternSoloNumeros(minLength = 0, maxLength = 50) {
    return `^[0-9]{${minLength},${maxLength}}$`;
  }

  public static patternSoloLetras(minLength = 0, maxLength = 50) {
    return `^[a-zA-ZÀ-ÿ\u00f1\u00d1]{${minLength},${maxLength}}$`;
  }

  public static patternLetrasEspacio(minLength = 0, maxLength = 50) {
    return `^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]{${minLength},${maxLength}}$`;
  }

  public static patternLetrasCaracteres(minLength = 0, maxLength = 50) {
    return `^[\\D ]{${minLength},${maxLength}}$`;
  }

  public static patternLetrasNumeros(minLength = 0, maxLength = 50) {
    return `^[a-zA-ZÀ-ÿ\u00f1\u00d10-9]{${minLength},${maxLength}}$`;
  }

  public static patternLetrasNumerosEspacio(minLength = 0, maxLength = 50) {
    return `^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]{${minLength},${maxLength}}$`;
  }

  public static patternLetrasNumerosCaracteres(minLength = 0, maxLength = 50) {
    return `^[\\D0-9$]{${minLength},${maxLength}}$`;
  }

  public static calculoTiempo(date: Date, language: 'es' | 'en' | 'de' = 'es') {
    const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return language === 'es'
        ? `Hace ${Math.floor(interval)} año${Math.floor(interval) > 1 ? 's' : ''}`
        : language === 'en'
          ? `${Math.floor(interval)} year ago`
          : '';
    }

    interval = seconds / 2592000;
    if (interval > 1) {
      return language === 'es'
        ? `Hace ${Math.floor(interval)} mes${Math.floor(interval) > 1 ? 'es' : ''}`
        : language === 'en'
          ? `${Math.floor(interval)} month ago`
          : '';
    }

    interval = seconds / 86400;
    if (interval > 1) {
      return language === 'es'
        ? `Hace ${Math.floor(interval)} día${Math.floor(interval) > 1 ? 's' : ''}`
        : language === 'en'
          ? `${Math.floor(interval)} day ago`
          : '';
    }

    interval = seconds / 3600;
    if (interval > 1) {
      return language === 'es'
        ? `Hace ${Math.floor(interval)} hora${Math.floor(interval) > 1 ? 's' : ''}`
        : language === 'en'
          ? `${Math.floor(interval)} hour ago`
          : '';
    }

    interval = seconds / 60;
    if (interval > 1) {
      return language === 'es'
        ? `Hace ${Math.floor(interval)} minuto${Math.floor(interval) > 1 ? 's' : ''}`
        : language === 'en'
          ? `${Math.floor(interval)} minute ago`
          : '';
    }

    return language === 'es'
      ? `Hace ${Math.floor(seconds)} segundo${Math.floor(seconds) > 1 ? 's' : ''}`
      : language === 'en'
        ? `${Math.floor(seconds)} second ago`
        : '';
  }

  public static formatoPersonalizadoSelect(data: any, keyValue: string | string[], keyLabel: string | string[], separator?: string) {
    if (Array.isArray(data)) {
      return data.map((item) => ({
        label: Array.isArray(keyLabel) ? keyLabel.map((key) => item[key]).join(separator ? separator : ' ') : item[keyLabel],
        value: Array.isArray(keyValue) ? keyValue.map((key) => item[key]).join(' ') : item[keyValue],
        dataComplete: item,
      }))
    } else {
      return {
        label: Array.isArray(keyLabel) ? keyLabel.map((key) => data[key]).join(separator ? separator : ' ') : data[keyLabel],
        value: Array.isArray(keyValue) ? keyValue.map((key) => data[key]).join(' ') : data[keyValue],
        dataComplete: data,
      }
    }
  }

  public static formatoParaGuardarImagenes(FechaActual = new Date()) {
    let MesActual: number | string = (FechaActual.getMonth() + 1);
    if (MesActual < 10) {
      MesActual = '0' + MesActual;
    }

    let DiaActual: number | string = FechaActual.getDate();
    if (DiaActual < 10) {
      DiaActual = '0' + DiaActual;
    }

    let HoraActual: number | string = FechaActual.getHours();
    if (HoraActual < 10) {
      HoraActual = '0' + HoraActual;
    }

    let MinutoActual: number | string = FechaActual.getMinutes();
    if (MinutoActual < 10) {
      MinutoActual = '0' + MinutoActual;
    }
    let SegundoActual: number | string = FechaActual.getSeconds()
    if (SegundoActual < 10) {
      SegundoActual = '0' + SegundoActual;
    }

    return `${FechaActual.getFullYear()}${MesActual}${DiaActual}${HoraActual}${MinutoActual}${SegundoActual}`;
  }

  public static ObtenerIdFechaISO(Fecha: string) {
    let Anio = parseInt(Fecha.toString().substring(0, 4)) * 10000;
    let Mes = parseInt(Fecha.toString().substring(5, 7)) * 100;
    let Dia = parseInt(Fecha.toString().substring(8, 10));
    return (Anio + Mes + Dia);
  }

  public static getFechaISO_Local() {
    let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    let localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
    return localISOTime;
  };

  public static GetFechaISOEnviada(Fecha: string, Tipo: number) {
    let Dia = Fecha.toString().substring(0, 2);
    let Mes = Fecha.toString().substring(3, 2);
    let Anio = Fecha.toString().substring(6, 4);
    let HoraActual = '';
    let MinutoActual = '';

    if (Tipo === 1) {
      HoraActual = '00';
      MinutoActual = '00';
    }
    else if (Tipo === 2) {
      HoraActual = '23';
      MinutoActual = '59';
    }
    else {
      HoraActual = Fecha.toString().substring(11, 2);
      MinutoActual = Fecha.toString().substring(14, 2);
    }

    let Fecha_Registro = Anio + '-' + Mes + '-' + Dia + ' ' + HoraActual + ':' + MinutoActual;

    return Fecha_Registro;
  }

  public static formattingNameOT(code: string = "", delegation: string = ""): string {
    let regex = new RegExp(code, 'g');
    let isContaintsCode = delegation.match(regex);

    if (isContaintsCode) {
      return delegation;
    } else {
      return code + " - " + delegation;
    }
  }

  public static scrollTopByClassName(className: string = "") {
    document.getElementsByClassName(className)[0]?.scrollIntoView();
  }

  public static getIdFechaGlobal = (Fecha = new Date()) => {
    let y = Fecha.getFullYear(), m = Fecha.getMonth() + 1, d = Fecha.getDate();
    return parseInt('' + y + (m < 10 ? '0' : '') + m + (d < 10 ? '0' : '') + d);
  };

  public static replaceAccents(txt: string): string {
    return txt.replace(/[áéíóú]/g, (match: string) => {
      switch (match) {
        case "á":
          return "a";
        case "é":
          return "e";
        case "í":
          return "i";
        case "ó":
          return "o";
        case "ú":
          return "u";
        default:
          return match;
      }
    });
  }

  public static generarStringUnico = (): string => {
    const timestamp = new Date().getTime()
    const randomNumber = Math.floor(Math.random() * 1000000)
    const uniqueString = timestamp.toString() + randomNumber.toString()
    return uniqueString
  }

  public static obtenerExtensionDesdeBase64 = (base64String: string): string | undefined => {
    const tipoArchivo = base64String.match(/^data:(.+);base64/)
    if (tipoArchivo && tipoArchivo[1]) {
      const partesTipoArchivo = tipoArchivo[1].split('/')
      return partesTipoArchivo[1]
    }
  }

}
