import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { OffCanvasBottom } from '../../../../shared/Components/ReactBootstrap/OffCanvasBottom/OffCanvasBottom'
import { Adjuntos } from '../../../../shared/Components/Adjuntos/Adjuntos'
import { DtoDocumentacionBaremoPEX, DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { obtenerDocumentacionNoClientes, obtenerDocumentacionClientes, codMOPublico, codMONoClientes_ReglaLiquidacion1, codMOClientes_ReglaLiquidacion1, codMODROPAL04, codMOFTTFAL04 } from 'sigo-package'
import { typeHPList } from '../Domain/typeHPList'
import { DtoHPList, DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { FormAdd } from '../../Preliquidar/Components/FormAdd'

// Preliquidar
import { DtoDataSelectAsignaciones } from '../../Preliquidar/Domain/DtoDataSelectAsignaciones'
import { DtoDataAddedValorizaciones } from '../../Shared/Preliquidar/Interfaces/DtoDataAddedValorizaciones'
import { DtoInitialValues } from '../../Shared'
import { FormikHelpers } from 'formik'
import { DtoMaterialesUtiRet } from '../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { Valorizaciones } from './Valorizaciones'
import { DtoDataMateriales } from '../../Preliquidar/Domain/DtoDataMateriales'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'

interface props {
  showOffCanvas: boolean
  setShowOffCanvas: Dispatch<SetStateAction<boolean>>
  typeHPList: typeHPList
  onSave: (
    values: DtoInitialValues,
    formikHelpers: FormikHelpers<DtoInitialValues> | null,
    materialesInstalado: DtoMaterialesUtiRet[],
    materialesRetirado: DtoMaterialesUtiRet[],
    nodos: DtoNodoDoc[],
    valRechazadaXMetraje: DtoValorizacion | null
  ) => Promise<boolean>
  updateHP: DtoHPList | null
  newFiles: File[]
  setNewFiles: Dispatch<SetStateAction<File[]>>
  ruta: string
  home_ID: string
  codEspecialidades: string[]
  asignaciones: DtoDataSelectAsignaciones[]
  valorizaciones: DtoDataAddedValorizaciones[]
  onSubmit: (
    values: DtoInitialValues,
    formikHelpers: FormikHelpers<DtoInitialValues>,
    materialesInstalado: DtoMaterialesUtiRet[],
    materialesRetirado: DtoMaterialesUtiRet[]
  ) => void | Promise<any>
  setDocumentacionValorizacion: Dispatch<SetStateAction<DtoNodoDoc[]>>
  documentacionValorizacion: DtoNodoDoc[],
  setNewFilesUpload: Dispatch<SetStateAction<File[]>>
  newFilesUpload: File[],
  dataDocumentacionBaremoPEX: DtoDocumentacionBaremoPEX[]
  trabajo: DtoTrabajosDE | null
  onChange: (name: string, value: any, materialesInstalado: DtoMaterialesUtiRet[]) => { dataAlmacen: DtoDataMateriales[], dataMateriales: DtoDataMateriales[] } | undefined
  dataManoObraGlobal: DtoManoObraGlobal[]
  dataMateriales: DtoDataMateriales[]
  ID_AddressList: number
  Home_ID: string
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  dataMaterialesAlmacen: DtoDataMateriales[]
}

export const Documentacion = (props: props) => {

  const {
    setShowOffCanvas,
    showOffCanvas,
    typeHPList,
    onSave,
    updateHP,
    newFiles,
    setNewFiles,
    ruta,
    home_ID,
    codEspecialidades,
    asignaciones,
    valorizaciones,
    documentacionValorizacion,
    setDocumentacionValorizacion,
    newFilesUpload,
    setNewFilesUpload,
    dataDocumentacionBaremoPEX,
    trabajo,
    onChange,
    dataManoObraGlobal,
    dataMateriales,
    ID_AddressList,
    setShowModal,
    dataMaterialesAlmacen
  } = props
  const [nodos, setNodos] = useState<DtoNodoDoc[]>([])
  const [valRechazadaXMetraje, setValRechazadaXMetraje] = useState<DtoValorizacion | null>(null)
  const [codigosAFiltrar, setCodigosAFiltrar] = useState<string[]>([])

  const restoreFiles = (documentacion: DtoNodoDoc[], updateHP: DtoHPList) => {

    const update = updateHP.Asignaciones.find(e => e.Empresa === trabajo?.Ultima_asignacion.Empresa.Codigo)

    if (!update) throw Error(`¡No se pudo obtener asignación!`)

    for (const nodo of documentacion) {
      const files = update.Anexos.filter(e => e.CodigoNodo === nodo.Codigo)
      nodo.Files = [...nodo.Files.filter(e => e.Estado.ID_Estado === 2024), ...files]
      restoreFiles(nodo.Children, updateHP)
    }
  }

  const determinaPermitirValorizar = (trabajo: DtoTrabajosDE) => {
    // const estados = ['PRODUCCION', 'CERTIFICADA', 'PRELIQUIDADA', 'LIQUIDADA', 'RECHAZADA POR METRAJE', 'RECHAZADA POR DOCUMENTACION']
    const codMOPrivado = typeHPList === 'Business' ? codMONoClientes_ReglaLiquidacion1 : codMOClientes_ReglaLiquidacion1
    const verificarExistencia = (codigos: string[]) => {
      return false
      // return trabajo.Ultima_PreLiquidacion.Valorizacion.some(e =>
      //   e.HomePass[0] &&
      //   e.HomePass[0].ID_AddressList === ID_AddressList &&
      //   e.HomePass[0].Home_ID === home_ID &&
      //   e.HomePass[0].ClientType === typeHPList &&
      //   estados.includes(e.Ultimo_Estado_Interno.Descripcion) &&
      //   codigos.includes(e.DetalleChile.ManoObra.Codigo)
      // )
    }
    const existCodMOPublico = verificarExistencia(codMOPublico)
    const existCodMOPrivado = verificarExistencia(codMOPrivado)
    const codigosAFiltrar = [
      ...existCodMOPublico ? codMOPublico : [],
      ...existCodMOPrivado ? codMOPrivado : [],
    ]
    setCodigosAFiltrar(codigosAFiltrar)
  }

  useEffect(() => {
    if (!trabajo) return
    if (showOffCanvas) {
      determinaPermitirValorizar(trabajo)
    }
  }, [showOffCanvas])

  useEffect(() => {
    if (showOffCanvas && updateHP) {
      const documentacion = typeHPList === 'Business' ?
        obtenerDocumentacionNoClientes({ codEspecialidades }) :
        obtenerDocumentacionClientes({ codEspecialidades }).Nodos
      restoreFiles(documentacion, updateHP)
      setNodos(documentacion)
      setNewFiles([])
    }
  }, [showOffCanvas])

  return (
    <OffCanvasBottom
      height={82}
      show={showOffCanvas}
      setShow={setShowOffCanvas}
      children={
        <div>
          <Adjuntos
            Nodos={nodos}
            setNodos={setNodos}
            ruta={`${ruta}_${typeHPList}_${home_ID}_`}
            newFiles={newFiles}
            setNewFiles={setNewFiles}
          />
          <div className='mt-3'>
            <Valorizaciones
              trabajo={trabajo}
              ID_AddressList={ID_AddressList}
              Home_ID={home_ID}
              typeHPList={typeHPList}
              valRechazadaXMetraje={valRechazadaXMetraje}
              setValRechazadaXMetraje={setValRechazadaXMetraje}
            />
          </div>
          <div className='mt-3'>
            <FormAdd
              asignaciones={
                asignaciones
                  .filter(asignacion =>
                    (
                      typeHPList === 'Business'
                        ? codMOFTTFAL04
                        : codMODROPAL04
                    )
                      .filter(codigo => !codigosAFiltrar.includes(codigo))
                      .includes(asignacion.ManoObra.Codigo)
                  )
              }
              valorizaciones={valorizaciones}
              onSubmit={async (values, formikHelpers, materialesInstalado, materialesRetirado) => {
                await onSave(values, formikHelpers, materialesInstalado, materialesRetirado, nodos, valRechazadaXMetraje)
                setNewFiles([])
                setValRechazadaXMetraje(null)
              }}
              documentacionValorizacion={documentacionValorizacion}
              setDocumentacionValorizacion={setDocumentacionValorizacion}
              newFilesUpload={newFilesUpload}
              setNewFilesUpload={setNewFilesUpload}
              dataDocumentacionBaremoPEX={dataDocumentacionBaremoPEX}
              trabajo={trabajo}
              onClick={() => setShowModal((prev) => !prev)}
              onChange={onChange}
              dataMateriales={dataMateriales}
              // dataManoObraGlobal={dataManoObraGlobal}
              address={{
                ID_AddressList,
                Home_ID: home_ID,
                typeHPList
              }}
              newFiles={newFiles}
              dataMaterialesAlmacen={dataMaterialesAlmacen}
              valRechazadaXMetraje={valRechazadaXMetraje}
              setValRechazadaXMetraje={setValRechazadaXMetraje}
            />
          </div>
        </div>
      }
    />
  )
}
