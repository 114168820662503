import { RepositoryMain } from '../Domain/RepositoryMain'
import { RepositoryImplGenerico } from '../../../../shared/Infraestructure/RepositoryImplGenerico'
import { AdapterConfigure } from './AdapterConfigure'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { DtoDocumentacionBaremoPEX } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { FTPService } from '../../../../shared/Infraestructure/AdapterFileSystem'
import { DtoRequestUpdateTrabajo } from '../Domain/DtoRequestUpdateTrabajo'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { addCountProcess } from '../../../../shared/Infraestructure/SliceGenerico'
import { ActualizarDataResumenLiquidaciones, CalcularResumenLiquidaciones } from 'sigo-package'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { DtoReqSelectTrabajoDE } from '../../Lista/Domain/DtoReqSelectTrabajoDE'
import { DtoStockAlmacen } from '../../../../Master/Home/Domain/DtoStockAlmacen'
import { DtoConsumoMaterial } from '../../../../Master/Home/Domain/DtoConsumoMaterial'

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

  public async selectTrabajo(params: DtoReqSelectTrabajoDE, user: EntityDataUsuario, idTrabajo: number): Promise<DtoTrabajosDE[]> {

    const url: string = `${this.urlBase}${AdapterConfigure.SELECT_TRABAJOS}`

    let response: Array<any> = navigator.onLine
      ? await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'text', {})
      : this.selectTrabajoLocal(user, idTrabajo)
    response = typeof response === 'string'
      ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response
      : response
    response = Array.isArray(response) ? response : [response]
    return response
  }

  public async selectTrabajoLocal(user: EntityDataUsuario, idTrabajo: number): Promise<DtoTrabajosDE> {
    const trabajo = await this.dbLocal.selectByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, value: idTrabajo })
    return trabajo
  }

  public async selectDocumentacionBaremoPEX(): Promise<DtoDocumentacionBaremoPEX[]> {
    return await this.dbLocal.selectAllStore('DocumentacionBaremoPEX')
  }

  public async selectEstadoInterno(): Promise<any[]> {
    return await this.dbLocal.selectAllStore('EstadoInterno')
  }

  public async selectManoObraGlobal(): Promise<any[]> {
    return await this.dbLocal.selectAllStore('ManoObraGlobal')
  }

  public async selectPrecioEspecialidad(): Promise<any[]> {
    return await this.dbLocal.selectAllStore('PrecioEspecialidad')
  }

  async selectPrecioMaterial(): Promise<any[]> {
    return await this.dbLocal.selectAllStore('PrecioMaterial')
  }

  async uploadFiles(files: File[], user: EntityDataUsuario, idTrabajo: number) {
    console.info(`¡Se subirán ${files.length} archivos!`)
    if (navigator.onLine) {
      for await (const file of files) {
        await FTPService.setFileToServer(file.name, file)
      }
      // await Promise.all(files.map(e => FTPService.setFileToServer(e.name, e)))
      return
    }
    const trabajoIndexedDb = await this.selectTrabajoLocal(user, idTrabajo)
    trabajoIndexedDb.dataSend.archivos = [...trabajoIndexedDb.dataSend.archivos, ...files.map(e => ({ File: e }))]
    await this.dbLocal.updateByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, value: trabajoIndexedDb })
  }

  async updateTrabajos(params: DtoRequestUpdateTrabajo, user: EntityDataUsuario): Promise<DtoTrabajosDE> {

    const url: string = `${this.urlBase}${AdapterConfigure.UPDATE_TRABAJOS}`

    const trabajoIndexedLocal = await this.selectTrabajoLocal(user, params.Body.ID_Trabajo)

    let response: Array<any> = navigator.onLine ?
      await this.service.call<any>('POST', url, JSON.stringify(params), 'bearer', 'json', 'json', {}) :
      await this._saveLocalTrabajo(params)

    response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response

    const trabajoActualizado = response.filter(e => e.ID_Trabajo && e.Ultima_PreLiquidacion && e.PartesDiarios && e.ID_Trabajo === params.Body.ID_Trabajo)
    if (trabajoActualizado.length !== 1) throw Error(`Error al obtener trabajo actualizado`)

    if (trabajoIndexedLocal) {
      const trabajoUpdated: DtoTrabajosDE = {
        ColeccionObras: trabajoIndexedLocal.ColeccionObras,
        ColeccionReserva: trabajoIndexedLocal.ColeccionReserva,
        ColeccionManoObra: trabajoIndexedLocal.ColeccionManoObra,
        ColeccionAddressList: trabajoIndexedLocal.ColeccionAddressList,
        ...trabajoActualizado[0]
      }
      await this.dbLocal.updateByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, value: trabajoUpdated })
      await this.updateStockPersonal(trabajoUpdated, response, user)
    }
    await this.updateStockAlmacen(response, user)
    await this.updateConsumoMaterial(response, user)

    return await this.dbLocal.selectByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`, value: params.Body.ID_Trabajo })
  }


  private async _saveLocalTrabajo(params: DtoRequestUpdateTrabajo): Promise<any> {
    const StatusOffline = { Estado: 'No Enviado' }

    let response = { ...params.Body, StatusOffline: StatusOffline, dataSend: { params, archivos: [] } }
    this.dispatch(addCountProcess())

    return [response]
  }


  private async updateStockPersonal(trabajo: DtoTrabajosDE, response: any[], user: EntityDataUsuario): Promise<any> {
    const stocks = response.filter(e => e.ID_StockPersonal && e.Reserva)
    for await (const stock of stocks) {
      await this.dbLocal.updateByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`, value: stock })
    }
    if (stocks.length) return
    // Traer todos los trabajos de IndexedDB
    let trabajosIndexedDB: DtoTrabajosDE[] = await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_Trabajos`)
    // Filtrar los trabajos con mismo ID_GOM
    trabajosIndexedDB = trabajosIndexedDB.filter(e => e.ID_GOM === trabajo.ID_GOM)
    // Sacar Resumen
    const Resumen = CalcularResumenLiquidaciones(trabajo, trabajosIndexedDB)
    // Actualizar Los Stock segun resumen MatStockPersonal
    let StockPersonalIndexedDB: DtoResponseSelectStockPersonal[] = await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`)
    StockPersonalIndexedDB = StockPersonalIndexedDB.filter(e => e.FlagPex === 1)
    StockPersonalIndexedDB = StockPersonalIndexedDB.filter(e => Resumen.MatStockPersonal.find(el => el.ID_Trabajo === e.ID_Trabajo && el.Codigo === e.Reserva))
    StockPersonalIndexedDB = StockPersonalIndexedDB.map(e => ({ ...e, Personal: { Identificacion: user.Identificacion } }))
    ActualizarDataResumenLiquidaciones(null, null, StockPersonalIndexedDB, Resumen)
    for await (const stockPersonal of StockPersonalIndexedDB) {
      await this.dbLocal.updateByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`, value: stockPersonal })
    }
  }

  private async updateStockAlmacen(response: any[], user: EntityDataUsuario) {
    const stocksAlmacen = response.filter(e => e.ID_StockAlmacen)
    for await (const stock of stocksAlmacen) {
      await this.dbLocal.updateByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockAlmacen`, value: stock })
    }
  }

  private async updateConsumoMaterial(response: any[], user: EntityDataUsuario) {
    const data: any[] = response.reduce((acc, el) => {
      if (Array.isArray(el)) return [...acc, ...el]
      else return [...acc, el]
    }, [])
    const consumoMaterial: DtoConsumoMaterial[] = data.filter(e => e.ID_ConsumoMaterial)
    for await (const item of consumoMaterial) {
      await this.dbLocal.updateByIndexStore({ nameStore: `${user.Pais.Nombre}_${user.Delegacion.Codigo}_ConsumoMaterial`, value: item })
    }
  }

  public async selectStockPersonal(user: EntityDataUsuario): Promise<DtoResponseSelectStockPersonal[]> {
    const result: DtoResponseSelectStockPersonal[] = await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockPersonal`)
    return result.filter(e => e.FlagPex === 1)
  }

  public async selectStockAlmacen(user: EntityDataUsuario): Promise<DtoStockAlmacen[]> {
    return await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_StockAlmacen`)
  }

  public async selectConsumoMaterial(user: EntityDataUsuario): Promise<DtoConsumoMaterial[]> {
    return await this.dbLocal.selectAllStore(`${user.Pais.Nombre}_${user.Delegacion.Codigo}_ConsumoMaterial`)
  }

  public async selectItems(): Promise<any[]> {
    return await this.dbLocal.selectAllStore('Items')
  }

}