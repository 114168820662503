import { DtoItems } from '../../../../../../app/Domain/DtoItems'
import { DtoResponseSelectStockPersonal } from '../../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { DtoAmap } from '../../../../Home/Domain/DtoAmap'
import { DtoTrabajosDE } from '../../../Lista/Domain/DtoTrabajosDE'
import { DtoDataAddedValorizaciones } from '../../../Shared/Preliquidar/Interfaces/DtoDataAddedValorizaciones'
import { DtoDataSelectAsignaciones } from '../../Domain/DtoDataSelectAsignaciones'
import { typeMaterial } from '../../Domain/types'
import {
  CalcularMOUtilizado,
  GenerarDtoAsignacionValorizacion,
  generarDataMaterial,
  generarDataMaterialAlmacen,
} from './Processes'
import { GenerarDtoItem } from './Processes/GenerarDtoItem'
import { DtoPrecioMaterial } from '../../../../../../app/Domain/DtoPrecioMaterial'
import { DtoStockAlmacen } from '../../../../../Master/Home/Domain/DtoStockAlmacen'
import { DtoMaterialesUtiRet } from '../../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { CalcularResumenLiquidaciones, codigosMOT1_F2 } from 'sigo-package'
import { DtoConsumoMaterial } from '../../../../../Master/Home/Domain/DtoConsumoMaterial'

class UseCasePreLiquidationProcesses {

  getDataAsignaciones(trabajo: DtoTrabajosDE): DtoDataSelectAsignaciones[] {

    const { MoUo } = CalcularResumenLiquidaciones(trabajo, [trabajo])

    const data = trabajo.Ultima_asignacion.Valorizaciones
      .filter(val => !codigosMOT1_F2.includes(val.ManoObra.Codigo))
      .reduce((acc: DtoDataSelectAsignaciones[], val): DtoDataSelectAsignaciones[] => {
        if (val.Estado.ID_Estado !== 1) return acc
        const cantidadMOUtilizado = CalcularMOUtilizado(val.LineaCodigoCub, MoUo)
        return [
          ...acc,
          {
            CantidadMODisponible: val.CantidadMO - cantidadMOUtilizado,
            ...val
          }
        ]
      }, [])
    return data
  }

  generarDtoAsignacionValorizacion(amap: DtoAmap, cantidad: number) {
    return GenerarDtoAsignacionValorizacion(amap, cantidad)
  }

  generarDtoItem(item: DtoItems, precioMaterial: DtoPrecioMaterial, cantidad: number) {
    return GenerarDtoItem(item, precioMaterial, cantidad)
  }

  generarDataMaterial(dataItems: DtoItems[], dataStockPersonal: DtoResponseSelectStockPersonal[], typeMaterial: typeMaterial, valorizaciones: DtoDataAddedValorizaciones[]) {
    return generarDataMaterial(dataItems, dataStockPersonal, typeMaterial, valorizaciones)
  }

  generarDataMaterialAlmacen(dataStockAlmacen: DtoStockAlmacen[], dataConsumoMaterial: DtoConsumoMaterial[], valorizaciones: DtoDataAddedValorizaciones[], materialesInstalado: DtoMaterialesUtiRet[]) {
    return generarDataMaterialAlmacen(dataStockAlmacen, dataConsumoMaterial, valorizaciones, materialesInstalado)
  }

}

export const PreLiquidationProcess = new UseCasePreLiquidationProcesses()