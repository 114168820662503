import { Container } from '../../../../shared/Components/Container/Container'
import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { AdapterConfigure } from '../Infraestructure/AdapterConfigure'
import { DtoHPList, DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { Button, Tab, Tabs } from 'react-bootstrap'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ElementItemCardGenerico } from '../../../../shared/Components/Programados/ElementItemCardGenerico'
import { Documentacion } from './Documentacion'
import { DtoDocumentacionBaremoPEX, DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { typeHPList } from '../Domain/typeHPList'
import { obtenerDocumentacionNoClientes, obtenerDocumentacionClientes, codMODROPAL04, codMOFTTFAL04 } from 'sigo-package'
import { InputSearch } from '../../../../shared/Components/ReactBootstrap/InputSearch/InputSearch'
import { TabCalidad } from './TabCalidad'
import { DtoDocumentacionPEX } from '../../../../../app/Domain/DtoDocumentacionPEX'

// Preliquidar
import { DtoDataSelectAsignaciones } from '../../Preliquidar/Domain/DtoDataSelectAsignaciones'
import { DtoDataAddedValorizaciones } from '../../Shared/Preliquidar/Interfaces/DtoDataAddedValorizaciones'
import { DtoInitialValues } from '../../Shared'
import { FormikHelpers } from 'formik'
import { DtoMaterialesUtiRet } from '../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import AddedReservaMO from '../../Preliquidar/Components/AddedReservaMO'
import { DtoPrecioEspecialidad } from '../../../../../app/Domain/DtoPrecioEspecialidad'
import { DtoInitialValuesAddMO } from '../../Shared/Preliquidar/Interfaces/DtoInitialValuesAddMO'
import { DtoDataMateriales } from '../../Preliquidar/Domain/DtoDataMateriales'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
import { typeEstadosAddressID } from '../../Shared/Domain'

interface props {
  trabajo: DtoTrabajosDE | null
  onSave: (
    values: DtoInitialValues,
    formikHelpers: FormikHelpers<DtoInitialValues> | null,
    materialesInstalado: DtoMaterialesUtiRet[],
    materialesRetirado: DtoMaterialesUtiRet[],
    nodos: DtoNodoDoc[],
    typeHPList: typeHPList,
    Home_ID: string,
    ID_AddressList: number,
    newFiles: File[],
    valRechazadaXMetraje: DtoValorizacion | null
  ) => Promise<boolean>
  dataDocumentacionPEX: DtoDocumentacionPEX | undefined
  onSaveTabCalidad: (nodos: DtoNodoDoc[], newFiles: File[]) => Promise<void>
  asignaciones: DtoDataSelectAsignaciones[]
  valorizaciones: DtoDataAddedValorizaciones[]
  onSubmit: (
    values: DtoInitialValues,
    formikHelpers: FormikHelpers<DtoInitialValues>,
    materialesInstalado: DtoMaterialesUtiRet[],
    materialesRetirado: DtoMaterialesUtiRet[]
  ) => void | Promise<any>
  setDocumentacionValorizacion: Dispatch<SetStateAction<DtoNodoDoc[]>>
  documentacionValorizacion: DtoNodoDoc[],
  setNewFilesUpload: Dispatch<SetStateAction<File[]>>
  newFilesUpload: File[]
  dataDocumentacionBaremoPEX: DtoDocumentacionBaremoPEX[]
  onChange: (name: string, value: any, materialesInstalado: DtoMaterialesUtiRet[]) => { dataAlmacen: DtoDataMateriales[], dataMateriales: DtoDataMateriales[] } | undefined
  dataManoObraGlobal: DtoManoObraGlobal[]
  dataMateriales: DtoDataMateriales[]
  dataPrecioEspecialidad: DtoPrecioEspecialidad[]
  onSubmitFormAddMO: (values: DtoInitialValuesAddMO, formikHelpers: FormikHelpers<DtoInitialValuesAddMO>) => void | Promise<any>
  dataMaterialesAlmacen: DtoDataMateriales[]
  cambiarEstadoAddresID: (typeHPList: typeHPList, Home_ID: string, ID_AddressList: number, campo: typeEstadosAddressID) => Promise<void>
}

type typeColorFondo = '#df3b47' | '#ffc415' | '#00a844'
type typeColorText = 'white' | 'black'

export const ViewMain = (props: props) => {

  const {
    trabajo,
    onSave,
    dataDocumentacionPEX,
    onSaveTabCalidad,
    asignaciones,
    valorizaciones,
    onSubmit,
    documentacionValorizacion,
    setDocumentacionValorizacion,
    newFilesUpload,
    setNewFilesUpload,
    dataDocumentacionBaremoPEX,
    onChange,
    dataManoObraGlobal,
    dataMateriales,
    dataPrecioEspecialidad,
    onSubmitFormAddMO,
    dataMaterialesAlmacen,
    cambiarEstadoAddresID
  } = props
  const langTranslate = LanguageTranslate()
  const [dataResidential, setDataResidential] = useState<DtoHPList[]>([])
  const [dataBusiness, setDataBusiness] = useState<DtoHPList[]>([])
  const [updateHP, setUpdateHP] = useState<DtoHPList>(new DtoHPList())
  const [showOffCanvas, setShowOffCanvas] = useState<boolean>(false)
  const [typeHPList, setTypeHPList] = useState<typeHPList>('Residential')
  const [home_ID, setHome_ID] = useState<string>('')
  const [ID_AddressList, setID_AddressList] = useState<number>(0)
  const [newFiles, setNewFiles] = useState<File[]>([])
  const [searchTermDataResidential, setSearchTermDataResidential] = useState<string>('')
  const [searchTermDataBusiness, setSearchTermDataBusiness] = useState<string>('')
  const [codEspecialidades, setCodEspecialidades] = useState<string[]>([])
  const [docNoClientes, setDocNoClientes] = useState<DtoNodoDoc[]>([])
  const [docClientes, setDocClientes] = useState<DtoNodoDoc[]>([])
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (trabajo) {

      //#region DataResidential (Clientes)
      const dataResidential = trabajo.ColeccionAddressList.reduce((acc: DtoHPList[], al) => {
        return [
          ...acc,
          ...al.ResidentialHPList
            .filter(el => el.Asignaciones.map(e => e.Empresa).includes(trabajo.Ultima_asignacion.Empresa.Codigo))
            .map(e => ({ ...e, ID_AddressList: al.ID_AddressList, AddressID: al.AddressID }))
        ]
      }, [])
      const dataResidentialGroup: DtoHPList[][] = Object.values(dataResidential.reduce((acc: { [key: string]: DtoHPList[] }, obj) => {
        const key = obj.AddressID || ''
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {}))
      // Ordenar los subarrays por Home_ID
      dataResidentialGroup.forEach(subarray => {
        subarray.sort((a, b) => a.Home_ID.localeCompare(b.Home_ID))
      })
      const dataClientes = dataResidentialGroup.map(subarray => subarray[0])
      setDataResidential(dataClientes)
      //#endregion DataResidential

      //#region DataBusiness (No Clientes)
      const dataBusiness = trabajo.ColeccionAddressList.reduce((acc: DtoHPList[], al) => {
        return [
          ...acc,
          ...al.BusinessHPList
            .filter(e => e.Asignaciones.map(el => el.Empresa).includes(trabajo.Ultima_asignacion.Empresa.Codigo))
            .map(e => ({ ...e, ID_AddressList: al.ID_AddressList, AddressID: al.AddressID }))
        ]
      }, [])
      const dataBusinessGroup: DtoHPList[][] = Object.values(dataBusiness.reduce((acc: { [key: string]: DtoHPList[] }, obj) => {
        const key = obj.AddressID || ''
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {}))
      // Ordenar los subarrays por Home_ID
      dataBusinessGroup.forEach(subarray => {
        subarray.sort((a, b) => a.Home_ID.localeCompare(b.Home_ID))
      })
      const dataNoClientesAux = dataBusinessGroup.map(subarray => subarray[0])
      const clientesAddressIDs = new Set(dataClientes.map(e => e.AddressID))
      const dataNoClientes = dataNoClientesAux.filter(e => !clientesAddressIDs.has(e.AddressID))
      setDataBusiness(dataNoClientes)
      //#endregion DataBusiness

      const codsEspecialidades = trabajo.Ultima_asignacion.Valorizaciones.map(e => e.Especialidad.Codigo)
      setCodEspecialidades(codsEspecialidades)
      setDocNoClientes(obtenerDocumentacionNoClientes({ codEspecialidades: codsEspecialidades }))
      setDocClientes(obtenerDocumentacionClientes({ codEspecialidades: codsEspecialidades }).Nodos)
    }
  }, [trabajo, onSave])

  const onClick = (Home_ID: string, tipo: typeHPList, ID_AddressList: number) => {
    try {
      const dataAux = tipo === 'Business' ? dataBusiness : dataResidential
      const HP = dataAux.find(e => e.Home_ID === Home_ID)
      if (!HP) throw Error(`No se pudo consultar Home Pass! Comunícate con Soporte`)
      setUpdateHP(HP)
      setTypeHPList(tipo)
      setShowOffCanvas(true)
      setHome_ID(Home_ID)
      setID_AddressList(ID_AddressList)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Container>
        <ElementBreadCrumb
          list={[
            {
              text: langTranslate.moduloMain.textoProgramados,
              navigate: true,
              path: `${AdapterConfigure.ROUTE_PROGRAMADOS}`,
            },
            {
              text: langTranslate.menu['004'].titulo,
              navigate: true,
              path: `${AdapterConfigure.ROUTE_TRABAJOS}`,
            },
            {
              text: trabajo?.ColeccionObras[0].OrdenTrabajo || '',
              navigate: false,
              path: '',
            },
          ]}
        />
        <Tabs
          defaultActiveKey='Residential'
          id='fill-tab-example'
          fill
        >
          <Tab className='mt-3' eventKey='Residential' title={langTranslate.general.Clientes}>
            <InputSearch handleChange={(text) => setSearchTermDataResidential(text)} textLength={1} />
            {
              dataResidential
                .filter(e =>
                  `${e.Street} ${e.HouseNumber} ${e.HouseNumberAffix}`
                    .toLowerCase()
                    .includes(searchTermDataResidential.toLowerCase())
                ).map((e, i) => {
                  const { backgroundColor, color } = definirColorFondo(e, docClientes, undefined, trabajo)
                  const { OC, Asfaltado, Soplado } = e
                  const disabled_oc = OC ? OC.Estado : true
                  let disabled_sop = Soplado ? Soplado.Estado : true
                  let disabled_asph = Asfaltado ? Asfaltado.Estado : true
                  return <ElementItemCardGenerico
                    backgroundColor={backgroundColor}
                    key={i}
                    data1={<span style={{ color }}>{e.AddressID}</span>}
                    text1={<span style={{ color }}>{langTranslate.general.AddressID}</span>}
                    data3={<span style={{ color }}>{`${e.Street} ${e.HouseNumber} ${e.HouseNumberAffix}`}</span>}
                    text3={<span style={{ color }}>{langTranslate.general.Direccion}</span>}
                    icono
                    colorIcono1='dark'
                    icono1='fa-regular fa-folder-open'
                    onClick={() => onClick(e.Home_ID, 'Residential', e.ID_AddressList || 0)}
                    buttonsBottom={[
                      <Button
                        key='OC'
                        disabled={disabled_oc}
                        onClick={async () => await cambiarEstadoAddresID('Residential', e.Home_ID, e.ID_AddressList ?? 0, 'OC')}
                      >
                        OC
                      </Button>,
                      <Button
                        key='SOP'
                        disabled={disabled_sop}
                        onClick={async () => await cambiarEstadoAddresID('Residential', e.Home_ID, e.ID_AddressList ?? 0, 'SOP')}
                      >
                        SOP
                      </Button>,
                      <Button
                        key='ASPH'
                        disabled={disabled_asph}
                        onClick={async () => await cambiarEstadoAddresID('Residential', e.Home_ID, e.ID_AddressList ?? 0, 'ASPH')}
                      >
                        ASPH
                      </Button>
                    ]}
                  />
                })
            }
          </Tab>
          <Tab className='mt-3' eventKey='Business' title={langTranslate.general.NoClientes}>
            <InputSearch handleChange={(text) => setSearchTermDataBusiness(text)} textLength={1} />
            {
              dataBusiness
                .filter(e =>
                  `${e.Street} ${e.HouseNumber} ${e.HouseNumberAffix}`
                    .toLowerCase()
                    .includes(searchTermDataBusiness.toLowerCase())
                ).map((e, i) => {
                  const { backgroundColor, color } = definirColorFondo(e, docNoClientes, undefined, trabajo)
                  const { OC, Asfaltado, Soplado } = e
                  const disabled_oc = OC ? OC.Estado : true
                  let disabled_sop = Soplado ? Soplado.Estado : true
                  let disabled_asph = Asfaltado ? Asfaltado.Estado : true
                  return <ElementItemCardGenerico
                    backgroundColor={backgroundColor}
                    key={i}
                    data1={<span style={{ color }}>{e.AddressID}</span>}
                    text1={<span style={{ color }}>{langTranslate.general.AddressID}</span>}
                    data3={<span style={{ color }}>{`${e.Street} ${e.HouseNumber} ${e.HouseNumberAffix}`}</span>}
                    text3={<span style={{ color }}>{langTranslate.general.Direccion}</span>}
                    icono
                    colorIcono1='dark'
                    icono1='fa-regular fa-folder-open'
                    onClick={() => onClick(e.Home_ID, 'Business', e.ID_AddressList || 0)}
                    buttonsBottom={[
                      <Button
                        key='OC'
                        disabled={disabled_oc}
                        onClick={async () => await cambiarEstadoAddresID('Business', e.Home_ID, e.ID_AddressList ?? 0, 'OC')}
                      >
                        OC
                      </Button>,
                      <Button
                        key='SOP'
                        disabled={disabled_sop}
                        onClick={async () => await cambiarEstadoAddresID('Business', e.Home_ID, e.ID_AddressList ?? 0, 'SOP')}
                      >
                        SOP
                      </Button>,
                      <Button
                        key='ASPH'
                        disabled={disabled_asph}
                        onClick={async () => await cambiarEstadoAddresID('Business', e.Home_ID, e.ID_AddressList ?? 0, 'ASPH')}
                      >
                        ASPH
                      </Button>
                    ]}
                  />
                })
            }
          </Tab>
          <Tab className='mt-3 mb-2' eventKey={'Calidad'} title={langTranslate.general.Calidad}>
            <TabCalidad
              dataDocumentacionPEX={dataDocumentacionPEX}
              onSave={onSaveTabCalidad}
              trabajo={trabajo}
              dataBusiness={dataBusiness}
              dataResidential={dataResidential}
            />
          </Tab>
        </Tabs>
      </Container>
      <Documentacion
        setShowOffCanvas={setShowOffCanvas}
        showOffCanvas={showOffCanvas}
        typeHPList={typeHPList}
        onSave={async (
          values,
          formikHelpers,
          materialesInstalado,
          materialesRetirado,
          nodos,
          valRechazadaXMetraje
        ) => await onSave(
          values,
          formikHelpers,
          materialesInstalado,
          materialesRetirado,
          nodos,
          typeHPList,
          home_ID,
          ID_AddressList,
          newFiles,
          valRechazadaXMetraje
        )}
        updateHP={updateHP}
        newFiles={newFiles}
        setNewFiles={setNewFiles}
        ruta={`AddressList_${ID_AddressList}`}
        home_ID={home_ID}
        codEspecialidades={codEspecialidades}
        asignaciones={
          asignaciones.filter(e => ![
            updateHP.Asfaltado,
            updateHP.OC,
            updateHP.Soplado
          ].filter((e, i, arr) => arr[1].Estado && e.Estado)
            .map(ele => ele.Especialidad)
            .includes(e.Especialidad.Codigo)
          )
        }
        valorizaciones={valorizaciones}
        onSubmit={onSubmit}
        documentacionValorizacion={documentacionValorizacion}
        setDocumentacionValorizacion={setDocumentacionValorizacion}
        newFilesUpload={newFilesUpload}
        setNewFilesUpload={setNewFilesUpload}
        dataDocumentacionBaremoPEX={dataDocumentacionBaremoPEX}
        trabajo={trabajo}
        onChange={onChange}
        dataManoObraGlobal={dataManoObraGlobal}
        dataMateriales={dataMateriales}
        ID_AddressList={ID_AddressList}
        Home_ID={home_ID}
        setShowModal={setShowModal}
        showModal={showModal}
        dataMaterialesAlmacen={dataMaterialesAlmacen}
      />
      <AddedReservaMO
        show={showModal}
        setShow={setShowModal}
        dataManoObraGlobal={dataManoObraGlobal
          .filter(e => (typeHPList === 'Business' //Business -> No Clientes
            ? codMOFTTFAL04
            : codMODROPAL04
          ).includes(e.Codigo))
        }
        dataPrecioEspecialidad={dataPrecioEspecialidad}
        trabajo={trabajo}
        onSubmitFormAddMO={onSubmitFormAddMO}
        type={typeHPList}
        updateHP={updateHP}
      />
    </>
  )
}

const definirColorFondo = (
  e: DtoHPList,
  nodo: DtoNodoDoc[],
  color: { backgroundColor: typeColorFondo, color: typeColorText } = { backgroundColor: '#df3b47', color: 'white' },
  trabajo: DtoTrabajosDE | null
): { backgroundColor: typeColorFondo, color: typeColorText } => {
  const tieneHijos = nodo.some(n => n.Children.length > 0);

  const asignacion = e.Asignaciones.find(e => e.Empresa === trabajo?.Ultima_asignacion.Empresa.Codigo)

  if (!asignacion) throw Error(`¡No se pudo obtener asignación!`)

  const sizes: number[] = nodo.map(n => asignacion.Anexos.filter(a => a.CodigoNodo === n.Codigo && a.Estado.ID_Estado === 1).length)
  // const todosSonCero = sizes.every(size => size === 0)
  const alMenosUnoMayorA0 = sizes.some(size => size > 0)
  const todosMayoresA0 = sizes.every(size => size > 0)

  // #00a844 Verde
  // #ffc415 Amarillo
  // #df3b47 Rojo

  if (tieneHijos) {
    return nodo.reduce((color, n) => {
      color = definirColorFondo(e, n.Children, color, trabajo)
      return color
    }, color)
  } else if (todosMayoresA0 && color.backgroundColor !== '#ffc415') {
    return { backgroundColor: '#00a844', color: 'white' };
  } else if ((alMenosUnoMayorA0 || ['#ffc415', '#00a844'].includes(color.backgroundColor))) {
    return { backgroundColor: '#ffc415', color: 'black' }
  } else {
    return { backgroundColor: '#df3b47', color: 'white' }
  }
}
