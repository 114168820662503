import React, { useEffect, useRef } from 'react'
import { BrowserMultiFormatReader, IScannerControls, BarcodeFormat } from '@zxing/browser'
import { DecodeHintType } from '@zxing/library'

interface ZXingScannerProps {
    onDetected: (code: string) => void
}

export const ZXingScanner: React.FC<ZXingScannerProps> = ({ onDetected }) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const scannerControls = useRef<IScannerControls | null>(null)

    useEffect(() => {
        const hints = new Map()
        hints.set(DecodeHintType.TRY_HARDER, true)
        hints.set(DecodeHintType.POSSIBLE_FORMATS, [
            BarcodeFormat.CODE_128
        ])
        // hints.set(DecodeHintType.ALLOWED_LENGTHS, [8, 12, 13]);
        const codeReader = new BrowserMultiFormatReader(hints, { delayBetweenScanSuccess: 500, delayBetweenScanAttempts: 150 })
        const videoElement = videoRef.current

        const startScanner = async () => {
            try {
                const devices = await BrowserMultiFormatReader.listVideoInputDevices()
                if (devices.length === 0) {
                    console.error('No se encontraron cámaras.')
                    return
                }

                const backCamera = devices.find((device) =>
                    device.label.toLowerCase().includes('back')
                ) || devices[devices.length - 1]

                const selectedDeviceId = backCamera.deviceId;

                // Iniciar el escáner
                scannerControls.current = await codeReader.decodeFromVideoDevice(
                    selectedDeviceId,
                    videoRef.current!,
                    (result) => {
                        if (result) {
                            console.log('Código detectado:', result.getText())
                            onDetected(result.getText())
                        }
                    }
                )
            } catch (err) {
                console.error('Error al iniciar el escáner:', err)
            }
        }

        startScanner()

        return () => {
            // Detener escaneo y liberar la cámara al desmontar
            if (scannerControls.current) {
                scannerControls.current.stop()
            }
            if (videoElement && videoElement.srcObject) {
                const stream = videoElement.srcObject as MediaStream
                stream.getTracks().forEach((track) => track.stop())
                videoElement.srcObject = null
            }
        }
    }, [])


    return (
        <div style={containerStyle}>
            <div style={scannerAreaStyle}>
                <video ref={videoRef} style={videoStyle} />
            </div>
        </div>
    )
}

// Estilos para el contenedor del video y área de escaneo
const containerStyle: React.CSSProperties = {
    position: 'relative',
    width: '100%',
    height: 150,  // O cualquier altura que desees
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const scannerAreaStyle: React.CSSProperties = {
    position: 'absolute',
    // top: '20%', // Puedes ajustar esta posición
    // left: '10%',
    width: '100%', // Controla el ancho de la zona de escaneo
    height: '100%', // Relación de 5:2 -> 2/5 = 0.4 -> 0.4 * 80% (ancho)
    border: '2px solid rgba(255, 17, 17, 0.8)', // Contorno para mostrar la zona de escaneo
    borderRadius: '8px',
    background: 'rgba(0, 0, 0, 0.3)', // Fondo semi-transparente para oscurecer el área fuera de la zona de escaneo
}

const videoStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Para cubrir todo el área del contenedor
}