import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoHPList, DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { DtoReqUpdateAddressList } from '../../../../shared/Domain/Dto/DtoReqUpdateAddressList'
import { RepositoryMain } from '../Domain/RepositoryMain'
import { typeHPList } from '../Domain/typeHPList'
import { typeEstadosAddressID } from '../../Shared/Domain'

export class UseCaseChangeAddressState {
  private repository: RepositoryMain
  private user: EntityDataUsuario

  constructor(_repository: RepositoryMain, _user: EntityDataUsuario) {
    this.repository = _repository
    this.user = _user
  }

  public async exec(trabajo: DtoTrabajosDE, typeHPList: typeHPList, Home_ID: string, ID_AddressList: number, campo: typeEstadosAddressID) {
    return await this._exec(trabajo, typeHPList, Home_ID, ID_AddressList, campo)
  }

  private async _exec(trabajo: DtoTrabajosDE, typeHPList: typeHPList, Home_ID: string, ID_AddressList: number, campo: typeEstadosAddressID) {

    // Buscar AddressList a actualizar
    const updatedAddressList = trabajo.ColeccionAddressList.filter(e => e.ID_AddressList === ID_AddressList)
    if (updatedAddressList.length !== 1) throw Error(`¡No se pudo Obtener AddressList! Comunícate con Soporte`)

    // Determinar tIpo de listas de casa y obtener la casa por su id
    const HPListUpdate = typeHPList === 'Business'
      ? updatedAddressList[0].BusinessHPList.find(e => e.Home_ID === Home_ID)
      : updatedAddressList[0].ResidentialHPList.find(e => e.Home_ID === Home_ID)
    if (!HPListUpdate) throw Error(`¡No se pudo actualizar Address List! Comunícate con Soporte`)

    this.updateAddressState(HPListUpdate, campo)

    // Construir Body
    const body: DtoReqUpdateAddressList = {
      Header: { Coleccion: { Codigo: this.user.Delegacion.Codigo, Nombre: this.user.Pais.Nombre } },
      Body: updatedAddressList
    }

    const response = await this.repository.updateAddressState(body)

    const indexToUpdate = trabajo.ColeccionAddressList.findIndex(al => al.ID_AddressList === response[0].ID_AddressList)
    if (indexToUpdate !== -1) trabajo.ColeccionAddressList.splice(indexToUpdate, 1, response[0])

    return await this.repository.updateTrabajoLocal(trabajo, this.user)

  }

  private updateAddressState(HPListUpdate: DtoHPList, campo: typeEstadosAddressID) {
    if (campo === 'OC') {
      HPListUpdate.OC.Estado = true
      HPListUpdate.Asfaltado.Estado = false
      HPListUpdate.Soplado.Estado = false
    }
    if (campo === 'ASPH') {
      HPListUpdate.OC.Estado = true
      HPListUpdate.Asfaltado.Estado = true
    }
    if (campo === 'SOP') {
      HPListUpdate.OC.Estado = true
      HPListUpdate.Soplado.Estado = true
    }
  }

}