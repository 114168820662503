import { useEffect, useState } from 'react'
import { DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { addLoading, removeLoading } from '../../../../shared/Infraestructure/SliceGenerico'
import { UseCaseSelectTrabajo } from '../Application/UseCaseSelectTrabajo'
import { useSelector } from 'react-redux'
import { RepositoryImplMain } from './RepositoryImplMain'
import { RepositoryImplMain as RepositoryImplMainPreliquidar } from '../../Preliquidar/Infraestructure/RepositoryImplMain'
import { AdapterConfigure } from './AdapterConfigure'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { DtoDocumentacionBaremoPEX, DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { typeHPList } from '../Domain/typeHPList'
import { UseCaseUpdateAddressList } from '../Application/UseCaseUpdateAddressList'
import { UseCaseLoadData } from '../Application/UseCaseLoadData'
import { DtoDocumentacionPEX } from '../../../../../app/Domain/DtoDocumentacionPEX'
import { UseCaseUpdateObra } from '../Application/UseCaseUpdateObra'
import { UseCaseProcesses } from '../Application/UseCaseProcesses'
// Preliquidacion
import { DtoDataSelectAsignaciones } from '../../Preliquidar/Domain/DtoDataSelectAsignaciones'
import { PreLiquidationProcess } from '../../Preliquidar/Application/UseCasePreLiquidationProcesses/UseCasePreLiquidationProcesses'
import { DtoDataAddedValorizaciones } from '../../Shared/Preliquidar/Interfaces/DtoDataAddedValorizaciones'
import { DtoInitialValues, onChangeShared, onSubmitFormAddMOShared, onSubmitShared } from '../../Shared'
import { FormikHelpers } from 'formik'
import { DtoMaterialesUtiRet } from '../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { DtoDataMateriales } from '../../Preliquidar/Domain/DtoDataMateriales'
import { DtoItems } from '../../../../../app/Domain/DtoItems'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { DtoManoObraGlobal } from '../../../../../app/Domain/DtoManoObraGlobal'
import { UseCasePreliquidarDocHP } from '../Application/UseCasePreliquidar'
import { DtoResponseEstadoInterno } from '../../../../../app/Domain/DtoResponseEstadoInterno'
import { DtoPrecioEspecialidad } from '../../../../../app/Domain/DtoPrecioEspecialidad'
import { DtoInitialValuesAddMO } from '../../Shared/Preliquidar/Interfaces/DtoInitialValuesAddMO'
import { DtoPrecioMaterial } from '../../../../../app/Domain/DtoPrecioMaterial'
import { DtoStockAlmacen } from '../../../../Master/Home/Domain/DtoStockAlmacen'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
import { DtoConsumoMaterial } from '../../../../Master/Home/Domain/DtoConsumoMaterial'
import { UseCaseChangeAddressState } from '../Application/UseCaseChangeAddressState'
import { typeEstadosAddressID } from '../../Shared/Domain'

export const Controller = () => {

  const dispatch: Dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const navigate: NavigateFunction = useNavigate()
  const currentPath = useLocation().pathname
  const lastPath = currentPath.replace(/\/preliquidar\/\d+$/, '')
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
  const { user, userGestor } = useSelector((state: RootState) => state.auth)
  const repository = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY)
  const repositoryPreliquidar = new RepositoryImplMainPreliquidar(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY)
  const [trabajo, setTrabajo] = useState<DtoTrabajosDE | null>(null)
  const [dataDocumentacionPEX, setDataDocumentacionPEX] = useState<DtoDocumentacionPEX>()

  //#region Preliquidacion
  const [asignaciones, setAsignaciones] = useState<DtoDataSelectAsignaciones[]>([])
  const [valorizaciones, setValorizaciones] = useState<DtoDataAddedValorizaciones[]>([])
  const [documentacionValorizacion, setDocumentacionValorizacion] = useState<DtoNodoDoc[]>([])
  const [newFilesUpload, setNewFilesUpload] = useState<File[]>([])
  const [dataDocumentacionBaremoPEX, setDocumentacionBaremoPEX] = useState<DtoDocumentacionBaremoPEX[]>([])
  const [dataMateriales, setDataMateriales] = useState<DtoDataMateriales[]>([])
  const [dataItems, setDataItems] = useState<DtoItems[]>([])
  const [dataStockPersonal, setDataStockPersonal] = useState<DtoResponseSelectStockPersonal[]>([])
  const [dataManoObraGlobal, setDataManoObraGlobal] = useState<DtoManoObraGlobal[]>([])
  const [dataEstadoInterno, setDataEstadoInterno] = useState<DtoResponseEstadoInterno[]>([])
  const [dataPrecioEspecialidad, setDataPrecioEspecialidad] = useState<DtoPrecioEspecialidad[]>([])
  const [dataPrecioMaterial, setDataPrecioMaterial] = useState<DtoPrecioMaterial[]>([])
  const [dataStockAlmacen, setDataStockAlmacen] = useState<DtoStockAlmacen[]>([])
  const [dataMaterialesAlmacen, setDataMaterialesAlmacen] = useState<DtoDataMateriales[]>([])
  const [dataConsumoMaterial, setDataConsumoMaterial] = useState<DtoConsumoMaterial[]>([])
  //#endregion Preliquidacion

  const init = async () => {
    try {
      dispatch(addLoading({ textLoading: 'Cargando Trabajo ...' }))
      const trabajo = await new UseCaseSelectTrabajo(repository).exec(user, Number(id))
      const {
        DocumentacionPEX,
        DocumentacionBaremoPEX,
        ManoObraGlobal,
        dataItems,
        dataStockPersonal,
        EstadosInternos,
        PrecioEspecialidad,
        dataPrecioMaterial,
        dataStockAlmacen,
        dataConsumoMaterial
      } = await new UseCaseLoadData(repository).exec(user, trabajo)
      const documentacion = UseCaseProcesses.generarModeloDocZanja(DocumentacionPEX[0], trabajo)
      setDataDocumentacionPEX(documentacion)
      setDocumentacionBaremoPEX(DocumentacionBaremoPEX)
      setDataManoObraGlobal(ManoObraGlobal)
      setDataItems(dataItems)
      setDataEstadoInterno(EstadosInternos)
      setDataPrecioEspecialidad(PrecioEspecialidad)
      setDataStockPersonal(dataStockPersonal)
      setTrabajo(trabajo)
      setDataPrecioMaterial(dataPrecioMaterial)
      setDataStockAlmacen(dataStockAlmacen)
      setDataConsumoMaterial(dataConsumoMaterial)
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error').then(() => navigate(lastPath))
    } finally {
      dispatch(removeLoading())
    }
  }

  useEffect(() => {
    if (trabajo) {
      setAsignaciones(PreLiquidationProcess.getDataAsignaciones(trabajo))
      setDataManoObraGlobal((prev) => prev.filter(e => e.ContratoOT.Codigo === trabajo.ColeccionObras[0].ContratoOT.Codigo))
    }
  }, [trabajo])

  const onSave = async (
    values: DtoInitialValues,
    formikHelpers: FormikHelpers<DtoInitialValues> | null,
    materialesInstalado: DtoMaterialesUtiRet[],
    materialesRetirado: DtoMaterialesUtiRet[],
    nodos: DtoNodoDoc[],
    typeHPList: typeHPList,
    Home_ID: string,
    ID_AddressList: number,
    newFiles: File[],
    valRechazadaXMetraje: DtoValorizacion | null
  ): Promise<boolean> => {
    try {
      dispatch(addLoading({ textLoading: 'Actualizando ...' }))
      if (trabajo) {
        if (formikHelpers) {
          var valorizacionesAux = onSubmitShared(
            values,
            formikHelpers,
            materialesInstalado,
            materialesRetirado,
            documentacionValorizacion,
            setValorizaciones,
            setDocumentacionValorizacion,
            dataManoObraGlobal,
            dataStockPersonal,
            dataItems,
            valorizaciones
          )
        }
        if (newFiles.length) {
          await new UseCaseUpdateAddressList(repository, user, userGestor).exec(trabajo, nodos, typeHPList, Home_ID, ID_AddressList, newFiles)
        }
        if (formikHelpers) {
          await new UseCasePreliquidarDocHP(repositoryPreliquidar, true)
            .exec(
              user,
              userGestor,
              trabajo,
              [valorizacionesAux],
              dataEstadoInterno,
              dataManoObraGlobal,
              typeHPList,
              valRechazadaXMetraje ? valRechazadaXMetraje.ID_Valorizacion : null
            )
        }
        const trabajoAux = await new UseCaseSelectTrabajo(repository).exec(user, Number(id))
        setTrabajo(trabajoAux)
        AdapterGenerico.createMessage('¡Guardado!', '¡Actualizado correctamente!', 'success')
      }
      return true
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
      setValorizaciones([])
      return false
    } finally {
      dispatch(removeLoading())
    }
  }

  const onSaveTabCalidad = async (nodos: DtoNodoDoc[], newFiles: File[]) => {
    try {
      console.log(`Se subieron ${newFiles.length} archivos nuevos ⭐`)
      dispatch(addLoading({ textLoading: 'Actualizando ...' }))
      if (trabajo) {
        const response = await new UseCaseUpdateObra(repository, user, userGestor).exec(trabajo, nodos, newFiles)
        setTrabajo(response)
        AdapterGenerico.createMessage('¡Guardado!', '¡Actualizado correctamente!', 'success')
      }
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
    } finally {
      dispatch(removeLoading())
    }
  }

  //#region Preliquidacion
  const onSubmit = (
    values: DtoInitialValues,
    formikHelpers: FormikHelpers<DtoInitialValues>,
    materialesInstalado: DtoMaterialesUtiRet[],
    materialesRetirado: DtoMaterialesUtiRet[]
  ): void | Promise<any> => {
    try {
      onSubmitShared(
        values,
        formikHelpers,
        materialesInstalado,
        materialesRetirado,
        documentacionValorizacion,
        setValorizaciones,
        setDocumentacionValorizacion,
        dataManoObraGlobal,
        dataStockPersonal,
        dataItems,
        valorizaciones
      )
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
    }
  }
  const onChange = (name: string, value: any, materialesInstalado: DtoMaterialesUtiRet[]) => {
    try {
      return onChangeShared(
        name,
        value,
        setDataMateriales,
        setDataMaterialesAlmacen,
        dataItems,
        dataStockPersonal,
        dataStockAlmacen,
        valorizaciones,
        materialesInstalado,
        dataConsumoMaterial
      )
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
    }
  }
  const onSubmitFormAddMO = (values: DtoInitialValuesAddMO, formikHelpers: FormikHelpers<DtoInitialValuesAddMO>): void | Promise<any> => {
    try {
      onSubmitFormAddMOShared(
        values,
        formikHelpers,
        trabajo,
        setTrabajo,
        dataPrecioEspecialidad,
        dataPrecioMaterial,
        dataItems
      )
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
    }
  }
  //#endregion

  const cambiarEstadoAddresID = async (typeHPList: typeHPList, Home_ID: string, ID_AddressList: number, campo: typeEstadosAddressID) => {
    try {
      if (!trabajo) throw Error(`No se pudo obtener trabajo`)
      const mapa: { [key in typeEstadosAddressID]: string } = {
        ASPH: 'Asfaltado',
        OC: 'Obra Civil',
        SOP: 'Soplado'
      }
      const confirmacion = await AdapterGenerico.createMessage('Confirmar', `¿Estás seguro de cerrar ${mapa[campo]}?`, 'question', true)
      if (!confirmacion) return
      dispatch(addLoading({ textLoading: 'Actualizando ...' }))
      const trabajoAux = await new UseCaseChangeAddressState(repository, user).exec(trabajo, typeHPList, Home_ID, ID_AddressList, campo)
      setTrabajo(trabajoAux)
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error')
    } finally {
      dispatch(removeLoading())
    }
  }

  return {
    init,
    trabajo,
    onSave,
    dataDocumentacionPEX,
    onSaveTabCalidad,
    asignaciones,
    valorizaciones,
    onSubmit,
    newFilesUpload,
    setNewFilesUpload,
    documentacionValorizacion,
    setDocumentacionValorizacion,
    dataDocumentacionBaremoPEX,
    onChange,
    dataMateriales,
    dataManoObraGlobal,
    dataPrecioEspecialidad,
    onSubmitFormAddMO,
    dataMaterialesAlmacen,
    cambiarEstadoAddresID
  }
}