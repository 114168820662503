import { Dispatch } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import {
  addLoading,
  hideIconMenu,
  removeLoading,
} from '../../../../shared/Infraestructure/SliceGenerico'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { useEffect, useState } from 'react'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { UseCaseSelectTrabajo } from '../Application/UseCaseSelectTrabajo'
import { RepositoryImplMain } from './RepositoryImplMain'
import { AdapterConfigure } from './AdapterConfigure'
import { UseCaseProcesses } from '../Application/UseCaseProcesses'
import { DtoData } from '../Domain/DtoData'

export const Controller = () => {

  const dispatch: Dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const navigate: NavigateFunction = useNavigate()
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
  const { user } = useSelector((state: RootState) => state.auth)
  const repository = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY)
  const [trabajo, setTrabajo] = useState<DtoTrabajosDE | null>(null)
  const [data, setData] = useState<DtoData[]>([])
  const processes = new UseCaseProcesses()

  useEffect(() => {
    if (trabajo) {
      const data = processes.generarData(trabajo)
      setData(data)
    }
  }, [trabajo])

  const init = async () => {
    try {
      dispatch(hideIconMenu())
      dispatch(addLoading({ textLoading: 'Cargando presupuesto MO ...' }))
      const trabajo = await new UseCaseSelectTrabajo(repository).exec(user, Number(id))
      setTrabajo(trabajo)
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error').then(() => { navigate(-1) })
    } finally {
      dispatch(removeLoading())
    }
  }

  return {
    init,
    trabajo,
    data
  }
}
