import React, { FC, useEffect, useState } from 'react'
import { OffCanvasBottom } from '../../../../shared/Components/ReactBootstrap/OffCanvasBottom/OffCanvasBottom'
import { Adjuntos } from '../../../../shared/Components/Adjuntos/Adjuntos'
import { DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'
import { DtoHPList, DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { DtoHomePass } from '../../../Trabajos/Asignacion/Domain/DtoHomePassSimple'
import { obtenerDocumentacionClientes, obtenerDocumentacionNoClientes } from 'sigo-package'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { typeHPList } from '../../DocHP/Domain/typeHPList'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'

interface iProps {
  setShowOffCanvas: React.Dispatch<React.SetStateAction<boolean>>
  showOffCanvas: boolean
  valorizacion: DtoValorizacion
  trabajo: DtoTrabajosDE
  onSaveAdjuntosAddress: (
    nodos: DtoNodoDoc[],
    typeHPList: typeHPList,
    Home_ID: string,
    ID_AddressList: number,
    newFiles: File[],
    valorizacionUpdate: DtoValorizacion
  ) => Promise<boolean>
}

export const AdjuntosAddress: FC<iProps> = ({ setShowOffCanvas, showOffCanvas, valorizacion, trabajo, onSaveAdjuntosAddress }) => {

  const [nodos, setNodos] = useState<DtoNodoDoc[]>([])
  const [newFiles, setNewFiles] = useState<File[]>([])
  const [HomePass, setHomePass] = useState<DtoHomePass>({} as DtoHomePass)
  const languageTranslate = LanguageTranslate()

  const restoreFiles = (documentacion: DtoNodoDoc[], updateHP: DtoHPList) => {

    const update = updateHP.Asignaciones.find(e => e.Cuadrilla === trabajo.Ultima_asignacion.Cuadrilla.Codigo)

    if (!update) throw Error(languageTranslate.general.No_se_pudo_obtener_asignación)

    for (const nodo of documentacion) {
      const files = update.Anexos.filter(e => e.CodigoNodo === nodo.Codigo)
      nodo.Files = [...nodo.Files.filter(e => e.Estado.ID_Estado === 2024), ...files]
      restoreFiles(nodo.Children, updateHP)
    }
  }

  useEffect(() => {
    if (showOffCanvas) {
      try {
        //#region Limpiar variables
        setHomePass(valorizacion.HomePass[0])
        setNewFiles([])
        //#endregion

        const { ClientType, ID_AddressList, Home_ID } = valorizacion.HomePass[0]
        const AddressList = trabajo.ColeccionAddressList.filter(e => e.ID_AddressList === ID_AddressList)
        if (AddressList.length !== 1) throw Error(`¡No se pudo obtener direccíon!`)
        const updateHP = AddressList[0][ClientType === 'Business' ? 'BusinessHPList' : 'ResidentialHPList'].filter(e => e.Home_ID === Home_ID)
        if (updateHP.length !== 1) throw Error(`¡No se pudo obtener direccíon!`)

        const codEspecialidades = trabajo.Ultima_asignacion.Valorizaciones.map(e => e.Especialidad.Codigo)
        const documentacion = ClientType === 'Business' ?
          obtenerDocumentacionNoClientes({ codEspecialidades }) :
          obtenerDocumentacionClientes({ codEspecialidades }).Nodos
        restoreFiles(documentacion, updateHP[0])
        setNodos(documentacion)
      } catch (error) {
        console.log(error)        
        AdapterGenerico
          .createMessage('¡Error!', (error as Error).message, 'error')
          .then(() => setShowOffCanvas(false))
      }
    }
  }, [showOffCanvas])

  return (
    <OffCanvasBottom
      height={82}
      show={showOffCanvas}
      setShow={setShowOffCanvas}
      children={
        <Adjuntos
          Nodos={nodos}
          setNodos={setNodos}
          ruta={`AddressList_${HomePass.ID_AddressList}_${HomePass.ClientType}_${HomePass.Home_ID}_`}
          newFiles={newFiles}
          setNewFiles={setNewFiles}
          btnDelete={false}
          btnAdjuntar={false}
          onSave={async () => {
            const result = await onSaveAdjuntosAddress(nodos, HomePass.ClientType, HomePass.Home_ID, HomePass.ID_AddressList, newFiles, valorizacion)
            if (result) setShowOffCanvas(false)
          }}
        />
      }
    />
  )
}
