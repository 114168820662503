import { DtoFilesDoc } from '../../../../Programados/Trabajos/Trabajos/Domain/DtoFilesDoc'
import { DtoEstado } from '../../../../shared/Domain/Dto/DtoEstado'

export class iAutoInventario {
  ID_AutoInventario: number = 0
  ID_StockPersonal: number = 0
  Ind_SB: string = ''
  CodigoLlave: string = ''
  CodigoBusqueda: string = ''
  Codigo: string = ''
  KeyFecha: string = ''
  Identificacion: string = ''
  Cantidad: number = 0
  Stock: number = 0
  Observacion: string = ''
  Fecha: Date = new Date()
  Files: DtoFilesDoc[] = []
  Equipos: EquiposAIDto[] = []
  Estado: DtoEstado = new DtoEstado()
}

export class EquiposAIDto {
  Serie: string = ''
  Estado: boolean = false
  Observacion: string = ''
} 