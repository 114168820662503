import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { iAutoInventario } from '../../shared/domain/iAutoInventario'
import { iBodyReqSaveData } from '../domain/iBodyReqSaveData'
import { Repository } from '../infraestructure/repository'

export class UseCaseSave {

  private repository: Repository
  private state: RootState
  private files: File[]

  constructor(repository: Repository, state: RootState) {
    this.repository = repository
    this.state = state
    this.files = []
  }

  async exec() {
    return await this._exec()
  }

  private async _exec() {
    const data = structuredClone(this.state.autoInventario.dataAutoInventario)
    this.getFiles(data)
    this.validarEquiposSeriados(data)
    const body: iBodyReqSaveData = {
      datos: {
        Codigo: this.state.auth.user.Delegacion.Codigo,
        Nombre: this.state.auth.user.Pais.Nombre
      },
      data: data
    }
    await this.repository.uploadFiles(this.files)
    const result = await this.repository.saveDataAutoInventario(body)
    return this.buscarUsuarioAutoinventario(result)
  }

  private getFiles(data: iAutoInventario[]) {
    for (const item of data) {
      for (const file of item.Files) {
        if (file.File) {
          this.files.push(file.File)
          delete file.File
        }
      }
    }
  }

  private validarEquiposSeriados(data: iAutoInventario[]) {
    const falta = data.some(e => e.Cantidad !== e.Equipos.length && e.Ind_SB === 'S')
    if (falta) {
      throw new Error(`¡Tiene stock seriado que debe informar!`)
    }
    data.forEach(e => {
      if (e.Ind_SB === 'S') {
        const escaneados = e.Equipos.filter(el => el.Estado).length       
        e.Cantidad = escaneados
      }
    })
  }

  private buscarUsuarioAutoinventario(data: any[]) {
    const exist = data.find(e => e.Reportado)
    if (!exist) {
      throw new Error('Se reportó pero no se pudo refrescar. Por favor considere refrescar.')
    }
    return true
  }

}