import { codMOOcultosAL04 } from 'sigo-package'
import { DtoItems } from '../../../../../../../app/Domain/DtoItems'
import { DtoConsumoMaterial } from '../../../../../../Master/Home/Domain/DtoConsumoMaterial'
import { DtoResponseSelectStockPersonal } from '../../../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { DtoStockAlmacen } from '../../../../../../Master/Home/Domain/DtoStockAlmacen'
import { DtoMaterialesUtiRet } from '../../../../../Trabajos/Asignacion/Domain/DtoMaterialesUtiRet'
import { DtoDataAddedValorizaciones } from '../../../../Shared/Preliquidar/Interfaces/DtoDataAddedValorizaciones'
import { DtoDataMateriales } from '../../../Domain/DtoDataMateriales'
import { typeMaterial } from '../../../Domain/types'

export const generarDataMaterial = (dataItems: DtoItems[], dataStockPersonal: DtoResponseSelectStockPersonal[], typeMaterial: typeMaterial, valorizaciones: DtoDataAddedValorizaciones[]) => {
  return typeMaterial === 'Instalado'
    ? dataStockPersonal
      .filter(e => !codMOOcultosAL04.includes(e.Item.Codigo))
      .map(e => {

        const disponible = e.Despacho - e.Devolucion - e.Liquidacion + e.DespachoTR - e.DevolucionTR
        const utlizadoLocal = valorizaciones
          .reduce((acc, val) => {
            return acc += val.materialesInstalado
              .filter(e => e.Motivo.Descripcion !== 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS')
              .reduce((ac, mi) => {
                return ac += mi.CodigoLlave === e.CodigoLlave ? mi.Cantidad : 0
              }, 0)
          }, 0)

        const newDto = new DtoDataMateriales()
        newDto.Codigo = e.Item.Codigo.trim()
        newDto.CodigoLlave = e.CodigoLlave.trim()
        newDto.Descripcion = e.Item.Descripcion
        newDto.CantidadDisponible = disponible - utlizadoLocal
        newDto.Lote = e.Lote
        newDto.Bobina = e.Bobina
        return newDto
      })
    : typeMaterial === 'Retirado'
      ? dataItems.map(e => {
        const newDto = new DtoDataMateriales()
        newDto.Codigo = e.Codigo.trim()
        newDto.CodigoLlave = e.Codigo.trim()
        newDto.Descripcion = e.Descripcion
        return newDto
      })
      : []
}

export const generarDataMaterialAlmacen = (dataStockAlmacen: DtoStockAlmacen[], dataConsumoMaterial: DtoConsumoMaterial[], valorizaciones: DtoDataAddedValorizaciones[], materialesInstalado: DtoMaterialesUtiRet[]) => {

  return dataStockAlmacen.map(e => {

    const disponible = e.Ingreso - e.Despacho + e.Devolucion

    const despachoTemporal = dataConsumoMaterial.reduce((acc, cm) => {
      // if (cm.Estado.ID_Estado !== 1) return acc
      const exist = cm.CosumoMaterial.filter(cmAux => cmAux.CodigoLlave.trim() === e.CodigoLlave.trim())
      if (exist.length > 1) throw Error(`¡No se pudo calcular disponible!`)
      return acc += exist.length ? exist[0].Cantidad : 0
    }, 0)

    const utlizadoLocal = valorizaciones
      .reduce((acc, val) => {
        return acc += val.materialesInstalado
          .reduce((ac, mi) => {
            return ac += (mi.CodigoLlave.trim() === e.CodigoLlave.trim() && mi.Motivo.Descripcion === 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS') ? mi.Cantidad : 0
          }, 0)
      }, 0)

    const utilizadoGrid = materialesInstalado.reduce((acc, mi) => {
      return acc += e.CodigoLlave.trim() === mi.CodigoLlave.trim() && mi.Motivo.Descripcion === 'CONSUMO_MATERIAL_AUTOMATICO_DUCTOS' ? mi.Cantidad : 0
    }, 0)

    const newDto = new DtoDataMateriales()
    newDto.Codigo = e.Item.Codigo.trim()
    newDto.Descripcion = e.Item.Descripcion
    newDto.CantidadDisponible = disponible - utlizadoLocal - despachoTemporal - utilizadoGrid
    newDto.CodigoLlave = e.CodigoLlave.trim()
    newDto.Lote = e.Lote
    newDto.Bobina = e.Bobina
    return newDto
  }).reduce((arr: Array<any>, n: any) => {
    const post = arr.findIndex(m => m.CodigoLlave == n.CodigoLlave && m.Lote == n.Lote && m.Bobina == n.Bobina);
    if (post === -1) { arr.push(n) }
    else {
      arr[post]["Cantidad"] += n.Cantidad;
      arr[post]["CantidadContratista"] += n.CantidadContratista;
      arr[post]["CantidadDisponible"] += n.CantidadDisponible;
      arr[post]["CantidadInformada"] += n.CantidadInformada;
      arr[post]["CantidadReutilizada"] += n.CantidadReutilizada;
      arr[post]["CantidadSinStock"] += n.CantidadSinStock;
      arr[post]["CantidadStock"] += n.CantidadStock;
    }
    return arr;
  }, [])
}