import { Children, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate'
import { DtoTrabajosDE } from '../../Lista/Domain/DtoTrabajosDE'
import { typeHPList } from '../Domain/typeHPList'
import { iDtoHomePassValorizacion } from '../Domain/iDtoHomePassValorizacion'
import { DtoValorizacion } from '../../../Trabajos/Asignacion/Domain/DtoValorizacion'

interface iProps {
  trabajo: DtoTrabajosDE | null
  ID_AddressList: number
  Home_ID: string
  typeHPList: typeHPList
  setValRechazadaXMetraje: Dispatch<SetStateAction<DtoValorizacion | null>>
  valRechazadaXMetraje: DtoValorizacion | null
}

export const Valorizaciones = (props: iProps) => {

  const { ID_AddressList, Home_ID, typeHPList, trabajo, setValRechazadaXMetraje, valRechazadaXMetraje } = props
  const langTranslate = LanguageTranslate()
  const [data, setData] = useState<iDtoHomePassValorizacion[]>([])
  const [existRechazadaPorMetraje, setExistRechazadaPorMetraje] = useState<boolean>(false)

  useEffect(() => {
    if (!trabajo || !ID_AddressList) return
    const data = trabajo.Ultima_PreLiquidacion.Valorizacion
      .flatMap((curr) =>
        curr.HomePass.filter((e) => e.ID_AddressList === ID_AddressList &&
          e.Home_ID === Home_ID && e.ClientType === typeHPList &&
          [
            'PRODUCCION',
            'CERTIFICADA',
            'PRELIQUIDADA',
            'LIQUIDADA',
            'RECHAZADA POR METRAJE'
          ].includes(curr.Ultimo_Estado_Interno.Descripcion)
        ).map(e => ({
          ...e,
          valorizacion: curr
        }))
      )
    setExistRechazadaPorMetraje(data.some(e => ['RECHAZADA POR METRAJE'].includes(e.valorizacion.Ultimo_Estado_Interno.Descripcion)))
    setData(data)
  }, [ID_AddressList, trabajo])

  if (!data.length) return null

  return (
    <table className='table table-striped' style={{ backgroundColor: 'gray' }}>
      <thead>
        <tr>
          <th scope='col'>{langTranslate.general.Codigo}</th>
          <th scope='col'>{langTranslate.general.Descripcion}</th>
          <th scope='col' className='text-center'>{langTranslate.general.Cantidad}</th>
          {
            existRechazadaPorMetraje && <th></th>
          }
        </tr>
      </thead>
      <tbody>
        {
          Children.toArray(
            data.map(e =>
              <tr>
                <td>{e.valorizacion.DetalleChile.ManoObra.Codigo}</td>
                <td>{e.valorizacion.DetalleChile.ManoObra.Nombre}</td>
                <td className='text-center'>{e.Cantidad.toFixed(2)}</td>
                {
                  existRechazadaPorMetraje &&
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    {
                      (
                        ['RECHAZADA POR METRAJE']
                          .includes(e.valorizacion.Ultimo_Estado_Interno.Descripcion) &&
                        e.valorizacion.Semana == 0
                      )
                        ? <input
                          type='radio'
                          name='seleccion'
                          checked={valRechazadaXMetraje?.ID_Valorizacion === e.valorizacion.ID_Valorizacion}
                          onChange={() => setValRechazadaXMetraje(e.valorizacion)}
                        />
                        : null
                    }
                  </td>
                }
              </tr>
            )
          )
        }
      </tbody>
    </table>
  )
}
