import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import './Style.scss';

import foto from '../../../../assets/img/user.png';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import esFlag from '../../../../assets/img/es.png';
import usFlag from '../../../../assets/img/us.png';
import deFlag from '../../../../assets/img/de.png';
import { EntityInformation } from '../Domain/EntityInformation';
// import makeAnimated from "react-select/animated";
import { Collapse } from 'react-bootstrap';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

interface PropsViewMain {
    auth: boolean;
    user: EntityDataUsuario;

    showPreference: boolean;
    setShowPreference: Function;
    formPreferencia: { values: any; touched: any; errors: any; handleBlur: Function; };
    information: EntityInformation;
    onChangePreferencia: Function;

    showLanguage: boolean;
    setShowLanguage: Function;
    language?: string;
    onChangeLanguage?: Function;

    showChangePassword: boolean;
    setShowChangePassword: Function;
    formChangePassword: { values: any; touched: any; errors: any; handleBlur: Function; };
    onChangePassword: Function;
    onSubmitChangePassword: Function;
};

// const animatedComponents = makeAnimated();
export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();

    const flags = {
        es: esFlag,
        en: usFlag,
        de: deFlag,
    };

    const MenuListVirtualized = (props: any) => {
        const rows = props?.children;
        const allOptions = props.options;
        const RowRenderer = ({ index, style }: any) => <div className='d-inline-block text-truncate option-virtualized-custom' style={{ ...style, minHeight: 'max-content', minWidth: 'max-content', overFlowY: 'scroll' }} key={allOptions[index].value}> {rows[index]} </div>

        const RowRendererEmpty = ({ index, style }: any) => <div style={style} key={-1}> No Options </div>

        return (
            <div style={{ height: (rows?.length || 0) * 35, maxHeight: 300, minHeight: 35 }}>
                <AutoSizer>
                    {({ height, width }: any) => (
                        <List
                            className="List"
                            height={height}
                            itemCount={(rows?.length || 0)}
                            itemSize={35}
                            width={width}
                        >
                            {(rows?.length || 0) === 0 ? RowRendererEmpty : RowRenderer}
                        </List>
                    )}
                </AutoSizer>
            </div>
        )
    }

    return (
        <div className='ViewProfile'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={[{ text: languageTranslate.textoConfiguracion, navigate: false, path: '' }]} />
                <div className="row">
                    <div className="col-4 text-center">
                        <img src={foto} className="img-thumbnail" alt="Profile" style={{ borderRadius: '50%' }} width={90} />
                    </div>
                    <div className="col-8">
                        <p style={{ marginBottom: '0.2rem' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>{`${props.user.Nombres} ${props.user.ApellidoPaterno} ${props.user.ApellidoMaterno}`.trim()}</span>
                        </p>
                        {/* <p style={{ marginBottom: '0.2rem' }}>
                            <span style={{ fontSize: '11px' }}>{`${props.user.Empresa?.Nombre || props.user.Empresa['Empresa' as keyof typeof props.user.Empresa] || ''} - ${props.user.PaisBase.Pais}`}</span>
                        </p> */}
                        <p style={{ marginBottom: '0.2rem' }}>
                            <span style={{ fontSize: '0.65rem' }}>{`${props.user.Perfil.Perfil ? props.user.Perfil.Perfil : languageTranslate.general.SinPerfil}`}</span>
                        </p>
                    </div>
                </div>
                <div hidden={props.user.IdUsuario === 0}>
                    <div className="row">

                        {/* <div className="col-12 pt-3">
                            <div className="card">
                                <div className="card-header fondoSeccion" onClick={() => { props.setShowPreference(!props.showPreference) }}>
                                    <h6>{languageTranslate.moduloConfiguracion.textoPreferencia}</h6>
                                    <i className={`fa-solid fa-play ${props.showPreference ? 'iconShow' : 'iconNotShow'}`} />
                                </div>
                                <Collapse in={props.showPreference}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="mb-1">{languageTranslate.moduloConfiguracion.textoOT} <span className="text-danger">*</span></label>
                                                    <Select
                                                        className="custom-select"
                                                        components={{ MenuList: MenuListVirtualized }}
                                                        onChange={(value) => props.onChangePreferencia('ot', value)}
                                                        value={[props.formPreferencia.values['ot']]}
                                                        options={props.information.ot}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 pt-2">
                                                <div className="form-group">
                                                    <label className="mb-1">{languageTranslate.moduloConfiguracion.textoDelegacion} <span className="text-danger">*</span></label>
                                                    <Select
                                                        className="custom-select"
                                                        components={{ MenuList: MenuListVirtualized }}
                                                        isDisabled={true}
                                                        onChange={(value) => props.onChangePreferencia('delegacion', value)}
                                                        value={[props.formPreferencia.values['delegacion']]}
                                                        options={props.information.delegacion.filter(row => row.dataComplete.Pais.IdPais === props.formPreferencia.values['pais']?.dataComplete?.IdPais)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 pt-2">
                                                <div className="form-group">
                                                    <label className="mb-1">{languageTranslate.moduloConfiguracion.textoPais} <span className="text-danger">*</span></label>
                                                    <Select
                                                        className="custom-select"
                                                        components={{ MenuList: MenuListVirtualized }}
                                                        isDisabled={true}
                                                        onChange={(value) => props.onChangePreferencia('pais', value)}
                                                        value={[props.formPreferencia.values['pais']]}
                                                        options={props.information.pais}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 pt-4">
                                                <div className="form-group">
                                                    <label className="mb-1">{languageTranslate.moduloConfiguracion.textoPersonalMediosHumanos} <span className="text-danger">*</span></label>
                                                    <Select
                                                        className="custom-select"
                                                        components={{ MenuList: MenuListVirtualized }}
                                                        onChange={(value) => props.onChangePreferencia('personal', value)}
                                                        isDisabled={!props.formPreferencia.values['pais']}
                                                        value={props.formPreferencia.values['personal']}
                                                        options={props.information.personal.filter(row => row.dataComplete.IdPais === props.formPreferencia.values.pais?.dataComplete.IdPais )}
                                                        isMulti
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 pt-4">
                                                <div className="form-group d-flex">
                                                    <label className="mb-1">{languageTranslate.moduloConfiguracion.textoBuscarCuadrillaPersonal} <span className="text-danger">*</span></label>
                                                    <div className="form-check form-switch" style={{ marginLeft: 'auto' }}>
                                                        <input
                                                            checked={props.formPreferencia.values['buscarCuadrilla']}
                                                            onChange={(evt) => props.onChangePreferencia('buscarCuadrilla', evt.target.checked)}
                                                            className="form-check-input"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div> */}

                        <div className="col-12 pt-3">
                            <div className="card">
                                <div className="card-header fondoSeccion" onClick={() => { props.setShowLanguage(!props.showLanguage) }}>
                                    <h6>{languageTranslate.moduloConfiguracion.textoIdioma}</h6>
                                    <i className={`fa-solid fa-play ${props.showLanguage ? 'iconShow' : 'iconNotShow'}`} />
                                </div>
                                <Collapse in={props.showLanguage}>
                                    <div className="card-body">
                                        <div className="row pt-2">
                                            {
                                                languageTranslate.languages.map(row => (
                                                    <div className="col-4" key={row.code}>
                                                        <input
                                                            type="radio"
                                                            className="btn-check"
                                                            name="options-outlined"
                                                            id={`inputIdioma${row.code}`}
                                                            value={row.code}
                                                            onChange={(evt) => evt.target.checked ? !!props.onChangeLanguage ? props.onChangeLanguage(row.code) : undefined : undefined}
                                                            checked={props.language === row.code ? true : false}
                                                        />
                                                        <label className="btn btn-sm btn-outline-primary" htmlFor={`inputIdioma${row.code}`}>
                                                            <img src={flags[row.code as keyof typeof flags]} alt="flag" /> {row.name}
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>

                        <div className="col-12 pt-3">
                            <div className="card">
                                <div className="card-header fondoSeccion" onClick={() => { props.setShowChangePassword(!props.showChangePassword) }}>
                                    <h6>{languageTranslate.moduloConfiguracion.textoCambioPassword}</h6>
                                    <i className={`fa-solid fa-play ${props.showChangePassword ? 'iconShow' : 'iconNotShow'}`} />
                                </div>
                                <Collapse in={props.showChangePassword}>
                                    <div className="card-body">
                                        <form className="row" noValidate onSubmit={(e) => props.onSubmitChangePassword(e)}>
                                            <div className="col-12">
                                                <div className="input-group input-group-sm">
                                                    <span className="input-group-text" >
                                                        <i className="fa-solid fa-key" />
                                                    </span>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        className="form-control"
                                                        required
                                                        placeholder={languageTranslate.moduloConfiguracion.textoPassword}
                                                        value={props.formChangePassword.values['password']}
                                                        onChange={(evt) => { props.onChangePassword('password', evt.target.value) }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 pt-2">
                                                <div className="input-group input-group-sm">
                                                    <span className="input-group-text" >
                                                        <i className="fa-solid fa-key" />
                                                    </span>
                                                    <input
                                                        type="password"
                                                        name="newPassword"
                                                        className="form-control"
                                                        required
                                                        placeholder={languageTranslate.moduloConfiguracion.textoNuevaPassword}
                                                        value={props.formChangePassword.values['newPassword']}
                                                        onChange={(evt) => { props.onChangePassword('newPassword', evt.target.value) }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 pt-2">
                                                <div className="input-group input-group-sm">
                                                    <span className="input-group-text" >
                                                        <i className="fa-solid fa-key" />
                                                    </span>
                                                    <input
                                                        type="password"
                                                        name="reNewPassword"
                                                        className="form-control"
                                                        required
                                                        placeholder={languageTranslate.moduloConfiguracion.textoRepetirNuevaPassword}
                                                        value={props.formChangePassword.values['reNewPassword']}
                                                        onChange={(evt) => { props.onChangePassword('reNewPassword', evt.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 pt-2">
                                                <button type="submit" className="btn btn-primary" style={{ width: '100%', backgroundColor: '#01397D' }}>
                                                    {languageTranslate.moduloConfiguracion.textoBotonSaveCambioPassword}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
};